<template>
  <div class="module-carousel">
    <div class="mod-shop-carousel" :style="{padding: content.margin + 'px'}">
      <el-carousel v-model="value1" v-if="content.data.length > 0"
        :height="content.height + 'px'"
        :autoplay="true"
        :interval="content.interval ? content.interval * 1000 : 2000"
        :radius-dot="settingBor"
        :direction="content.direction == 1 ? 'horizontal' : 'vertical'"
        arrow="never">
        <el-carousel-item v-for="(item, index) in content.data" :key="index">
          <div class="carousel-img" :style="comstyle">
            <img v-if="item.img_url == ''" :src="source_domain + '/images/icon-bg8.jpg'" alt="">
            <img v-if="item.img_url != ''" :src="item.img_url" alt="">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {},
      settingBor: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      comstyle () {
        return {
          // height: (this.content.height == null || this.content.height == '') ? '150px' : this.content.height / 2 + 'px',
          // width: 298 - 2 * this.content.margin + 'px',
          height: this.content.height + 'px',
          // marginTop: this.content.margin + 'px',
          // marginLeft: this.content.margin + 'px',
          // marginBottom: this.content.margin + 'px',
          // marginRight: this.content.margin + 'px'
        }
      }
    },
    data () {
      return {
        source_domain: this.config.SOURCE_DOMAIN,
        'type': 'audio',
        'value1': 0
      }
    }
  }
</script>

<style scoped>
  .module-carousel {
    width: 298px;
  }
  .mod-shop-carousel{
    box-sizing: border-box;
    width: 298px;
  }
  .mod-shop-carousel .carousel-img img{
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
