import { render, staticRenderFns } from "./title.edit.vue?vue&type=template&id=532b3bce&scoped=true"
import script from "./title.edit.vue?vue&type=script&lang=js"
export * from "./title.edit.vue?vue&type=script&lang=js"
import style0 from "./title.edit.vue?vue&type=style&index=0&id=532b3bce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532b3bce",
  null
  
)

export default component.exports