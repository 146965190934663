<template>
  <div class="edit-white">
    <div class="edit-shop-header">
      <h3>视频设置</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-video">
        <el-form  :model="content" :label-width="80">
          <el-form-item label="视频地址："  prop="url">
             <Input v-model="content.url" type="textarea" :rows="4" @on-change="contentChange"></Input>
             <a href="http://bbdshop-static.tsphp.com/forum.php?mod=viewthread&tid=6216&page=1&extra=#pid26367" target="_blank">如何查找视频地址？</a>
          </el-form-item><el-form-item label="视频封面：">
            <div class="choice-image-box">
              <single-select-img :img="content.img" :width="750" :height="421" :maxSize="1024" @updateImgList="imgChange"></single-select-img>
              <p>建议尺寸：750 x 421 像素</p>
            </div>
          </el-form-item>
          </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  import singleSelectImg from '../../components/FileUpload'
  export default {
    components: {
      singleSelectImg
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'blank'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.type, this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      imgChange (val) {
        this.content.img = val.url;
      }
    }
  }
</script>

<style scoped>
  .edit-shop-white {
    background-color: #ddd;
  }
  .edit-shop-content  .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }
  .choice-image-box{
    background-color: #fcfcfc;
    width: 400px;
    position: relative;
    height: 120px;
    padding: 10px;
    overflow: hidden;
  }
  .choice-image-box p{
    position: absolute;
    bottom: 10px;
    left: 120px;
    color: #999;
  }
</style>
