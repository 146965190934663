<template>
  <div class="edit-phone">
    <div class="edit-shop-header">
      <h3>电话设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-phone">
       <el-form :model="content" label-width="80px" size="small">
         <el-form-item label="显示文字：" prop="title">
             <el-row>
                 <el-col :span="12">
                     <el-input v-model="content.title" size="small" label="请输入" controls-position="right"></el-input>
                 </el-col>
                 <el-col :span="12">
                     <p class="tit pd-lf-10">默认为“电话”，最多可输入20个字</p>
                 </el-col>
             </el-row>
         </el-form-item>
         <el-form-item label="电话号码："  prop="phone">
             <el-row>
                 <el-col :span="12">
                     <el-input v-model="content.phone" size="small" label="请输入" controls-position="right"></el-input>
                 </el-col>
                 <el-col :span="12">
                     <p class="tit pd-lf-10">请填写座机号或者手机号，最多可输入20位数字</p>
                 </el-col>
             </el-row>
         </el-form-item>
         <el-form-item label="背景颜色：" prop="bgcolor">
             <el-color-picker v-model="content.bgcolor"></el-color-picker>
         </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'phone'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      }
    }
  }
</script>

<style scoped>
  .edit-shop-content  .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content  .ivu-form-item:after{
    display: none;
  }
  .tit{color:#999;}
</style>
