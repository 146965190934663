<template>
  <div class="module-waterfall">
    <div class="mod-shop-waterfall" :style="{backgroundColor: content.bgcolor}">
      <div class="mod-item-box">
        <ul class="watefall-ul">
          <li class="mod-item" :style="{width: navWidth/2}" v-for="(item, index) in leftData" :key="index">
            <img v-if="item.img != ''" :src="$store.state.imgBaseUrl + item.img" class="mod-img" :style="{height:item.height/2+'px'}"/>
            <img v-else src="../../assets/images/icon-bg8.jpg" alt="" :style="{height:item.height/2+'px'}">
            <h3 v-show="item.title != ''" class="mod-name" :style="{color: item.color}">{{item.title}}</h3>
          </li>
        </ul>
        <ul class="watefall-ul">
          <li class="mod-item" :style="{width: navWidth/2}" v-for="(item, index) in rightData" :key="index">
            <img v-if="item.img != ''" :src="$store.state.imgBaseUrl + item.img" class="mod-img" :style="{height:item.height/2+'px'}"/>
            <img v-else src="../../assets/images/icon-bg8.jpg" alt="" :style="{height:item.height/2+'px'}">
            <h3 v-show="item.title != ''" class="mod-name" :style="{color: item.color}">{{item.title}}</h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {
      navWidth () {
        return 100 + '%'
      }
    },
    data () {
      return {
        'type': 'waterFall',
        'setHeight': '200px',
        'leftHeight': 0,
        'rightHeight': 0,
        'leftData': [],
        'rightData': []
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.setLFdata()
        },
        deep: true
      }
    },
    created () {
      this.setLFdata()
    },
    methods: {
      setLFdata () {
        this.leftHeight = 0;
        this.rightHeight = 0;
        this.leftData = [];
        this.rightData = [];
        this.content.data.forEach((item, index) => {
          if (this.leftHeight <= this.rightHeight) {
            if (item.title != '') {
              this.leftHeight = this.leftHeight + 45;
            }
            this.leftHeight = this.leftHeight + item.height;
            this.leftData.push(item)
          } else {
            if (item.title != '') {
              this.rightHeight = this.rightHeight + 45;
            }
            this.rightHeight = this.rightHeight + item.height;
            this.rightData.push(item)
          }
        })
        // console.log(this.leftHeight, this.rightHeight)
      }
    }
  }
</script>

<style scoped>
  .mod-shop-waterfall{
    background-color: #fff;
  }
  .mod-item-box {
    overflow: hidden;
    text-align: center;
    display:flex;
  }
  .watefall-ul{
    width: 199px;
    float: left;
  }
  .mod-item {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  .mod-item img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .mod-name {
    font-size: 12px;
    color: #444;
    font-weight: normal;
    margin-top: 7px;
    word-wrap: break-word;
    word-break: break-all;
    height: 18px;
    line-height: 18px;
    text-align:left;
    overflow: hidden;
  }
  .mod-dots{
    clear: left;
    width: 100%;
    padding: 4px 0;
    text-align: center;
  }
  .mod-dots span{
    display: inline-block;
    border: 1px solid #666;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin: 0 4px;
  }
</style>
