<template>
  <div class="module-banner">
    <section class="mod-shop-header1" v-if="content.style == 0">
      <div class="mod-shop-header1-bg">
        <img class="mod-shop-header1-pic" :src="content.banner_img">
        <div class="mod-shop-header1-title">
          <span class="shop-name" :style="{color: content.color}">{{content.shop_name}}</span>
        </div>
        <div class="mod-shop-header1-logo">
          <img :src="content.logo">
        </div>
      </div>
    </section>
    <section class="mod-shop-header2" v-if="content.style == 1">
      <div class="mod-shop-header2-bg">
        <img class="mod-shop-header1-pic" :src="content.banner_img">
        <div class="mod-shop-header2-logo">
          <img :src="content.logo">
        </div>
        <div class="mod-shop-header2-title">
          <span class="shop-name" :style="{color: content.color}">{{content.shop_name}}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
    },
    data () {
      return {
      }
    }
  }
</script>

<style scoped>

  .mod-shop-header1 {
    background-color: #fff;
    position: relative;
  }

  .mod-shop-header1 .mod-shop-header1-bg {
    width: 100%;
    height: 90px;
  }

  .mod-shop-header1 .mod-shop-header1-pic {
    width: 100%;
    display: block;
    height: 100%;
  }

  .mod-shop-header1 .mod-shop-header1-bg .mod-shop-header1-title {
    position: absolute;
    left: 74px;
    bottom: 26px;
    font-size: 18px;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
  }

  .mod-shop-header1 .mod-shop-header1-bg .mod-shop-header1-logo {
    position: absolute;
    left: 12px;
    bottom: 10px;
    width: 48px;
    height: 48px;
    padding: 1px;
    border: 1px solid #ececec;
    border-radius: 2px;
  }

  .mod-shop-header1 .mod-shop-header1-bg .mod-shop-header1-logo img {
    width: 44px;
    height: 44px;
  }

  .mod-shop-header1 .mod-shop-header1-bg .shop-name{
    display: block;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 224px;;
    white-space: nowrap;
  }

  .mod-shop-header2 {
    position: relative;
  }
  .mod-shop-header2 .mod-shop-header2-bg{
    height: 120px;
  }
  .mod-shop-header2 .mod-shop-header2-bg img{
    width: 100%;
    height: 100%;
  }
  .mod-shop-header2 .mod-shop-header2-logo{
    position: absolute;
    left: 50%;
    top: 24px;
    margin-left: -26px;
    height: 52px;
    width: 52px;
    border-radius: 50%;
    border: 1px solid #ececec;
    padding: 1px;
    overflow: hidden;
  }
  .mod-shop-header2 .mod-shop-header2-logo img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .mod-shop-header2 .mod-shop-header2-title{
    position: absolute;
    left: 0;
    top: 84px;
    width: 100%;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
</style>
