<template>
  <div class="module-customarticle">
    <div class="mod-shop-customarticle" :style="{backgroundColor:content.bgcolor}">
      <div class="m-list3" v-if="content.style == 1">
        <ul class="f-cb">
          <li v-for="(item, index) in content.data" :key="index">
            <div class="u-img"><a href="javascript:void(0);">
              <img v-if="!item.it_icon || item.it_icon == ''" src="../../assets/images/icon-bg8.jpg">
              <img v-else :src="item.it_icon" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);" :style="{color:content.color}">{{item.it_title}}</a></h3>
              <p :style="{color:content.color}">{{item.it_desc}}...</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="m-list2" v-else-if="content.style == 2">
        <ul class="f-cb">
          <li v-for="(item, index) in content.data" :key="index">
            <div class="u-img"><a href="javascript:void(0);">
              <img v-if="!item.it_icon || item.it_icon == ''" src="../../assets/images/icon-bg8.jpg">
              <img v-else :src="item.it_icon" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);" :style="{color:content.color}">{{item.it_title}}</a></h3>
              <p :style="{color:content.color}">{{item.it_desc}}...</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="m-list5" v-else-if="content.style == 3">
        <ul class="f-cb">
          <li v-for="(item, index) in content.data" :key="index">
            <div class="txt">
              <h3><a href="javascript:void(0);" :style="{color:content.color}">{{item.it_title}}</a></h3>
              <p :style="{color:content.color}">{{item.it_desc}}...</p>
            </div>
            <div class="u-img"><a href="javascript:void(0);">
              <img v-if="!item.it_icon || item.it_icon == ''" src="../../assets/images/icon-bg8.jpg">
              <img v-else :src="item.it_icon" alt="" />
            </a></div>
          </li>
        </ul>
      </div>
      <div class="m-list6" v-else-if="content.style == 4">
        <ul class="f-cb">
          <li v-for="(item, index) in content.data" :key="index">
            <div class="u-img">
              <img :src="item.it_icon" alt="" />
            </div>
            <div class="txt">
              <h3><a href="javascript:void(0);">{{item.it_title}}</a></h3>
              <p>{{item.it_desc}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="m-list7" v-else-if="content.style == 5">
        <ul class="f-cb">
          <li v-for="(item, index) in content.data" :key="index">
            <div class="u-img">
              <img :src="item.it_icon" alt="" />
            </div>
            <div class="txt">
              <h3><a href="javascript:void(0);">{{item.it_title}}</a></h3>
              <p>{{item.it_desc}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'edit-shop-imageText'
      }
    },
    watch: {

    },
    created () {

    },
    methods: {
      render (item) {
        return this.hexToRgba(item.it_bgcolor, item.it_opacity)
      },
      hexToRgba (hex, opacity) {
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      }
    }
  }
</script>

<style scoped>
  .mod-shop-customarticle {
    background-color: #fff;
  }

  .m-list2{padding:1px 0 0;zoom:1; overflow: hidden;}
  .m-list2 ul{margin:0;}
  .m-list2 li{display:inline;float:left;width:100%;margin:0;padding: 7px 10px;border-bottom: 1px solid #eee;}
  .m-list2 .txt{position: relative;float:left;width:100%;height:84px;overflow:hidden;margin-right:-95px;}
  .m-list2 h3,.m-list2 p{margin-right:95px;overflow:hidden;line-height:20px;}
  .m-list2 h3{margin-bottom:5px;}
  .m-list2 h3 a,.m-list2 h3 a:hover{color:#333;}
  .m-list2 .u-img{float:right;width:90px;height:90px;}
  .m-list2 .u-img2{float:right;width:84px;height:84px;}
  .m-list2 .u-img img{float:right;width:84px;height:84px;}

  .m-list3{padding:1px 0 0;zoom:1; overflow: hidden;}
  .m-list3 ul{margin:0;}
  .m-list3 li{display:inline;float:left;width:100%;margin:0;padding: 7px 10px;border-bottom: 1px solid #eee;}
  .m-list3 .txt{position: relative;float:right;width:100%;height:84px;overflow:hidden;margin-left:-95px;}
  .m-list3 h3,.m-list3 p{margin-left:95px;overflow:hidden;line-height:20px;}
  .m-list3 h3{margin-bottom:5px;}
  .m-list3 h3 a,.m-list3 h3 a:hover{color:#333;}
  .m-list3 .u-img{float:left;width:90px;height:90px;}
  .m-list3 .u-img2{float:left;width:84px;height:84px;}
  .m-list3 .u-img img{float:left;width:84px;height:84px;}

  .m-list5{padding:1px 0 0;zoom:1; overflow: hidden;}
  .m-list5 ul{margin:0;}
  .m-list5 li{width:100%;margin:0;padding: 7px 10px;border-bottom: 1px solid #eee;}
  .m-list5 .txt{width:100%;overflow:hidden;}
  .m-list5 h3,.m-list5 p{overflow:hidden;line-height:18px;}
  .m-list5 h3{margin-top:2px;margin-bottom:5px;}
  .m-list5 h3 a,.m-list5 h3 a:hover{color:#333;}
  .m-list5 .u-img{width:278px;height:278px;margin-top: 4px;}
  .m-list5 .u-img2{width:278px;height:278px;}
  .m-list5 .u-img img{width:278px;height:278px;}
  .m-list5 .txt:last-child p{
    margin-top: 4px;
  }
  .m-list6{padding:1px 0 0;}
  .m-list6 ul{margin:0;display: flex;flex-wrap: wrap;}
  .m-list6 li{margin:0;padding-right: 10px;box-sizing: border-box; width: 50%; padding-bottom: 10px; }
  .m-list6 li:nth-child(2n) {
    padding-right: 0;
  }
  .m-list6 .txt{width:100%; padding: 0 5px; box-sizing: border-box}
  .m-list6 .txt h3 {display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .m-list6 .txt p {display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .m-list6 h3,.m-list6 p{;line-height:20px;}
  .m-list6 h3 a,.m-list6 h3 a:hover{color:#333;}
  .m-list6 .u-img{width:100%;height:100px;}
  .m-list6 .u-img img{width:100%;height:94px;}

  .m-list7{padding:1px 0 0;}
  .m-list7 ul{margin:0;display: flex;flex-wrap: wrap;}
  .m-list7 li{margin:0;padding-right: 10px;box-sizing: border-box; width: 33.3333%; padding-bottom: 10px; }
  .m-list7 li:nth-child(3n) {padding-right: 0;}
  .m-list7 .txt{width:100%; padding: 0 5px; box-sizing: border-box}
  .m-list7 .txt h3 {display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .m-list7 .txt p {display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .m-list7 h3,.m-list7 p{line-height:20px;}
  .m-list7 h3 a,.m-list7 h3 a:hover{color:#333;}
  .m-list7 .u-img{width:100%;height:75px;}
  .m-list7 .u-img img{width:100%;height:70px;}
</style>
