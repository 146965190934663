<template>
  <div class="edit-images">
    <div class="edit-shop-header">
      <h3>图片模板设置</h3>
      <div class="edit-shop-delete">
        <!--a href="javascript:void(0);" @confirm="deleteComs()">
          <i class="el-icon-delete"></i>
        </a-->
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-images">
        <el-row style="height:30px; margin-bottom: 0">
          <el-col :span="24" style="line-height: 24px;">选择图片模板：</el-col>
        </el-row>
        <el-row class="images-style">
          <el-col :span="4">
            <img :class="{select: content.style==1}" @click="chooseStyle(1, 1)" :src="source_domain + '/images/pic02.png'" />
            <span class="select" v-show="content.style==1"><i class="el-icon-check"></i></span>
            <p>单列</p>
          </el-col>
          <el-col :span="4">
            <img :class="{select: content.style==2}" @click="chooseStyle(2, 2)" :src="source_domain + '/images/pic03.png'" />
            <span class="select" v-show="content.style==2"><i class="el-icon-check" aria-hidden="true"></i></span>
          <p>双列</p>
          </el-col>
          <el-col :span="4">
            <img :class="{select: content.style==3}" @click="chooseStyle(3, 3)" :src="source_domain + '/images/pic04.png'" />
            <span class="select" v-show="content.style==3"><i class="el-icon-check" aria-hidden="true"></i></span>
          <p>三列</p>
          </el-col>
          <el-col :span="4">
            <img :class="{select: content.style==6}" @click="chooseStyle(6, 4)" :src="source_domain + '/images/pic10.png'" />
            <span class="select" v-show="content.style==6"><i class="el-icon-check" aria-hidden="true"></i></span>
          <p>四列</p>
          </el-col>
          <!--el-col :span="4">
            <img :class="{select: content.style==7}" @click="chooseStyle(7, 5)" :src="source_domain + '/images/pic11.png'" />
            <span class="select" v-show="content.style==7"><i class="el-icon-check" aria-hidden="true"></i></span>
          <p>五列</p>
          </el-col-->
          <el-col :span="4">
            <img :class="{select: content.style==4}" @click="chooseStyle(4, 3)" :src="source_domain + '/images/pic06.png'" />
            <span class="select" v-show="content.style==4"><i class="el-icon-check" aria-hidden="true"></i></span>
            <p>左一右二</p>
          </el-col>
          <el-col :span="4">
          <img :class="{select: content.style==5}" @click="chooseStyle(5, 5)" :src="source_domain + '/images/pic09.png'" />
          <span class="select" v-show="content.style==5"><i class="el-icon-check" aria-hidden="true"></i></span>
          <p>左四右一</p>
          </el-col>
        </el-row>
        <el-form :model="content" label-width="80px">
          <el-form-item label="图片高度：">
            <el-input-number v-model="content.height" :min="1" :max="1000" size="small" label="请输入高度" controls-position="right"></el-input-number>
            <span class="edit-unit"> px</span>
          </el-form-item>
          <el-form-item label="图片间距：" v-if="content.style != 1">
            <el-input-number v-model="content.padding" :min="0" :max="100" size="small" label="请输入间距" controls-position="right"></el-input-number>
            <span class="edit-unit"> px</span>
          </el-form-item>
           <el-form-item label="周围留白：">
             <el-input-number v-model="content.margin" :min="0" :max="100" size="small" label="请输入间距" controls-position="right"></el-input-number>
            <span class="edit-unit"> px</span>
          </el-form-item>
        </el-form>
        <template v-for="(item,index) in content.data">
          <el-row style="height:120px;background-color: #f8f8f9; margin-bottom: 10px; padding: 10px; margin-top: 20px;" class="images-data" :key="index" v-if="index < maxImg">
            <el-col :span="5" style="width: 100px;">
              <SelectImgs :selectNum="1" :selectData="[content.data[index].img_url?content.data[index].img_url:'']" :idx="index" :selectFunc="updateDataImgs"></SelectImgs>
            </el-col>
            <el-col :span="19" class="line" style="width: 360px;">
              <el-form label-width="90px">
                <el-form-item label="图片链接：">
                  <select-link :setData="content.data[index].img_link" :index="index" :setFunc="setLink">
                    <template slot="button">
                      <el-input :value="content.data[index].img_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                    </template>
                  </select-link>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
  import SelectImgs from '../../components/SelectImgs'
  import selectLink from './select.link.vue'
  export default {
    components: {
      SelectImgs,
      selectLink
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        source_domain: this.config.SOURCE_DOMAIN,
        'type': 'images',
        'maxImg': 1,
        'tempData': [{
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }, {
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }, {
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }, {
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }, {
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }]
      }
    },
    created () {
      this.maxImg = this.content.style == 4 ? 3 : this.content.data.length;
      for (var i = 0; i < this.content.data.length; i++) {
        this.tempData[i] = this.content.data[i];
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      chooseStyle (style, value) {
        this.maxImg = value;
        var dataLength = this.content.data.length;
        if (this.content.style != style) {
          this.content.style = style;
          if (this.content.data.length < value) {
            for (var i = 0; i < value; i++) {
              this.content.data[i] = this.tempData[i];
            }
          } else {
            for (var j = 0; j < this.content.data.length; j++) {
              this.tempData[i] = this.content.data[j];
            }
            this.content.data.splice(value, dataLength - 1)
          }
        }
      },
      updateDataImgs (val, index) {
        var data = this.content.data;
        if (!data) {
          data = [];
        }
        if (!data[index]) {
          data[index] = {};
        }
        data[index].img_url = val.pic;
        this.content.data = data;
      },
      setLink (item, index) {
        this.content.data[index].img_link = item
      }
    }
  }
</script>

<style scoped>
  .edit-shop-images {
    padding: 0 10px;
  }
  .images-style .el-col{
    position: relative;
    margin-right: 10px;
    display: block;
    width: 85px;
  }
  .images-style .el-col:last-child{
    margin-right: 0;
  }
  .images-style .el-col img{
    width: 80px;
    height: 65px;
    border: 2px solid #fff;
  }
  .images-style .el-col p{
    text-align: center;
    color: #999;
    line-height: 30px;
  }
  .images-style .el-col img:hover{
    cursor: pointer;
  }
  .images-style img.select{
    border: 2px solid #57a3f3;
  }
  .images-style span.select{
    height: 22px;
    width: 22px;
    position: absolute;
    top: 47px;
    right: 3px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
  }
  .images-style .select i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
  .images-data .line{
    height: 80px;
    line-height: 80px;
  }
  .images-style{
    display: block;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }
  .images-style p {
    text-align: center;
    color: #999;
  }
  .images-style:after, .images-style:before{
    display: block;
  }
  .edit-shop-images .edit-input{
    width: 100px;
  }
  .edit-shop-images .edit-tips{
    margin-left: 20px;margin-top: 1px;color: #999;display: inline-block;
  }
</style>
