<template>
  <div class="edit-slider-article">
    <div class="edit-shop-header">
      <h3>横滑资讯设置</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-customArticle">
        <div style="overflow:hidden;">
          <Button v-if="content.article.length < 20" type="primary" style="float: left; margin-left: 10px;" @click="addArticle()"><i type="plus-round"></i> 添加资讯</Button>
          <Button v-else disabled style="float: left; margin-left: 10px;">添加资讯</Button>
          <p class="edit-tips" style="margin-top: 7px;">最多可添加20篇资讯，拖曳可调整资讯顺序</p>
        </div>
      </section>
      <div class="drag" style="overflow: auto;">
        <draggable class="dragArea" v-model="content.article">
          <div v-for="(item, index) in content.article" class="goods-wrap" :key="index">
            <!--<img :src="$store.state.imgBaseUrl + item.img_json[0].url">-->
            <img v-if="item.first_img" :src="$store.state.imgBaseUrl + item.first_img">
            <i @click.native="remove(index)" class="del-goods" type="close-circled"></i>
          </div>
        </draggable>
      </div>
    </div>
    <Modal v-model="modal2" width="500" :mask-closable="false" @on-cancel="close">
      <div class="">
        <div class="inner-header" style="width: 400px;height: 34px; overflow: hidden">
          <Row>
            <Col span="24">
            <div class="search-inline" @keyup.enter="search">
              <el-form ref="serach" inline @submit.native.prevent>
                <el-form-item>
                  <Input type="text" placeholder="请输入资讯标题" style="width: 200px;" v-model="searchParams.title"></Input>
                </el-form-item>
                <el-form-item>
                  <Button type="ghost" @click="search">
                    <i type="search"></i>
                    搜索
                  </Button>
                </el-form-item>
              </el-form>
            </div>
            </Col>
          </Row>
        </div>
        <div class="">
          <div data-v-9dbc4676="" class="table-editcontent ivu-table-wrapper">
            <div class="ivu-table">
              <div class="ivu-table-header">
                <table cellspacing="0" cellpadding="0" border="0" style="width: 100%;">
                  <thead>
                  <tr>
                    <th class="ivu-table-column-center" style="width: 60px;">
                      <Checkbox v-model="selectAll" @on-change="selectAllItem()"></Checkbox>
                    </th>
                    <th class="">
                      <div class="ivu-table-cell"><span>资讯标题</span>  </div>
                    </th>
                    <th class="ivu-table-column-center" style="width: 100px;">
                      <div class="ivu-table-cell"><span>资讯封面</span>  </div>
                    </th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="ivu-table-body" style="height: 300px;overflow: scroll;">
                <table cellspacing="0" cellpadding="0" border="0" style="width: 100%;">
                  <tbody class="ivu-table-tbody">
                  <tr class="ivu-table-row" v-for="(item, index) in data" :key="index">
                    <td class="ivu-table-column-center" style="width: 30px;">
                      <div class="ivu-table-cell">
                        <Checkbox v-model="item.issel" @on-change="selectItem(item, index)"></Checkbox>
                      </div>
                    </td>
                    <td class="">
                      <div class="ivu-table-cell">   <span>{{item.title}}</span>
                      </div>
                    </td>
                    <td class="ivu-table-column-center" style="width: 100px;">
                      <div class="ivu-table-cell">
                        <img :src="$store.state.imgBaseUrl + item.img_json[0].url" style="width: 60px; height: 60px; vertical-align: middle; border: 1px solid rgb(244, 242, 242); padding: 1px; margin: 2px 0px; background-color: rgb(255, 255, 255);">
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="ivu-table-tip" style="display: none;">
                <table cellspacing="0" cellpadding="0" border="0">
                  <tbody>
                  <tr>
                    <td><span>暂无筛选结果</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" slot="footer"  style="overflow: hidden;">
        <Page style="float:left;" :total="pageInfo.totalItems" show-elevator class-name="pagination-content" show-total @on-change="pageChange" :current="pageInfo.currentPage" :page-size="pageInfo.limit" size="small"></Page>
        <Button class="pull-right" type="primary" @click="confirm">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import {getArticles} from '../../../service/article.manage/article.manage.service'
  export default {
    components: {
      draggable
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {
        article: []
      }
    },
    computed: {

    },
    data () {
      return {
        modal2: false,
        type: 'sliderArticle',
        selectIds: [],
        selectId: '',
        searchParams: {
          group_ids: 0,
          title: '',
          c_time: '',
          u_time: '',
          read_num: ''
        },
        pageInfo: {
          currentPage: 1,
          limit: 5,
          totalItems: 0
        },
        data: [],
        selectAll: false
      }
    },
    created () {
      // JSON.parse(JSON.stringify(item))
      this.content.article.forEach((item, index) => {
        if (Object.prototype.toString.call(item.img_json) === '[object String]') {
          this.content.article[index].img_json = JSON.parse(item.img_json);
          this.content.article[index].first_img = this.content.article[index].img_json[0].url;
        } else {
          this.content.article[index].first_img = item.img_json[0].url
        }
      })
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      addArticle () {
        this.modal2 = true;
        this.getArticleList();
      },
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },

      selectAllItem () {
        if (this.selectAll) {
          if (this.content.article.length >= 20) {
            this.$Message.info('最多一共可以选择20篇资讯！');
          } else {
            this.data.forEach((item, index) => {
              if (!item.issel) {
                this.content.article.push(item);
              }
              item.issel = this.selectAll;
            })
            this.content.article.splice(19, this.content.article.length - 20);
          }
        } else {
          this.data.forEach((item, index) => {
            if (item.issel) {
              this.content.article.forEach((ouitem, ouindex) => {
                if (ouitem.id == item.id) {
                  this.content.article.splice(ouindex, 1)
                }
              })
            }
            item.issel = this.selectAll;
          })
        }
      },

      selectItem (item, index) {
        if (item.issel) {
          if (this.content.article.length >= 20) {
            this.$Message.info('最多一共可以选择20篇资讯！');
          } else {
            this.selectAll = true;
            this.content.article.push(item);
            this.data.forEach((item, index) => {
              if (!this.data[index].issel) {
                this.selectAll = false;
              }
            })
          }
        } else {
          this.selectAll = false;
          this.content.article.forEach((ouitem, ouindex) => {
            if (ouitem.id == item.id) {
              this.content.article.splice(ouindex, 1)
            }
          })
        }
      },

      confirm () {
        this.searchParams.currentPage = 1;
        this.searchParams.title = '';
        this.modal2 = false;
        this.data = []
      },

      remove (index) {
        this.content.article.splice(index, 1)
      },

      search () {
        this.pageInfo.currentPage = 1;
        this.getArticleList();
      },

      close () {
        this.data = []
      },

      getArticleList () {
        // let params = JSON.parse(JSON.stringify(this.searchParams));
        // params.page = this.pageInfo.currentPage;
        // params.pagesize = this.pageInfo.limit;
        // getArticles(params, (res) => {
        //   if (res.errcode == 0) {
        //     this.data = res.data.list;
        //     this.selectAll = true;
        //     this.data.forEach((item, index) => {
        //       this.data[index].img_json = JSON.parse(item.img_json);
        //       this.data[index].first_img = this.data[index].img_json[0].url;
        //       this.data[index].issel = false;
        //       this.content.article.forEach((ouitem, ouindex) => {
        //         if (ouitem.id == item.id) {
        //           this.data[index].issel = true;
        //         }
        //       })
        //     })
        //     this.data.forEach((item, index) => {
        //       if (!this.data[index].issel) {
        //         this.selectAll = false;
        //       }
        //     })
        //     this.pageInfo.totalItems = res.data.count;
        //   } else {
        //     this.$Message.error(res.errmsg);
        //   }
        // }, () => {
        //   this.$Message.error('系统繁忙，请稍后重试！');
        // })
      },
      pageChange (value) {
        this.pageInfo.currentPage = value;
        this.getArticleList()
      }
    }
  }
</script>

<style scoped>
  .edit-shop-customArticle {
    background-color: #fff;
  }
  .edit-shop-customArticle .edit-input{
    width: 100px;float: left;
  }
  .edit-shop-customArticle .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-customArticle .edit-tips{
    float:left;margin-left: 20px;margin-top: 1px;color: #999;
  }
  .edit-shop-customArticle .ivu-form-item{
    height: 34px;
  }
  .edit-shop-content .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }
  .dragArea{
    margin: 5px 0 0 12px;
  }
  .dragArea > .goods-wrap {
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px 10px 0px 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
  }
  .dragArea > .goods-wrap:hover {
    cursor: move;
    border: 1px #cd6e00 dashed;
  }
  .dragArea > .goods-wrap > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .dragArea > .goods-wrap > i.del-goods {
    display: none;
    position: absolute;
    font-size: 18px;
    top: -4px;
    right: -4px;
    cursor: pointer;
    color: #a3a3a3;
  }
  .dragArea > .goods-wrap > i.del-goods:hover {
    color: #333;
  }
  .dragArea > .goods-wrap:hover > i.del-goods {
    display: block;
  }
</style>
