<template>
  <div class="module-sliderGoods">
    <div class="mod-shop-sliderGoods">
      <div class="view-goods-slide">
        <div class="goods-slide"  v-if="content.goods.length == 0">
           <div class="good-block">
                 <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                   <div class="u-des"  v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                  </div>
            </div>
            <div class="good-block">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                  </div>
            </div>
            <div class="good-block">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                  </div>
            </div>
        </div>
        <div class="goods-slide"  v-else>
            <template v-for="(item, index) in content.goods">
                <div class="good-block" :key="index" v-if="index < 3">
                    <img  :src="$store.state.imgBaseUrl + item.img" resize="cover" class="u-img"/>
                    <div class="u-des"  v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales">
                        <p class="name" v-if="content.show_ele.name">{{item.title}}</p>
                        <p class="price"  v-if="content.show_ele.price"><i>￥</i>{{item.price}}</p>
                        <p class="sales" v-if="content.show_ele.sales">销量：{{item.csale}}件</p>
                    </div>
                </div>
            </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'sliderGoods'
      }
    }
  }
</script>

<style scoped>
  .mod-shop-sliderGoods{background-color: #f0f3f6;}
  .view-goods-slide{
    width: 298px;
    overflow: hidden;
  }
  .goods-slide{
    width: 498px;
    padding: 6px 6px 8px 6px;
    overflow: hidden;
  }
   .good-block{
     width:120px;
     margin-right: 9px;
     border-radius: 5px;
     background-color: #fff;
     float: left;
   }
   .good-block:last-child{
     margin-right: 0;
   }
   .good-block img{
     width: 100%;
     height:110px;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
   }
   .good-block .name{
     font-size: 13px;
    color: #424141;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-top: 10px;
    height: 18px;
   }
   .good-block .u-des{
     padding-left: 8px;
     margin-bottom:13px;
   }
    .good-block .price{
      font-size: 16px;
      color: #ff5500;
      margin-top: 9px;
    }
    .good-block .price i{
      font-size: 13px;
      color: #ff5500;
    }
    .good-block .sales{
      font-size:12px;
      color: #8e8e8e;
      margin-top:9px;
    }
</style>
