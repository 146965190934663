<template>
  <div class="module-map">
    <div class="mod-shop-map">
     <div class="view-map" >
        <div class="map-address">
          <img src="https://dshop-static.tsphp.com/applet_weapp/images/icon2/icon_13.png">
          <span v-if="content.address != ''">{{content.address}}</span>
          <span v-else>显示地址</span>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <iframe class="iframe1" v-if="content.is_show == 0"
        :src="'https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:'+this.content.lat+','+this.content.lng+';&key=BK7BZ-3LOKW-Q3URK-OUXWR-S7VQO-VEFBH&referer=myapp'"></iframe>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'map',
        mapUrl: ''
      }
    }
  }
</script>

<style scoped>
  .view-map iframe {
    height: 165px;
    width: 100%;
    border: none;
  }

  .map-address {
    display: inline-block;
    width: 297px;
    padding: 15px 4px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
    text-align: right;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-address img {
    width: 12px;
    height: 15px;
    display: inline-block;
    margin-left: 4px;
  }
  .map-address .fa-map-marker {
    font-size: 16px;
    float: left;
    color: #444;
    margin-left: 4px;
  }

  .map-address .fa-angle-right {
    float: right;
    color: #444;
    font-size: 20px;
  }

  .map-address span {
    width: 258px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #333;
    text-align: left;
    padding-left: 10px;
  }
</style>
