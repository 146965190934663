<template>
    <div class="edit-map">
        <div class="edit-shop-header">
            <h3>地图设置</h3>
            <div class="edit-shop-delete">
                <Poptip
                        confirm
                        placement="left"
                        title="您确认删除吗？"
                        @on-ok="deleteComs()"
                        @on-cancel="cancel">
                    <a href="javascript:void(0);">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </a>
                </Poptip>
            </div>
        </div>
        <div class="edit-shop-content" style="height:500px;">
            <section class="edit-shop-map">
                <el-form :model="content" :label-width="80">
                    <el-form-item label="显示地址：" prop="address">
                        <Cascader style="width: 95%" :data="provinceList" :clearable="false" v-model="selectAttr"
                                  :load-data="loadData" @on-change="changeCascader" placeholder="请选择所属的省份-城市-县区"
                                  :render-format="label => label.join('-')"></Cascader>
                        <div style="margin-top:10px;margin-bottom:10px;">
                            <Input v-model="content.address" style="width: 78%"></Input>
                            <Button type="primary" @click="searchMap()">地图定位</Button>
                            <!-- <Button type="primary" @click="searchMap2()">地图检索</Button> -->
                        </div>
                        <div style="margin-bottom:10px;">
                            <Button type="defalut" long="90%" @click="searchMap2()" class="searchBtn">地图定位不准确？搜索一下，选点
                            </Button>
                        </div>
                        <div id="container" class="container" style="width:50%; height:300px;float:left"></div>
                        <div style="width:48%; height:300px;border:0.5px solid #dddee1;float:right;overflow-y:auto;">
                            <div v-for="(adr,ind) in addressSelectList" :key="ind"
                                 :class="[(content.lng+''==adr.latLng.lng+'' && content.lat+''==adr.latLng.lat+'')?'address_select_item selected':'address_select_item']"
                                 @click="selectAddrItem(adr)">
                                <span>{{adr.address}}</span>
                            </div>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="地图：" prop="is_show" v-if="!(content.from && content.from == 'super_form')">
                         <RadioGroup v-model="content.is_show">
                          <Radio value="1" :label="1">不显示</Radio>
                          <Radio value="0" :label="0">显示</Radio>
                        </RadioGroup>
                    </el-form-item> -->
                </el-form>
            </section>
        </div>
    </div>
</template>

<script>
    // import {getRegionAll} from '../../../service/common/common'

    export default {
        props: {
            index: {
                type: Number,
                default: 0
            },
            content: {}
        },
        computed: {
            selectAttr() {
                return [this.content.province, this.content.city, this.content.district]
            }
        },
        data() {
            return {
                type: 'map',
                pcd: '',
                regions: {},
                searchService: [], // 地图对象
                geocoder: {}, // 地址解析
                markers: null, // 地图上的marker
                cityList: [],
                provinceList: [],
                addressSelectList: []
            }
        },
        mounted() {
            this.getRegionAll();
            let _this = this;
            let qq = window.qq;
            _this.markers = [];
            var center = new qq.maps.LatLng(this.content.lat != '' ? this.content.lat : 39.916527, this.content.lng != '' ? this.content.lng : 116.397128);
            var map = new qq.maps.Map(document.getElementById('container'), {
                center: center,
                zoom: 15
            });
            if (this.content.address) {
                this.addressSelectList = [{
                    latLng: {
                        lng: this.content.lng,
                        lat: this.content.lat
                    },
                    address: this.content.address
                }]
            }
            // 设置Poi检索服务，用于本地检索、周边检索
            _this.searchService = new qq.maps.SearchService({
                // 检索成功的回调函数
                complete: function (results) {
                    // 设置回调函数参数
                    var pois = results.detail.pois;
                    var infoWin = new qq.maps.InfoWindow({
                        map: map
                    });
                    var latlngBounds = new qq.maps.LatLngBounds();
                    if (!pois) {
                        pois = [];
                        _this.$Message.error({
                            content: '地址不存在！',
                            duration: 3
                        });
                    }
                    _this.addressSelectList = pois
                    for (var i = 0, l = pois.length; i < l; i++) {
                        var poi = pois[i];
                        // 扩展边界范围，用来包含搜索到的Poi点
                        latlngBounds.extend(poi.latLng);

                        (function (n) {
                            var marker = new qq.maps.Marker({
                                map: map
                            });
                            console.log(pois[n])
                            marker.setPosition(pois[n].latLng);
                            marker.setTitle(i + 1);
                            _this.markers.push(marker);
                            let name = pois[n].name || '';
                            _this.cityList.push({
                                value: pois[n].id,
                                label: name
                            })
                            qq.maps.event.addListener(marker, 'click', function () {
                                infoWin.open();
                                infoWin.setContent('<div style="width:180px;height:100px;">地址：' + pois[n].address + '</div>');
                                infoWin.setPosition(pois[n].latLng);
                                _this.content.lng = _this.content.lng == '' ? pois[n].latLng.lng + '' : _this.content.lng;// 经度
                                _this.content.lat = _this.content.lat == '' ? pois[n].latLng.lat + '' : _this.content.lat;// 纬度
                                _this.content.address = pois[n].address;
                            });
                        })(i);
                    }
                    // 调整地图视野
                    map.fitBounds(latlngBounds);
                },
                // 若服务请求失败，则运行以下函数
                error: function () {
                    _this.$Message.error({
                        content: '地址不存在！',
                        duration: 3
                    });
                }
            });
            // 地址解析 Geocoder
            _this.geocoder = new qq.maps.Geocoder({
                complete: function (results) {
                    // 设置回调函数参数
                    var result = results.detail.location
                    if (result) {
                        map.setCenter(result);
                        var marker = new qq.maps.Marker({
                            map: map,
                            position: result
                        });
                        var infoWin = new qq.maps.InfoWindow({
                            map: map
                        });
                        _this.content.lng = result.lng;// 经度
                        _this.content.lat = result.lat;// 纬度
                        _this.markers.push(marker);
                        qq.maps.event.addListener(marker, 'click', function () {
                            infoWin.open();
                            infoWin.setPosition(result);
                            infoWin.setContent('<div style="width:180px;height:100px;">地址：' + results.detail.address + '</div>');
                            _this.content.address = results.detail.address;
                        });
                    } else {
                        _this.$Message.error({
                            content: '地址不存在！',
                            duration: 3
                        });
                    }
                }
            })
            // 地图定位
            // if (this.content.provinceName && this.content.address) {
            //   _this.searchKeyword(this.content.provinceName, this.content.cityName, this.content.districtName, this.content.address, 5);
            // }
            if (this.content.lng && this.content.lat) {
                var marker = new qq.maps.Marker({
                    map: map,
                    position: center
                });
                marker.setPosition(center);
            }
        },
        watch: {
            'content': {
                handler(newValue, oldValue) {
                    this.contentChange()
                },
                deep: true
            }
        },
        methods: {
            selectAddrItem(adr) {
                this.content.lng = adr.latLng.lng + ''
                this.content.lat = adr.latLng.lat + ''
                this.content.address = adr.address
            },
            contentChange() {
                this.$emit('message', this.type, this.content);
            },
            deleteComs() {
                this.$emit('delete', this.index);
            },
            // 获取省市区
            getRegionAll() {
                var _this = this
                getRegionAll((res) => {
                    _this.regions = res
                    res[1].forEach(function (item) {
                        _this.provinceList.push({
                            id: item.id,
                            value: item.id,
                            label: item.title,
                            parent_id: item.parent_id,
                            children: [],
                            loading: false
                        })
                    })
                })
            },
            loadData(item, callback) {
                item.loading = true
                if (this.regions[item.id]) {
                    this.regions[item.id].forEach((one) => {
                        let subItem = {
                            id: one.id,
                            value: one.id,
                            label: one.title,
                            parent_id: one.parent_id
                        }
                        if (this.regions[subItem.id]) {
                            subItem.children = []
                            subItem.loading = false
                        }
                        item.children.push(subItem)
                    })
                }
                item.loading = false
                callback()
            },
            changeCascader(value, selectData) {
                var province = selectData[0].id;
                var provinceName = selectData[0].label;
                var city = selectData[1].id;
                var cityName = selectData[1].label;
                var district = selectData[2].id;
                var districtName = selectData[2].label;
                this.content.province = province;
                this.content.provinceName = provinceName;
                this.content.city = city;
                this.content.cityName = cityName;
                this.content.district = district;
                this.content.districtName = districtName;
            },
            searchKeyword(province, city, district, addres, pageCapacitys) { // 设置搜索的范围和关键字等属性
                // province 省
                // addres 详细地址
                let _this = this;
                var keyword = addres || '';
                var region = '';
                // var pageIndex = 0;
                // var pageCapacity = pageCapacitys || 10;
                if (!province) {
                    _this.$Message.error({
                        content: '请选择省市区！',
                        duration: 3
                    });
                    return;
                }
                _this.clearOverlays(_this.markers)
                if (keyword.indexOf(province) == -1) {
                    region += province
                    if (keyword.indexOf(city) == -1) {
                        region += city
                        if (keyword.indexOf(district) == -1) {
                            region += district
                        }
                    }
                }
                // 根据输入的关键字在搜索范围内检索
                if (keyword != '') {
                    _this.geocoder.getLocation(region + keyword);
                }
            },
            searchKeyword2(province, city, district, addres, pageCapacitys) { // 设置搜索的范围和关键字等属性
                // province 省
                // addres 详细地址
                let _this = this;
                var keyword = addres || '';
                var region = '';
                // var pageIndex = 0;
                // var pageCapacity = pageCapacitys || 10;
                if (!province) {
                    _this.$Message.error({
                        content: '请选择省市区！',
                        duration: 3
                    });
                    return;
                }
                _this.clearOverlays(_this.markers)
                // 根据输入的城市设置搜索范围
                if (keyword.indexOf(province) == -1) {
                    region += province
                    if (keyword.indexOf(city) == -1) {
                        region += city
                        if (keyword.indexOf(district) == -1) {
                            region += district
                        }
                    }
                }
                // 根据输入的关键字在搜索范围内检索
                if (keyword != '') {
                    _this.searchService.search(region + keyword);
                }
            },
            clearOverlays(overlays) { // 清除地图上的marker
                let overlay;
                for (overlay in overlays) {
                    overlays[overlay].setMap(null);
                }
            },
            searchMap() {
                this.searchKeyword(this.content.provinceName, this.content.cityName, this.content.districtName, this.content.address, 5)
            },
            searchMap2() {
                this.searchKeyword2(this.content.provinceName, this.content.cityName, this.content.districtName, this.content.address, 5)
            }
        }
    }
</script>

<style scoped>
    .edit-shop-content .ivu-form-item {
        margin-bottom: 10px;
    }

    .edit-shop-content .ivu-form-item:after {
        display: none;
    }

    .edit-shop-content .ivu-cascader .ivu-input {
        opacity: 0;
    }

    .searchBtn {
        border-color: #F98A10;
        color: #F98A10;
        background: #fff;
    }

    .address_select_item {
        background-color: #f7f7f7;
        border-bottom: 0.5px solid rgb(221, 222, 225);
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 2px 5px;
    }

    .address_select_item.selected {
        color: #fff;
        background: #F98A10;
    }

    .address_select_item:hover {
        cursor: pointer;
    }

    .address_select_item:last-child {
        border-bottom: 0
    }
</style>
