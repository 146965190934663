<template>
  <div class="edit-white">
    <div class="edit-shop-header">
      <h3>占位符设置</h3>
      <div class="edit-shop-delete">
        <el-popconfirm
            title="您确认删除吗？"
            @confirm="deleteComs()">
          <a href="javascript:void(0);">
            <i class="el-icon-delete"></i>
          </a>
        </el-popconfirm>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-white">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="高度：">
            <el-input-number v-model="content.height" :min="1" :max="1000" size="small" label="请输入高度" controls-position="right"></el-input-number>
            <span class="edit-unit">px</span>
          </el-form-item>
          <el-form-item label="背景颜色：">
            <el-color-picker v-model="content.bgcolor"></el-color-picker>
          </el-form-item>
          <el-form-item label="透明度：">
            <el-input-number v-model="content.opacity" :min="1" :max="100" size="small" label="请输入透明度" controls-position="right"></el-input-number>
            <span class="edit-unit">填1-100之间的数字</span>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'blank'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      }
    }
  }
</script>

<style scoped>
  .edit-shop-white {
    background-color: #fff;
  }
  .edit-shop-white .edit-input{
    width: 100px;float: left;
  }
  .edit-shop-white .edit-unit{
    margin-left: 10px;
  }
  .edit-shop-white .edit-tips{
    float:left;margin-left: 20px;margin-top: 1px;color: #999;
  }
  .edit-shop-white .ivu-form-item{
    height: 34px;
    margin-bottom: 15px;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }
</style>
