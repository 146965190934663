const floatComs = [
  {
    type: 'drawerNav',
    name: '抽屉式',
    icon: 'fa-inbox',
    content: {
      style: 1, // 图标样式 1大图标 2小图标
      show: 1, // 显示方式 1图标和文字 2只有图标 3只有文字
      array: 1, // 排列方式 1左图右文 2上图下文
      size: 2, // 图标大小 1 2
      margin: 0,
      bgimg: '', // 背景图片
      bgcolor: '#ffffff', // 背景颜色
      bgopacity: 100, // 背景透明度
      icon: '2018/08/27/Fjw69F30XjUi4lrGtiTzJ6MEgF97.png', // 开关图标
      icon_color: '', // 图标颜色
      line: false, // 分割线 false隐藏 true显示
      linecolor: '#eeeeee', // 分割线颜色
      align: 1, // 文字对其方式 1居左 2居中 3居右
      data: [
        {
          nav_name: '菜单1', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        },
        {
          nav_name: '菜单2', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  },
  {
    type: 'sideFixed',
    name: '侧固定',
    icon: 'fa-align-left',
    content: {
      style: 1, // 图标样式 1大图标 2小图标
      show: 1, // 显示方式 1图标和文字 2只有图标 3只有文字
      array: 1, // 排列方式 1左图右文 2上图下文
      size: 2, // 图标大小 1 2
      margin: 0,
      bgimg: '', // 背景图片
      bgcolor: '#ffffff', // 背景颜色
      bgopacity: 100, // 背景透明度
      line: false, // 分割线 false隐藏 true显示
      linecolor: '#eeeeee', // 分割线颜色
      data: [
        {
          nav_name: '菜单1', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        },
        {
          nav_name: '菜单2', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  },
  {
    type: 'latticeNav',
    group: 1,
    name: '宫格式',
    icon: 'fa-th',
    content: {
      style: 1, // 显示风格 1 2
      num: 2, // 每行菜单的数量，有三个值【2，3，4，5】，默认为2
      show: 1, // 显示方式 1 2 3
      fixed: 1, // 吸附方式 1 2
      size: 1, // 图标大小 1 2
      line_margin: 10, // 行间距像素
      padding_top: 10, // 上边距像素
      padding_bottom: 10, // 下边距'像素px'
      margin: 0,
      bgimg: '', // 背景图片
      bgcolor: '#ffffff', // 背景颜色
      bgopacity: 100, // 背景透明度
      data: [
        {
          nav_name: '菜单1', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        },
        {
          nav_name: '菜单2', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  },
  {
    type: 'surroundNav',
    name: '点聚式',
    icon: 'fa-dot-circle-o',
    content: {
      show: 1, // 显示方式 1 2
      fixed: 2, // 显示位置 1 2 3 4，默认为居中显示【2】
      icon: '2018/08/28/FhGjkmj1LmurKasd3h2DxajNSZGA.png', // 导航图标
      icon_color: '', // 图标颜色
      icon_opacity: 0, // 图标透明度
      data: [
        {
          nav_name: '菜单1', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_bgcolor: '#ffffff', // 菜单背景颜色
          nav_bgopacity: 100, // 菜单背景透明度
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        },
        {
          nav_name: '菜单2', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_color: '#000000', // 菜单文字颜色
          nav_bgcolor: '#ffffff', // 菜单背景颜色
          nav_bgopacity: 100, // 菜单背景透明度
          nav_link: { // 菜单链接
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  }
];

export default floatComs
