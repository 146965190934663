<template>
  <div class="module-phone">
    <div class="mod-shop-phone">
      <div class="view-phone" :style="{backgroundColor:content.bgcolor}" >
          <ul>
            <li><img class="u-phoneIcon" :src="source_domain + '/images/tel.png'" /></li>
            <li><span class="phonetxt" >{{content.title}}{{content.phone}}</span></li>
            <li> <i class="el-icon-arrow-right"></i></li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'phone',
        source_domain: this.config.SOURCE_DOMAIN,
      }
    }
  }
</script>

<style scoped>
.view-phone{
      background-color: rgba(255, 255, 255, 1);
      padding: 0px 10px;
       position: relative;
      box-sizing: border-box;flex-direction: row;align-items: center;
      display: flex;
      justify-content: center;align-items: center;

    }
    .view-phone ul{width:100%}
    .view-phone ul li{float: left;}
     .view-phone ul li:last-child{float: right; }
     .phonetxt{
       font-size: 14px;
      line-height: 45px;
      color: #333333;
      overflow: hidden;
      margin-left: 10px;
     }

    .u-phoneIcon{
      width: 15px;
      height: 15px;
      margin-top:14px;
    }
    .el-icon-arrow-right{
     line-height: 45px;
    }
</style>
