<template>
    <div class="edit-information">
        <div class="edit-shop-header">
            <h3>资讯类导航设置</h3>
            <div class="edit-shop-delete">
            </div>
        </div>
        <div class="edit-shop-content">
            <section class="edit-shop-information">
                <el-form :model="content" label-width="110px">
                    <el-form-item label="每行菜单数量：" prop="num">
                        <el-select v-model="content.num" style="width: 240px;" @on-change="changeNum" size="small">
                            <el-option value="1">1</el-option>
                            <el-option value="2">2</el-option>
                            <el-option value="3">3</el-option>
                            <el-option value="4">4</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="导航位置：" prop="position">
                        <el-select v-model="content.position" style="width: 240px;" size="small">
                            <el-option value="left">居左</el-option>
                            <el-option value="center">居中</el-option>
                            <el-option value="right">居右</el-option>
                        </el-select>
                    </el-form-item>
                    <div class="tags-data">
                        <div class="block" v-for="(item,index) in content.tags" :key="index">
                            <!--Poptip v-if="index > 1" style="position: absolute; right: 10px; top: 0px;"
                                    confirm
                                    transfer="true"
                                    placement="left"
                                    title="确认删除吗？"
                                    @on-ok="deleteItem(index)"
                                    @on-cancel="cancel">
                                <a href="javascript:void(0);">
                                    <i class="fa fa-trash" aria-hidden="true"></i> 删除
                                </a>
                            </Poptip-->
                            <el-form :model="content" label-width="110px" size="small">
                                <el-form-item label="菜单文字：">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-input v-model="item.nav_name" maxlength="5"></el-input>
                                        </el-col>
                                        <el-col :span="12">
                                            <p class="label tit">最多可输入5个字</p>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="选择链接：">
                                    <el-row>
                                        <el-col :span="12">
                                            <select-link class="select-links-opacity" :setData="item.nav_link" :index="index" :setFunc="setLink"></select-link>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="选择图标：">
                                    <el-row>
                                        <el-col :span="5">
                                            <SelectImgs :selectNum="1" :selectData="[item.nav_icon?item.nav_icon:'']" :idx="index" :selectFunc="updateDataImgs"></SelectImgs>
                                        </el-col>
                                        <el-col :span="12">
                                            <p class="label tit">建议尺寸50px * 50px</p>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="背景颜色：">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-color-picker v-model="content.nav_bgcolor"></el-color-picker>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="透明度：">
                                    <el-row>
                                        <el-col :span="6">
                                            <el-input-number :max="100" :min="1" v-model="item.nav_opacity" controls-position="right"></el-input-number>
                                        </el-col>
                                        <el-col :span="3">
                                            <p class="label tit">%</p>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-row style="height:40px;margin:15px 0 0 10px;">
                        <el-col :span="6" class="form-button" style="width: 100px;">
                            <el-button type="primary" size="small" @click="addTags" class="fl" :disabled="content.tags.length > 19">添加菜单</el-button>
                        </el-col>
                        <el-col :span="12" class="label tit" style="height:32px;line-height:32px;">
                            <span>最多一共可以选择20个菜单</span></el-col>
                    </el-row>
                </el-form>
            </section>
        </div>
    </div>
</template>

<script>
    import SelectImgs from '../../components/SelectImgs'
    import selectLink from './select.link.vue'
    export default {
        props: {
            index: {
                type: Number,
                default: 0
            },
            content: {}
        },
        computed: {},
        components: {
            SelectImgs,
            selectLink
        },
        data() {
            return {
                'type': 'information'
            }
        },
        watch: {
            'content': {
                handler(newValue, oldValue) {
                    this.contentChange()
                },
                deep: true
            }
        },
        methods: {
            contentChange() {
                this.$emit('message', this.content);
            },
            deleteComs() {
                this.$emit('delete', this.index);
            },
            addTags() {
                this.content.tags.push({
                    nav_name: '导航',
                    nav_bgcolor: '#ffffff',
                    nav_opacity: 100,
                    nav_icon: '',
                    nav_link: {
                        type: '',
                        name: '',
                        id: ''
                    }
                })
            },
            deleteItem(index) {
                this.content.tags.splice(index, 1);
            },
            changeNum(num) {
                if (this.content.tags.length < num) {
                    for (var i = this.content.tags.length; i < num; i++) {
                        this.content.tags.push({
                            nav_name: '菜单' + (i + 1),
                            nav_bgcolor: '#ffffff',
                            nav_opacity: 100,
                            nav_icon: '',
                            nav_link: {
                                type: '',
                                name: '',
                                id: ''
                            }
                        })
                    }
                }
            },
            setLink (item, index) {
                this.content.tags[index].nav_link = item
            },
            updateDataImgs(item, index) {
                this.content.tags[index].nav_icon = item.pic
            }
        }
    }
</script>

<style scoped>
    .tags-data {
        padding-left: 10px;
    }

    .tags-data .block {
        background-color: #f8f8f9;
        padding: 10px 0;
        margin-bottom: 10px;
        min-width: 500px;
    }

    .block-row {
        height: 40px;
        margin: 0 10px;
    }

    .block-row.title {
        border-bottom: 1px solid #eee;
        height: 30px;
        margin: 0;
        padding: 0 10px;
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    .block-row .label {
        height: 32px;
        line-height: 32px;
    }

    .tit {
        color: #999;
        margin-left: 10px;
    }
</style>
