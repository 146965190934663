<template>
  <div class="module-customGoods">
    <div class="mod-shop-customGoods">
      <div class="view-goods">
           <!--双列-->
        <section class="goods-type-1" v-if="content.style == 1">
           <ul class="m-double overflow" v-if="content.goods.length == 0">
              <li :style="{width:(width-9)/2+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
              <li :style="{width:(width-9)/2+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称 </p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
           </ul>
           <ul class="m-double overflow" v-else>
              <li :style="{width:(width-9)/2+'px'}" v-for="(item, index) in content.goods" :key="index">
                  <img :src="item.img" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">{{item.title}}</p>
                      <p class="price"  v-if="content.show_ele.price"><i>￥</i>{{item.price}}</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：{{item.sales}}{{ item.unit_name }}</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
           </ul>
        </section>
         <!--小图单列-->
        <section class="goods-type-2" v-else-if="content.style == 2">
              <ul class="smallClm" v-if="content.goods.length == 0">
                  <li>
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                          <p class="name"  v-if="content.show_ele.name">商品名称</p>
                          <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                          <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
                  <li>
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart" >
                          <p class="name" v-if="content.show_ele.name"> 商品名称</p>
                          <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                          <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
              </ul>
              <ul class="smallClm" v-else>
                  <li v-for="(item, index) in content.goods" :key="index">
                      <img :src="item.img" resize="cover" class="u-img"/>
                      <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                          <p class="name"  v-if="content.show_ele.name">{{item.title}}</p>
                          <p class="price" v-if="content.show_ele.price"><i>￥</i>{{item.price}}</p>
                          <p class="sales" v-if="content.show_ele.sales">销量：{{item.sales}}{{ item.unit_name}}件</p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
              </ul>
        </section>
        <!--大图单列-->
        <section class="goods-type-3" v-else-if="content.style == 3">
              <ul class="bigClm" v-if="content.goods.length == 0">
                  <li :style="{width:(width-20)+'px'}">
                     <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                       <div class="u-des"   v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">

                          <p class="name " v-if="content.show_ele.name"> 商品名称</p>
                          <p>
                              <span class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</span>
                              <span class="sales" v-if="content.show_ele.sales">销量：666件</span>
                          </p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
                    <li :style="{width:(width-20)+'px'}">
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des"  v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">

                          <p class="name " v-if="content.show_ele.name">商品名称</p>
                          <p>
                              <span class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</span>
                              <span class="sales" v-if="content.show_ele.sales">销量：666件</span>
                          </p>
                         <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
              </ul>
              <ul class="bigClm">
                  <li :style="{width:(width-20)+'px'}" v-for="(item, index) in content.goods" :key="index">
                     <img :src="item.img" resize="cover" class="u-img"/>
                       <div class="u-des"   v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                          <p class="name " v-if="content.show_ele.name"> {{item.title}}</p>
                          <p>
                              <span class="price"  v-if="content.show_ele.price"><i>￥</i>{{item.price}}</span>
                              <span class="sales" v-if="content.show_ele.sales">销量：{{item.sales}}{{ item.unit_name}}件</span>
                          </p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
              </ul>
        </section>
        <!--三列-->
        <section class="goods-type-4" v-if="content.style == 4">
          <ul class="m-double overflow">
            <li :style="{width:(width-9)/3+'px'}">
              <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
              <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                <p class="name" v-if="content.show_ele.name">商品名称</p>
                <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
              </div>
            </li>
            <li :style="{width:(width-9)/3+'px'}">
              <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
              <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                <p class="name" v-if="content.show_ele.name">商品名称</p>
                <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
              </div>
            </li>
            <li :style="{width:(width-9)/3+'px'}">
              <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
              <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                <p class="name" v-if="content.show_ele.name">商品名称 </p>
                <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
              </div>
            </li>
          </ul>

        </section>
       </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      width () {
        return 298;
      }
    },
    data () {
      return {
        'type': 'customGoods'
      }
    },
    created () {

    }
  }
</script>

<style scoped>
  .view-goods{display: -webkit-box;}
  .mod-shop-customGoods{background-color: #f0f3f6;}
  .goods-type-1{
        padding: 0 3px;
    }
    .mbt5{margin-bottom: 5px;}
    .mbt8{margin-bottom: 8px;}
    .goods-type-1 .m-double li{float: left;width: 49%;background-color: #fff;margin-bottom: 3px;}
    .goods-type-1 .m-double li:nth-child(odd){
         margin-right:3px;
     }
   .goods-type-1 .m-double li .u-img{width: 100%;height: 150px;}
   .goods-type-1 .m-double li .u-des{position: relative;padding: 8px 9px 9px;min-height: 32px;}

   .goods-type-1 .u-des .name{font-size: 13px;line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        color: #424141;
        height: 35px;
        font-size: 13px;
        margin-bottom: 5px;
    }
   .u-des .price{font-size: 16px;color: #ff5500;}
   .u-des .price i{font-size: 13px;color: #ff5500;    font-style: inherit;}
   .u-des .sales{font-size: 12px;color: #8e8e8e;line-height: 24px;}
   .shopCart{position: absolute;right: 9px;bottom:9px;width: 17px;height: 17px; }
   .u-salesPmIcon{width:26px;height: 13px; display: inline-block;margin-right: 3px;top:2px;}
    .goods-type-2{width:100%;}
    .goods-type-2 .smallClm{
         background-color: #fff;
         padding: 10px 0px 10px 9px;
    }
    .goods-type-2 .smallClm li{
        position: relative;
        padding: 10px 9px 10px 0px;
       border-bottom:0.5px solid #eaeaea;
       display: inline-block;
        width: 100%;
    }
    .goods-type-2 .smallClm li:first-child{
        padding-top:0px;
    }
    .goods-type-2 .smallClm li:last-child{
         padding-bottom: 0px;
         border-bottom:0;
     }
    .goods-type-2 .smallClm li .u-img{width:80px;height: 80px;float: left;}
    .goods-type-2 .smallClm li .u-des{margin-left: 90px;min-height: 80px;}
    .goods-type-2 .u-des .name{font-size: 13px;line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        color: #424141;
        height: 35px;
        font-size: 13px;
         margin-bottom: 5px;
    }
    .goods-type-3 .bigClm{
        padding: 0 10px;
    }
    .goods-type-3 .bigClm li{
        border-radius:5px;
        background-color: #fff;
        margin-bottom: 10px;
    }
    .goods-type-3 .bigClm li:last-child{
        margin-bottom: 0;
    }
    .goods-type-3 .bigClm li .u-img{
        width:100%;height: 280px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .goods-type-3 .bigClm li .u-des{padding:10px;position: relative;min-height: 32px;}
    .goods-type-3 .bigClm li .name{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #424141;
        font-size: 13px;
        margin-bottom: 5px;
    }
    .goods-type-3 .bigClm li .u-des div{flex-direction: row;}
    .goods-type-3 .bigClm li .u-des .price{margin-right: 10px;}
  .goods-type-4 .m-double li{float: left;width: 32%;background-color: #fff;margin-bottom: 3px;margin-right: 3px;}
  .goods-type-4 .m-double li:nth-child(3n){margin-right:0;}
  .goods-type-4 .m-double li .u-img{width: 100%;}
  .goods-type-4 .m-double li .u-des{position: relative;padding: 8px 9px 9px;min-height: 32px;}
  .goods-type-4 .u-des .name{font-size: 13px;line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: #424141;
    font-size: 13px;
    margin-bottom: 5px;
  }
</style>
