<template>
  <div class="module-title">
    <div class="mod-shop-title">
      <div class="view-title" :style="{backgroundColor:content.bgcolor}">
         <img v-if="content.left_icon" class="left" :src="content.left_icon" />
         <span class="fwbold" style="flex:3" :style="{color: content.color}">{{content.left_title}}</span>
         <a href="javascript:void(0)" style="flex:1;" class="right">
            <span class="rightImg" :style="{color: content.morecolor}">{{content.right_title}}
              <i class="el-icon-arrow-right" :style="{color: content.morecolor}" v-if="content.right_link.type"></i></span>
          </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'title'
      }
    }
  }
</script>

<style scoped>
.view-title{
  background-color: rgba(255, 255, 255, 1);
  padding: 11px 10px 11px 12px;
  display: block;
  height: 43px;
}
.fwbold{
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 21px;
  letter-spacing: 0px;
  color: #333333;
  overflow: hidden;
  white-space: pre;
  width: 176px;
  display: block;
  float: left;
}
.right{text-align: right;}
.right span{
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  font-size: 12px;
  letter-spacing: 0px;
  color: #666666;
  text-align: right;
}
.right span i{
  font-size: 12px;
  color: #666666;
}
.left{
  width: 18px;
  height: 18px;
  margin: 1px 7px 0 -3px;
  float: left;
}
.rightImg{
  width: 76px;
  height: 25px;
  float: right;
}
</style>
