<template>
  <div class="edit-white">
    <div class="edit-shop-header">
      <h3>瀑布式导航设置</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-middlenav">
        <div class="edit-item" v-for="(item, index) in content.data" :key="index">
          <el-form :model="item" :label-width="90">
            <el-form-item label="图片高度：">
              <InputNumber v-model="item.height" class="edit-input" @on-change="InputNumberChange"></InputNumber>
              <span class="edit-unit">px</span>
              <p class="edit-tips">默认为300px，最大可输入1000</p>

              <Poptip v-if="index > 1" style="position: absolute; right: 2px; top: -2px;"
                      confirm
                      transfer="true"
                      placement="left"
                      title="确认删除吗？"
                      @on-ok="deleteItem(index)"
                      @on-cancel="cancel">
                <a href="javascript:void(0);">
                  <i class="fa fa-trash" aria-hidden="true"></i> 删除
                </a>
              </Poptip>
            </el-form-item>
            <el-form-item label="菜单文字：">
              <Input v-model="item.title" class="edit-input" maxlength="5"></Input>
              <p class="edit-tips">最多可输入5个字</p>
            </el-form-item>
            <Row style="height: 52px;">
              <Col span="12" style="height: 34px;width: 160px;">
                <el-form-item label="文字颜色：">
                  <colorPicker v-model="item.color"></colorPicker>
                </el-form-item>
              </Col>
            </Row>
            <el-form-item label="菜单图片：">
              <div class="choice-image-box" style="height: 110px; overflow: hidden">
                <single-select-img style="width:100px;float:left;" :img="item.img" :width="100" :height="100" :maxSize="1024" @updateImgList="(val) => setImgUrl(val.url, index, 'nav_hover_icon')"></single-select-img>
                <p class="imgTips">建议尺寸：50*50像素，大小不超过1M</p>
              </div>
            </el-form-item>
            <el-form-item label="菜单链接：">
              <!--<Select v-model="item.nav_link.type">-->
                <!--<Option v-for="(item, index) in navlinkList" :value="item.type" :key="item.index">{{ item.label }}</Option>-->
              <!--</Select>-->
              <div style="width: 400px;position: relative;">
                <Input v-model="item.link.name" icon="arrow-down-b" placeholder="选择链接"></Input>
                <select-link class="select-links-opacity" :data="item.link" :index="index"></select-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="overflow:hidden; margin-top: 10px;">
          <Button v-if="content.data.length < 20" type="primary" style="float: left; margin-left: 10px;" @click="addItem()">添加菜单</Button>
          <Button v-else disabled style="float: left; margin-left: 10px;">添加菜单</Button>
          <p class="edit-tips" style="margin-top: 7px;">最多一共可以有20个菜单</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import singleSelectImg from '../../components/FileUpload'
  import selectLink from './select.link.vue'
  export default {
    components: {
      singleSelectImg, selectLink
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {
        // data: []
        // rightData: []
      }
    },
    computed: {

    },
    data () {
      return {
        'type': 'waterFall'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    created () {

    },
    methods: {
      deleteItem (index) {
        this.content.data.splice(index, 1);
      },
      addItem (index) {
        this.content.data.push({
          title: '菜单名称', // 菜单名称
          img: '', // 图片
          height: 200,
          link: { // 导航链接
            type: '',
            name: '',
            id: ''
          }
        });
      },
      setImgUrl (url, index) {
        this.content.data[index].img = url;
      },
      contentChange () {
        this.$emit('message', this.type, this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      InputNumberChange (value) {
        if (parseInt(value) < 1) {
          this.$Message.info({
            content: '高度最小为1px',
            duration: 3
          });
          setTimeout(() => {
            this.content.height = 1
          }, 100)
        } else if (parseInt(value) >= 1 && parseInt(value) <= 1000) {
          setTimeout(() => {
            this.content.height = Math.round(parseInt(value))
          }, 100)
        } else if (parseInt(value) > 1000) {
          this.$Message.info({
            content: '高度最大为100px',
            duration: 3
          });
          setTimeout(() => {
            this.content.height = 1000
          }, 100)
        }
      },
      // selectChange (num) {
      //   if (this.content.data.length < num) {
      //     for (var i = this.content.data.length; i < num; i++) {
      //       this.content.data.push({
      //         title: '菜单' + (i + 1),
      //         height: 200,
      //         img: '',
      //         link: {
      //           type: '',
      //           name: '',
      //           id: ''
      //         }
      //       })
      //     }
      //   }
      // },
      chooseStyle (style) {
        this.content.style = style;
      }
    }
  }
</script>

<style scoped>
  .edit-shop-middlenav {
    background-color: #fff;
  }
  .edit-shop-middlenav {
    background-color: #fff;
  }
  .edit-shop-middlenav .edit-input{
    width: 140px;float: left;
  }
  .edit-shop-middlenav .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-middlenav .edit-tips{
    float:left;margin-left: 20px; margin-top: 1px;color: #999;
  }
  .edit-item{
    background-color: #f8f8f9;
    padding: 10px;
    margin: 15px 10px;
  }
  .edit-shop-middlenav .ivu-form-item{
    min-height: 34px;
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-row:before, .edit-shop-content .ivu-row:after, .edit-shop-content .ivu-form-item:before, .edit-shop-content .ivu-form-item:after{
    display: none;
  }

  .images-style .ivu-col{
    position: relative;
    margin-right: 37px;
    margin-bottom: 5px;
    display: block;
  }
  .images-style .ivu-col:last-child{
    margin-right: 0;
  }
  .images-style .ivu-col .img{

  }
  .images-style .ivu-col .yuan{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
    border-radius: 50%;
  }
  .images-style .ivu-col .fang{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
  }
  .images-style .ivu-col p{
    text-align: center;
    color: #999;
  }
  .images-style .ivu-col .img:hover{
    cursor: pointer;
  }
  .images-style .img.select{
    border: 2px solid #57a3f3;
  }
  .images-style .selecti{
    height: 23px;
    width: 22px;
    position: absolute;
    top: 25px;
    right: 3px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
  }
  .images-style .selecti i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
  .images-data .line{
    height: 80px;
    line-height: 80px;
  }
  .images-style{
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
    height: 80px;
  }
  .images-style:after, .images-style:before{
    display: block;
  }
  .imgTips{float: left; margin-top: 70px;margin-left: 10px;color:#999;}
</style>
