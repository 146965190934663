<template>
  <div class="module-middlenav">
    <div class="mod-shop-middlenav" :style="{backgroundColor: content.bgcolor}">
      <div class="mod-item-box" >
        <template v-for="(item, index) in content.data">
          <div class="mod-item" :style="{width: navWidth}" v-if="index < content.num * 2" :key="index">
          <span class="mod-img">
            <img v-if="item.nav_icon != ''" :class="{'yuan': content.style == 1, 'fang': content.style == 2}" :src="item.nav_icon">
            <img v-else :class="{'yuan': content.style == 1, 'fang': content.style == 2}" src="../../assets/images/icon-bg8.jpg">
          </span>
            <h3 class="mod-name" :style="{color: content.color}">{{item.nav_name}}</h3>
          </div>
        </template>
        <div class="mod-dots"  v-if="total > pageSize">
          <p>
            <!--span v-for="item in navPage"></span-->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {
      navWidth () {
        return 100 / this.content.num + '%'
      },

      total () {
        return this.content.data.length
      },

      pageSize () {
        return this.content.num * 2
      },

      navPage () {
        var total = this.content.data.length;
        var pageSize = this.content.num * 2;
        return Math.ceil(total / pageSize);
      }
    },
    data () {
      return {
        'type': 'middleNav'
      }
    },
    created () {

    }
  }
</script>

<style scoped>
  .mod-shop-middlenav{
    background-color: #fff;
  }
  .mod-item-box {
    overflow: hidden;
    text-align: center;
  }
  .mod-item {
    width: 33.33%;
    display: inline-block;
    text-align: center;
    padding: 10px;
  }
  .mod-img {
    display: block;
    height: 36px;
    width: 36px;
    background-color: #ddd;
    margin: 0 auto;
    border-radius: 50%;
  }
  .mod-img img.yuan{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .mod-img img.fang{
    height: 100%;
    width: 100%;
  }
  .mod-name {
    font-size: 12px;
    color: #444;
    font-weight: normal;
    margin-top: 7px;
    word-wrap: break-word;
    word-break: break-all;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
  }
</style>
