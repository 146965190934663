<template>
  <div class="edit-phone">
    <div class="edit-shop-header">
      <h3>公众号关注</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-focus">
       <el-form  :model="content" :label-width="100">
        <P>
          使用组件前，需前往微信小程序后台，在“设置” -> “接口设置” -> “公众号关注组件”中设置要展示的公众号。<br>
          <a href="http://bbdshop-static.tsphp.com/forum.php?mod=viewthread&tid=5959&extra=page%3D1">公众号关注组件开通方法？</a><br>
          <!-- <div class="text_a">
            <a>公众号关注组件开通方法？</a>
            <div class="text_hover">
              <p>第<span>1</span>步、登录小程序后台>设置>接口设置>点击关注公众号关注组件按钮。</p>
              <img src="assets/images/a_1.jpg" />
              <p>第<span>2</span>步、点击“我知道了” 再次点击上面开关按钮，小程序如果没有关联公众号请先关联。</p>
              <img src="assets/images/a_1.jpg" />
              <p>第<span>3</span>步、选择您要关注的同主体公众号，非同主体的无法绑定。</p>
              <img src="assets/images/a_1.jpg" />
              <p>第<span>4</span>步、按钮绿色为开通成功，刷新可修改绑定的公众号或关闭关联。</p>
              <img src="assets/images/a_1.jpg" />
            </div>
          </div> -->
          注：<br>
          1、小程序需要关联公众号。<br>
          2、设置的公众号需与小程序主体一致。<br>
          3、如果前端没有显示，可能是因为微信小程序后台没有设置该组件。<br>
          4、只有线下扫小程序码或者小程序二维码时，打开时才有“公众号组件”。
        </P>

        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'publicFocus'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      }
    }
  }
</script>

<style scoped>
.edit-shop-focus a{font-weight:bold;}
.edit-shop-focus p{line-height:20px;font-size:12px;}

/*.text_a{width:150px;}
.text_hover{display:none;position:absolute;width:500px;background-color:#fff;padding:15px 10px;border-radius: 4px;box-shadow: 0 1px 6px rgba(0,0,0,.2);}
.text_hover p{font-size:12px;color:#333;padding-bottom:10px;}
.text_hover span{color:#ff6e6e;margin:0 2px;}
.text_hover img{height:160px;width:300px;}
.text_a:hover .text_hover{display:block;}*/

/*.edit-shop-content  .ivu-form-item{
  margin-bottom: 10px;
}
.edit-shop-content  .ivu-form-item:after{
  display: none;
}
.tit{color:#999;}*/
</style>
