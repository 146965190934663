<template>
  <div class="edit-sliderGoods">
    <div class="edit-shop-header">
      <h3>横滑商品设置</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-sliderGoods">
        <el-form  :model="content" :label-width="80">
         <el-form-item label="显示元素：">
            <Checkbox v-model="content.show_ele.name" @on-change="checkBoxChange">商品名称</Checkbox>
            <Checkbox v-model="content.show_ele.price" @on-change="checkBoxChange">价格</Checkbox>
            <Checkbox v-model="content.show_ele.sales" @on-change="checkBoxChange">销量</Checkbox>
        </el-form-item>
        <Row>
          <Col span="6" class="form-button" style="width: 120px">
          <Button type="primary" v-if="content.goods.length < 20 "  @click="addGoods"><i type="plus-round"></i> 添加商品</Button>
          <Button v-else disabled><i type="plus-round"></i> 添加商品</Button>
          </Col>
            <Col span="18" class="form-span"><span>最多一共可以选择20个商品，拖拽可改变商品顺序</span></Col>
        </Row>
        <div class="drag" style="overflow: auto;">
        <draggable  class="dragArea" v-model="content.goods">
          <div v-for="(item, index) in content.goods" class="goods-wrap" :key="index">
            <img :src="$store.state.imgBaseUrl + item.img">
            <i @click.native="remove(index)" class="del-goods" type="close-circled"></i>
          </div>
        </draggable>
       </div>
        </el-form>
      </section>
    </div>
    <Modal v-model="modalShow" width="600">
        <div class="" slot="header">
          <label style="width:60px;    text-align: right;vertical-align: middle;float: left;font-size: 12px;color: #495060;line-height: 1;padding: 10px 12px 10px 0;box-sizing: border-box; float:left;">选择商品</label>
          <div class="" style="width:500px;display: inline-block;">
            <el-form ref="searchData" :model="searchData" inline>
              <el-form-item style="margin-bottom: 0;">
                  <Select v-model="searchData.tag_id" filterable>
                      <Option v-for="item in tags" :value="item.id" :key="item.id">{{ item.title }}</Option>
                  </Select>
              </el-form-item>
              <el-form-item style="margin-bottom: 0;">
                <Input type="text" v-model="searchData.name"></Input>
              </el-form-item>
              <el-form-item style="margin-bottom: 0;margin-left:0;">
                <Button type="primary" @click="search">
                  <i type="search"></i> 搜索
                </Button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="">
          <Table :columns="columns" :data="good_list" @on-select="selectItem" @on-select-cancel="selectItemCancel" @on-selection-change="selectAll" @on-sort-change="sortableList"></Table>
        </div>
        <div class="" slot="footer"  style="overflow: hidden;">
          <Page size="small" style="float: left" :current="page.current" :total="page.total" :page-size="page.pagesize" show-elevator @on-change="pageChange" show-total></Page>
          <Button class="pull-right" type="primary" @click="confirm">确定</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import {getGoodsList} from '../../../service/common/common'
  // import {getGoodsGroups} from '../../../service/goods/goods'

  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {
        goods: []
      }
    },
    computed: {

    },
    components: {
      draggable
    },
    data () {
      return {
        type: 'sliderGoods',
        modalShow: false,
        searchData: {
          pagesize: 5,
          page: 1,
          name: '',
          tag_id: 0,
          csale: 'desc',
          price: 'desc',
          shelve_at: 'desc'
        },
        good_list: [],
        columns: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: ' ',
            render: (h, params) => {
              return h('img', {
                style: {
                  width: '60px',
                  height: '60px',
                  'marginTop': '10px'
                },
                attrs: {
                  src: this.$store.state.imgBaseUrl + params.row.img
                }
              });
            }
          }, {
            title: '商品',
            key: 'title'
          }, {
            title: '价格',
            key: 'price',
            sortable: true,
            render: (h, params) => {
              return h('div', {}, '￥' + params.row.price);
            }
          }, {
            title: '销量',
            key: 'csale',
            sortable: true
          }
        ],
        selectImg: [], // modal自选选择的商品
        confirmSelectImg: [], // 确认的自选选择的商品
        page: {
          total: 0,
          current: 1,
          pagesize: 5
        },
        tempData: []
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },

      addGoods () {
        this.searchData = {
          pagesize: 5,
          page: 1,
          name: '',
          tag_id: 0,
          csale: 'desc',
          price: 'desc',
          shelve_at: 'desc'
        };
        this.page.current = 1;
        this.modalShow = true;
        this.getGoodsGroups();
        this.getGoodses();
      },

      getGoodses (db) {
        let _this = this;
        if (db == 'search') {
          _this.searchData.page = 1;
        }
        getGoodsList(_this.searchData, (data) => {
          if (data.errcode == 0) {
            _this.good_list = data.data;
            _this.page.total = data.search_count;
            this.good_list.forEach((good, index) => {
              this.content.goods.forEach((selectItem) => {
                if (good.id == selectItem.id) {
                  this.$set(this.good_list[index], '_checked', true);
                }
              });
            });
          }
        });
      },

      getGoodsGroups () {
        let _this = this;
        let params = {
          page: 1,
          pagesize: 10000,
          title: '',
          column: 'created_at',
          direct: 'desc'
        };
        getGoodsGroups(params, (res) => {
          if (res.errcode == 0) {
            _this.tags = res.data;
            _this.tags.unshift({
              id: 0,
              title: '所有分组'
            });
            _this.searchData.tag_id = _this.tags[0].id;
          }
        })
      },

      search () {
        this.getGoodses('search');
      },

      selectAll (data) {
        if (this.content.goods.length >= 20) {
          this.$Message.info('最多一共可以选择20个商品！');
        } else {
          if (data.length > 0) {
            data.forEach((item, index) => {
              var isNew = true;
              this.content.goods.forEach((subitem, subindex) => {
                if (item.id == subitem.id) {
                  isNew = false;
                }
              })
              if (isNew) {
                this.content.goods.push(item)
              }
            });
            this.content.goods.splice(19, this.content.goods.length - 20);
          } else {
            this.good_list.forEach((item, index) => {
              this.content.goods.forEach((subitem, subindex) => {
                if (item.id == subitem.id) {
                  this.content.goods.splice(subindex, 1)
                }
              })
            });
          }
        }
      },

      selectItem (data, row) {
        if (this.content.goods.length == 20) {
          this.$Message.info('最多一共可以选择20个商品！');
        } else {
          this.content.goods.push(row);
        }
      },

      selectItemCancel (data, row) {
        this.content.goods.forEach((subitem, subindex) => {
          if (row.id == subitem.id) {
            this.content.goods.splice(subindex, 1)
          }
        })
      },

      confirm () {
        this.searchData = {
          pagesize: 5,
          page: 1,
          name: '',
          tag_id: 0,
          csale: 'desc',
          price: 'desc',
          shelve_at: 'desc'
        };
        this.page.current = 1;
        this.modalShow = false;
      },

      pageChange (index) {
        let _this = this;
        this.page.current = index;
        _this.searchData.page = index;
        _this.getGoodses();
      },

      remove (index) {
        this.content.goods.splice(index, 1);
      },

      checkBoxChange (value) {
        var checks = [];
        for (var key in this.content.show_ele) {
          if (this.content.show_ele[key]) {
            checks.push(key);
          }
        }
        if (checks.length == 0) {
          this.$Message.info('显示元素最少勾选1个！');
          setTimeout(() => {
            this.content.show_ele[this.tempData[0]] = true
          }, 100)
        } else {
          this.tempData = checks;
        }
      }
    }
  }
</script>

<style scoped>
  .edit-shop-content  .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content  .ivu-form-item:after{
    display: none;
  }
  .form-button{padding-left:10px;}
  .form-span{    height: 32px;line-height: 32px;}
  .dragArea{
    margin: 5px 0 0 12px;
  }
  .dragArea > .goods-wrap {
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px 10px 0px 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
  }
  .dragArea > .goods-wrap:hover {
    cursor: move;
    border: 1px #cd6e00 dashed;
  }
  .dragArea > .goods-wrap > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .dragArea > .goods-wrap > i.del-goods {
    display: none;
    position: absolute;
    font-size: 18px;
    top: -4px;
    right: -4px;
    cursor: pointer;
    color: #a3a3a3;
  }
  .dragArea > .goods-wrap > i.del-goods:hover {
    color: #333;
  }
  .dragArea > .goods-wrap:hover > i.del-goods {
    display: block;
  }
  .ivu-row{height:40px;}
</style>
