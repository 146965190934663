<template>
  <div class="module-book" v-bind:style="{backgroundColor:content.bgcolor}">
    <div class="title" :style="{fontSize:content.titleSize + 'px',color:content.titleColor}">{{content.title}}</div>
    <el-form size="small">
      <el-form-item label="">
        <el-input type="text" readonly="readonly" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input type="text" readonly="readonly" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input type="textarea" rows="3" readonly="readonly" placeholder="请填写需求"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" style="width: 100%;" :style="{backgroundColor:content.btnBgcolor,color:content.btncolor}">{{content.btnTxt}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
    },
    data () {
      return {
        type: 'book'
      }
    }
  }
</script>

<style scoped>
.module-book {
  padding: 10px;
  box-sizing: border-box;
}
.module-book .title {
  text-align: center;
  padding: 10px 0 20px 0;
  font-weight: bold;
}
</style>
