<template>
  <div class="edit-drawernav">
    <div class="edit-shop-header">
      <h3>抽屉式导航设置</h3>
      <div class="edit-shop-delete">
        <Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-drawernav">
        <el-form :model="content" :label-width="110">
          <el-form-item label="显示方式：">
            <RadioGroup v-model="content.show" @on-change="showChange">
                <Radio :label="1">图标与文字</Radio>
                <Radio :label="2">只显示图标</Radio>
                <Radio :label="3">只显示文字</Radio>
            </RadioGroup>
          </el-form-item>
          <el-form-item label="排列方式：">
            <RadioGroup v-model="content.array" @on-change="arrayChange">
                <Radio :label="1" :disabled="content.show == 2 || content.show == 3">左图右文</Radio>
                <Radio :label="2" :disabled="content.show == 2 || content.show == 3">上图下文</Radio>
            </RadioGroup>
          </el-form-item>
          <el-form-item label="对齐方式：">
            <RadioGroup v-model="content.align">
                <Radio :label="1">居左</Radio>
                <Radio :label="2">居中</Radio>
                <!-- <Radio :label="3">居右</Radio> -->
            </RadioGroup>
          </el-form-item>
          <Row style="height: 52px;">
              <Col span="12" style="height: 34px;width: 160px;">
                <el-form-item label="分割线：">
                  <i-switch v-model="content.line"></i-switch>
                </el-form-item>
              </Col>
              <Col span="12" style="height: 34px;width: 160px;">
                <el-form-item label="分割线颜色：">
                  <colorPicker v-model="content.linecolor"></colorPicker>
                </el-form-item>
              </Col>
          </Row>
          <el-form-item label="上边距：">
            <InputNumber v-model="content.margin" class="edit-input" min="0" max="1000" ></InputNumber>
            <p class="edit-unit">px</p>
          </el-form-item>
          <el-form-item label="图标大小：">
            <RadioGroup v-model="content.size">
                <Radio :label="1" :disabled="(content.show == 1 || content.show == 3) && content.array == 1">大图标</Radio>
                <Radio :label="2" :disabled="(content.show == 1 || content.show == 3) && content.array == 1">小图标</Radio>
            </RadioGroup>
          </el-form-item>
          <el-form-item label="图标样式：">
            <Row class="images-style">
              <Col span="4" style="width: 51px;">
                <span class="img yuan" :class="{select: content.style==1}" @click="chooseStyle(1)"></span>
                <span class="selecti" v-show="content.style==1"><i class="fa fa-check" aria-hidden="true"></i></span>
                <p>圆形</p>
              </Col>
              <Col span="4" style="width: 51px;">
                <span class="img fang" :class="{select: content.style==2}" @click="chooseStyle(2)"></span>
                <span class="selecti" v-show="content.style==2"><i class="fa fa-check" aria-hidden="true"></i></span>
                <p>方形</p>
              </Col>
            </Row>
          </el-form-item>
          <Row style="height: 52px;">
              <Col span="12" style="height: 34px;width: 160px;">
                <el-form-item label="背景颜色：">
                  <colorPicker v-model="content.bgcolor"></colorPicker>
                </el-form-item>
              </Col>
              <Col span="12" style="height: 34px;width: 160px;">
                <el-form-item label="透明度：">
                  <InputNumber v-model="content.bgopacity" class="edit-input" min="0" max="100" ></InputNumber>
                </el-form-item>
              </Col>
          </Row>
          <el-form-item label="背景图片：">
            <div class="choice-image-box" style="height: 110px; overflow: hidden">
              <single-select-img style="width: 100px; float: left;" :img="content.bgimg" :width="50" :height="50" :maxSize="1024" @updateImgList="(val) => setBgimg(val.url, index)"></single-select-img>
              <Button style="position: absolute;top: 0;left: 108px;" size="small" @click="deleteBgimg()">删除图片</Button>
              <p style="float: left; margin-top: 70px; color: #777; margin-left: 10px;">建议尺寸：375 * 200 像素</p>
            </div>
          </el-form-item>
          <el-form-item label="开关图标：">
            <div class="choice-image-box" style="height: 110px; overflow: hidden">
              <single-select-img style="width: 100px; float: left;" :img="content.icon" :width="50" :height="50" :maxSize="1024" @updateImgList="(val) => setIcon(val.url, index)"></single-select-img>
              <Button style="position: absolute;top: 0;left: 108px;" size="small" @click="deleteIcon()">恢复默认图片</Button>
              <p style="float: left; margin-top: 70px; color: #777; margin-left: 10px;">建议尺寸：50 * 50 像素</p>
            </div>
          </el-form-item>
        </el-form>
        <div class="edit-item" v-for="(item, index) in content.data" :key="index">
          <el-form :model="item" :label-width="90">
            <Row style="height: 52px;">
              <Col span="16" style="height: 34px;width: 330px;">
                <el-form-item label="菜单文字：">
                  <Input v-model="item.nav_name" class="edit-input" maxlength="5"></Input>
                  <p class="edit-tips">最多可输入5个字</p>
                </el-form-item>
              </Col>
              <Col span="8" style="height: 34px;width: 100px;">
                <el-form-item label="文字颜色：">
                    <colorPicker v-model="item.nav_color"></colorPicker>
                </el-form-item>
              </Col>
              <Poptip v-if="index > 1" style="position: absolute; right: 2px; top: -4px;"
                      confirm
                      transfer="true"
                      placement="left"
                      title="确认删除吗？"
                      @on-ok="deleteItem(index)"
                      @on-cancel="cancel">
                <a href="javascript:void(0);">
                  <i class="fa fa-trash" aria-hidden="true"></i> 删除
                </a>
              </Poptip>
            </Row>
            <el-form-item label="菜单图标：">
              <div class="choice-image-box" style="height: 110px; overflow: hidden">
                <single-select-img style="width: 100px; float: left;" :img="item.nav_icon" :width="50" :height="50" :maxSize="1024" @updateImgList="(val) => setImgUrl(val.url, index, 'nav_hover_icon')"></single-select-img>
                <p style="float: left; margin-top: 70px; color: #777; margin-left: 10px;">建议尺寸：50*50像素，大小不超过1M</p>
              </div>
            </el-form-item>
            <el-form-item label="菜单链接：">
              <div style="width: 400px;position: relative;">
                <Input v-model="item.nav_link.name" icon="arrow-down-b" placeholder="选择链接"></Input>
                <select-link class="select-links-opacity" :data="item.nav_link" :index="index"></select-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="overflow:hidden; margin-top: 10px;">
          <Button v-if="content.data.length < 20" type="primary" style="float: left; margin-left: 10px;" @click="addItem()">添加菜单</Button>
          <Button v-else disabled style="float: left; margin-left: 10px;">添加菜单</Button>
          <p class="edit-tips" style="margin-top: 7px;">最多一共可以有20个菜单</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import singleSelectImg from '../../components/FileUpload'
  import selectLink from './select.link.vue'
  export default {
    components: {
      singleSelectImg, selectLink
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'sideFixed'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    created () {

    },
    methods: {
      deleteItem (index) {
        this.content.data.splice(index, 1);
      },
      addItem (index) {
        this.content.data.push({
          nav_name: '菜单', // 导航文字
          nav_color: '#000000', // 菜单文字颜色
          nav_icon: '', // 导航图标地址
          nav_link: { // 导航链接
            type: '',
            name: '',
            id: ''
          }
        });
      },
      setImgUrl (url, index) {
        this.content.data[index].nav_icon = url;
      },
      contentChange () {
        this.$emit('message', this.type, this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      selectChange (num) {
        if (this.content.data.length < num) {
          for (var i = this.content.data.length; i < num; i++) {
            this.content.data.push({
              nav_name: '菜单' + (i + 1),
              nav_color: '#000000',
              nav_icon: '',
              nav_link: {
                type: '',
                name: '',
                id: ''
              }
            })
          }
        }
      },
      chooseStyle (style) {
        this.content.style = style;
      },
      setBgimg (url, index) {
        this.content.bgimg = url;
      },
      showChange (value) {
        if (value == 1) {
          this.content.array = 1;
          this.content.size = 2;
        } else if (value == 2) {
          this.content.array = 2;
        } else if (value == 3) {
          this.content.array = 1;
          this.content.size = 2;
        }
      },
      arrayChange (value) {
        if (value == 1) {
          this.content.size = 2;
          this.content.show = this.content.show == 2 ? 1 : this.content.show;
        } else if (value == 2) {
          this.content.size = 1;
          this.content.show = this.content.show == 3 ? 1 : this.content.show;
        }
      },
      deleteBgimg () {
        this.content.bgimg = '';
      },
      setIcon (url, index) {
        this.content.icon = url;
      },
      deleteIcon () {
        this.content.icon = '2018/08/27/Fjw69F30XjUi4lrGtiTzJ6MEgF97.png';
      }
    }
  }
</script>

<style scoped>
  .edit-shop-drawernav {
    background-color: #fff;
  }
  .edit-shop-drawernav {
    background-color: #fff;
  }
  .edit-shop-drawernav .edit-input{
    width: 140px;float: left;
  }
  .edit-shop-drawernav .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-drawernav .edit-tips{
    float:left;margin-left: 20px; margin-top: 1px;color: #999;
  }
  .edit-item{
    background-color: #f8f8f9;
    padding: 10px;
    margin: 15px 10px;
  }
  .edit-shop-drawernav .ivu-form-item{
    min-height: 34px;
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-row:before, .edit-shop-content .ivu-row:after, .edit-shop-content .ivu-form-item:before, .edit-shop-content .ivu-form-item:after{
    display: none;
  }

  .images-style .ivu-col{
    position: relative;
    margin-right: 37px;
    margin-bottom: 5px;
    display: block;
  }
  .images-style .ivu-col:last-child{
    margin-right: 0;
  }
  .images-style .ivu-col .img{

  }
  .images-style .ivu-col .yuan{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
    border-radius: 50%;
  }
  .images-style .ivu-col .fang{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
  }
  .images-style .ivu-col p{
    text-align: center;
    color: #999;
  }
  .images-style .ivu-col .img:hover{
    cursor: pointer;
  }
  .images-style .img.select{
    border: 2px solid #57a3f3;
  }
  .images-style .selecti{
    height: 23px;
    width: 22px;
    position: absolute;
    top: 25px;
    right: 3px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
  }
  .images-style .selecti i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
  .images-data .line{
    height: 80px;
    line-height: 80px;
  }
  .images-style{
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
    height: 80px;
  }
  .images-style:after, .images-style:before{
    display: block;
  }

  .edit-shop-drawernav .edit-input{
    width: 100px;
  }
</style>
