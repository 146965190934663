<template>
  <div class="module-groupGoods">
    <div class="mod-shop-groupGoods">
      <div v-if="content.tag_style == 1">
         <section class="good_tags">
            <span class="u-goodsIcon"></span>
           <span class="tag_text active">全部分类</span>
           <span class="tag_text ">全部分类</span>
         </section>
      </div>
      <section class="good_tags_left" v-else>
          <div :class="[index==0 ? 'item active' : 'item']" v-for="(item,index) in content.tag" :key="index">
            <span v-if="index==0" :style="{'color': content.font_bgcolor, 'borderColor': content.font_bgcolor}">{{item.title}}</span>
            <span v-else>{{item.title}}</span>
          </div>
      </section>
       <div class="view-goods" :class="{'view-goods-1': content.tag_style == 2}">
           <!--双列-->
        <section class="goods-type-1" v-if="content.style == 1">
           <ul class="m-double overflow">
              <li :style="{width:(width-9)/2+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
              <li :style="{width:(width-9)/2+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称 </p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
           </ul>
        </section>
        <!--三列-->
        <section class="goods-type-4" v-if="content.style == 4">
           <ul class="m-double overflow">
              <li :style="{width:(width-9)/3+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
              <li :style="{width:(width-9)/3+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称</p>
                      <p class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
              <li :style="{width:(width-9)/3+'px'}">
                  <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                  <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                      <p class="name" v-if="content.show_ele.name">商品名称 </p>
                      <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                      <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                     <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                  </div>
              </li>
           </ul>
        </section>
         <!--小图单列-->
        <section class="goods-type-2" v-else-if="content.style == 2">
              <ul class="smallClm">
                  <li>
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">
                          <p class="name"  v-if="content.show_ele.name">商品名称</p>
                          <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                          <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
                  <li>
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des" v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart" >
                          <p class="name" v-if="content.show_ele.name"> 商品名称</p>
                          <p class="price" v-if="content.show_ele.price"><i>￥</i>88.88</p>
                          <p class="sales" v-if="content.show_ele.sales">销量：666件</p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>
                  </li>
              </ul>
        </section>
        <!--大图单列-->
        <section class="goods-type-3" v-else-if="content.style == 3">
              <ul class="bigClm">
                  <li :style="{width:(width-8)+'px'}">
                     <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                       <div class="u-des"   v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">

                          <p class="name " v-if="content.show_ele.name"> 商品名称</p>
                          <p>
                              <span class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</span>
                              <span class="sales" v-if="content.show_ele.sales">销量：666件</span>
                          </p>
                          <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>

                  </li>
                    <li :style="{width:(width-8)+'px'}">
                      <img src="../../assets/images/icon-bg8.jpg" resize="cover" class="u-img"/>
                      <div class="u-des"  v-if="content.show_ele.name || content.show_ele.price || content.show_ele.sales || content.show_ele.cart">

                          <p class="name " v-if="content.show_ele.name">商品名称</p>
                          <p>
                              <span class="price"  v-if="content.show_ele.price"><i>￥</i>88.88</span>
                              <span class="sales" v-if="content.show_ele.sales">销量：666件</span>
                          </p>
                         <img v-if="content.show_ele.cart" class="shopCart" src="https://dshop-static.tsphp.com/applet_mch/images/icon_54.png"  resize="cover"/>
                      </div>

                  </li>

              </ul>
        </section>
       </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      width () {
        if (this.content.tag_style == 1) {
          return 298;
        } else {
          return 228;
        }
      }
    },
    data () {
      return {
        'type': 'groupGoods'
      }
    }
  }
</script>

<style scoped>
.view-goods{display: -webkit-box;}
.view-goods-1{
  width: 228px;
  float: right;
}
.mod-shop-groupGoods{background-color: #f0f3f6; overflow: hidden;}
.good_tags{flex-direction: row;align-items: center;padding: 8px 10px; background-color: #fff;margin-bottom: 3px;}
.good_tags .tag_text{display:inline-block;font-size: 14px;color: #424141;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;width:80%;}
.good_tags_left{
  width: 70px;
  /* float: left; */
  background-color: #f8f8f9;
  position: absolute;
  height: 100%;
}
.good_tags_left div{
  margin: 2px 0;
  width: 70px;
  padding: 8px 0;
}
.good_tags_left div span{
  display: block;
  padding: 0 2px 0 4px;
  white-space: normal;
  text-overflow: ellipsis;
  border-left: 1px solid rgba(0,0,0,0);
  overflow: hidden;
  word-break: normal;
  width: 70px;
  height: 18px;
}
.good_tags_left div.active{
  background-color: #fff;
}
.good_tags_left div.active span{
  border-left: 1px solid #ff5500;
}

.good_tags i{margin-top: 2px;
float: right;
    font-size: 14px;
    color: #424141;}
.u-goodsIcon{background-size: 100%;
    vertical-align: 8%;width: 15px;height: 15px;margin-right: 5px;display: inline-block;top: -1px;background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo1OTMzMzgxNC1jYTU1LTQxNDQtOTk1ZS0wODU3N2EzZTI2OWEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUY4MjBDQ0Y5MjA4MTFFN0EwQkFCNEI1OTRDQTE5NjciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUY4MjBDQ0U5MjA4MTFFN0EwQkFCNEI1OTRDQTE5NjciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjE1NGE0Y2RhLWUwZDMtNGQ0OS04ZWQ2LTVhODc0OWUxNmQwNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1OTMzMzgxNC1jYTU1LTQxNDQtOTk1ZS0wODU3N2EzZTI2OWEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5VeZCMAAAA40lEQVR42mJMS0v7z0AYvAfixUBcCsS/oGJsQNwNxDFALETIABYG4oAgEOcB8XcgroCKdUHFiAJMSGxGPNgWqiYVSX0slLYloBfDInzgCJRGDiIhNDmifURTMGrR0LDoHZRtg0edNZR+hyRGkj4maI4HgcNA/B8HhiXhOUiGkKQPVDKUQTNWLLQEwFcE1SKJkaSPEVjWDb/EACqFJwLxWzxh/Q6qhg1JL0n6WIgshcktveH6mIgshcktveH6mIgshcktveH6Rsu6UYtItojc0hurRf9pUHr/J7VdR27pDQcAAQYASR1cvaP9G10AAAAASUVORK5CYII=)}
.good_tags_scroll{
  height: 38px;
  background-color: rgba(255, 255, 255, 1);
	box-shadow: inset 0px -1px 0px 0px rgba(216, 216, 216, 1);
  margin-bottom: 3px;
}
.good_tags_scroll .scroll{
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.good_tags_scroll .item{
  margin-right: 12px;
  text-align: center;
}
.good_tags_scroll span{
  font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 35.53073px;
	letter-spacing: 0px;
  color: #333333;
  display: inline-block;

}
.good_tags_scroll .active span{
  color: #ff5500;
  border-bottom: 2px solid #ff5500;
}
.good_tags_scroll .item:first-child{
  margin-left: 10px;
}
.good_tags_scroll .item:last-child{
  margin-right: 0px;
  padding-right: 10px;
}
.goods-type-1{
    padding:3px;
}
.mbt5{margin-bottom: 5px;}
.mbt8{margin-bottom: 8px;}
.goods-type-1 .m-double li{float: left;width: 49%;background-color: #fff;margin-bottom: 3px;}
.goods-type-1 .m-double li:nth-child(odd){
     margin-right:3px;
 }
.goods-type-1 .m-double li .u-img{width: 100%;}
.goods-type-1 .m-double li .u-des{position: relative;padding: 8px 9px 9px;min-height: 32px;}

.goods-type-1 .u-des .name{font-size: 13px;line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: #424141;
    font-size: 13px;
    margin-bottom: 5px;
}
.goods-type-4 .m-double li{float: left;width: 32%;background-color: #fff;margin-bottom: 3px;margin-right: 3px;}
.goods-type-4 .m-double li:nth-child(3n){margin-right:0;}
.goods-type-4 .m-double li .u-img{width: 100%;}
.goods-type-4 .m-double li .u-des{position: relative;padding: 8px 9px 9px;min-height: 32px;}
.goods-type-4 .u-des .name{font-size: 13px;line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: #424141;
    font-size: 13px;
    margin-bottom: 5px;
}
.u-des .price{font-size: 16px;color: #ff5500;}
.u-des .price i{font-size: 13px;color: #ff5500;    font-style: inherit;}
.u-des .sales{font-size: 12px;color: #8e8e8e;line-height: 24px;}
.shopCart{position: absolute;right: 9px;bottom:9px;width: 17px;height: 17px; }
.u-salesPmIcon{width:26px;height: 13px; display: inline-block;margin-right: 3px;top:2px;}
.goods-type-2{width:100%;}
.goods-type-2 .smallClm{
     background-color: #fff;
     padding: 10px 0px 10px 9px;
}
.goods-type-2 .smallClm li{
    position: relative;
    padding: 10px 9px 10px 0px;
   border-bottom:0.5px solid #eaeaea;
   display: inline-block;
    width: 100%;
}
.goods-type-2 .smallClm li:first-child{
    padding-top:0px;
}
.goods-type-2 .smallClm li:last-child{
     padding-bottom: 0px;
     border-bottom:0;
 }
.goods-type-2 .smallClm li .u-img{width:80px;height: 80px;float: left;}
.goods-type-2 .smallClm li .u-des{margin-left: 90px;min-height: 80px;}
.goods-type-2 .u-des .name{font-size: 13px;line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: #424141;
    height: 35px;
    font-size: 13px;
     margin-bottom: 5px;
}
.goods-type-3 {padding: 3px 0;}
.goods-type-3 .bigClm{
    padding: 0 4px;
}
.goods-type-3 .bigClm li{
    border-radius:5px;
    background-color: #fff;
    margin-bottom: 10px;
}
.goods-type-3 .bigClm li:last-child{
    margin-bottom: 0;
}
.goods-type-3 .bigClm li .u-img{
    width:100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.goods-type-3 .bigClm li .u-des{padding:10px;position: relative;min-height: 32px;}
.goods-type-3 .bigClm li .name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #424141;
    font-size: 13px;
    margin-bottom: 5px;
}
.goods-type-3 .bigClm li .u-des div{flex-direction: row;}
.goods-type-3 .bigClm li .u-des .price{margin-right: 10px;}
</style>
