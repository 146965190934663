<template>
  <div class="edit-white">
    <div class="edit-shop-header">
      <h3>音频设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-audio">
        <el-form :model="content" label-width="100px">
          <el-form-item label="音频标题：">
            <el-input v-model="content.title" placeholder="请输入音频标题" maxlength="20"
                      size="small"></el-input>
            <p class="edit-tips">默认为音频，最大可输入20个字</p>
          </el-form-item>
          <el-form-item label="背景颜色：">
            <el-color-picker v-model="content.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="循环播放：">
            <RadioGroup v-model="content.isloop">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </el-form-item>
          <el-form-item label="暂停播放：">
            <RadioGroup v-model="content.ispause_after">
              <Radio :label="1">
                <span>暂停后再恢复播放时，从头开始播放</span>
              </Radio>
              <Radio :label="2">
                <span>暂停后再恢复播放时，从暂停位置开始播放</span>
              </Radio>
            </RadioGroup>
          </el-form-item>
          <el-form-item label="音频：">
            <Upload v-if="!btnStatus" :data="uploadData" style="height: 33px;"
                    :before-upload="beforeUploadFn"
                    :on-success="successFn"
                    :on-error="errorFn"
                    :on-exceeded-size="exceededSizeFn"
                    :on-format-error="formatErrorFn"
                    :on-remove="removeFn"
                    :format="['mp3']"
                    :max-size="10240">
              <Button v-if="content.url != ''" type="primary">重新上传</Button>
              <Button v-else type="primary">上传音频</Button>
            </Upload>
            <Button disabled v-else>
              <span>上传中...</span>
            </Button>
            <p class="edit-tips1">建议音频大小不超过1M，支持mp3格式</p>
            <div v-if="content.url != ''">
              <p><i class="ivu-icon ivu-icon-ios-musical-notes"></i> {{content.name}} <a @click="removeFn()">删除</a></p>
            </div>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  // import {updateFile} from '../../../service/uploadImg/uploadImg'
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'blank',
        'uploadData': {
          'token': '',
          'x:merchant_id': 0,
          'x:group_id': 0
        },
        'defaultFileList': [],
        'btnStatus': false
      }
    },
    created () {
      if (this.content.name != '' && this.content.url != '') {
        this.defaultFileList.push({
          name: this.content.name,
          url: this.content.url
        })
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      beforeUploadFn (file) {
        if (this.btnStatus) {
          this.$Message.error('音频上传中，请稍候！');
          return false;
        } else if (file.type != 'audio/mp3') {
          this.formatErrorFn(file);
          return false;
        } else if (file.size / 1024 > 1024) {
          this.exceededSizeFn(file);
          return false;
        } else {
          var formData = new FormData();
          this.btnStatus = true;
          formData.append('file[]', file);
          formData.append('group_id', this.uploadData['x:group_id']);
          updateFile(formData, (res) => {
            this.btnStatus = false;
            if (res.error_code == 0) {
              this.$Notice.success({
                title: '上传成功'
              });
              this.content.name = res.img.file_name;
              this.content.url = res.img.file_url;
            } else {
              this.$Notice.error({
                title: '上传失败'
              });
            }
          })
          return false;
        }
      },
      formatErrorFn (file) {
        this.$Notice.error({
          title: '音频文件格式不正确'
        });
      },
      exceededSizeFn (file) {
        this.$Notice.error({
          title: '音频文件太大，建议大小不超过1M'
        });
      },
      successFn (file) {
        this.$Message.success('音频上传成功！');
        this.content.name = file.file_name;
        this.content.url = file.url;
      },
      errorFn (file) {
        this.$Message.error('音频上传失败！');
      },
      removeFn (file) {
        this.content.name = '';
        this.content.url = '';
      }
    }
  }
</script>

<style scoped>
  .edit-shop-audio {
    background-color: #fff;
  }
  .edit-shop-audio .edit-input{
    width: 400px;
  }
  .edit-shop-audio .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-audio .edit-tips{
    color: #999;
  }
  .edit-shop-audio .edit-tips1{
    position: absolute;
    top: 0;
    left: 92px;
  }
  .edit-shop-audio .ivu-form-item{
    min-height: 34px;
  }
  .edit-shop-content .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-upload-list{
    background-color: #eee;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }
</style>
