<template>
  <div class="module-white">
    <div class="mod-shop-white" :style="{height: content.height + 'px', backgroundColor: render(content)}"></div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'white'
      }
    },
    methods: {
      render (item) {
        return this.hexToRgba(item.bgcolor, item.opacity)
      },
      hexToRgba (hex, opacity) {
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      }
    }
  }
</script>

<style scoped>
  .mod-shop-white {
    background-color: #ddd;
  }
</style>
