<template>
  <div class="edit-customGoods">
    <div class="edit-shop-header">
      <h3>自定义商品设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-customGoods">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="显示风格："  prop="style">
             <el-radio-group v-model="content.style" style="overflow: hidden;">
               <el-radio :label="1">双列</el-radio>
               <el-radio :label="2">小图单列</el-radio>
               <el-radio :label="3">大图单列</el-radio>
               <el-radio :label="4">三列</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示元素："  prop="show_ele">
              <el-checkbox v-model="content.show_ele.name" @on-change="checkBoxChange" >商品名称</el-checkbox>
              <el-checkbox v-model="content.show_ele.price" @on-change="checkBoxChange" >价格</el-checkbox>
<!--              <el-checkbox v-model="content.show_ele.sales" @on-change="checkBoxChange" >销量</el-checkbox>-->
              <el-checkbox v-model="content.show_ele.cart" @on-change="checkBoxChange" >购物车</el-checkbox>
          </el-form-item>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="6" class="form-button" style="width: 124px">
            <el-button size="small" v-if="content.goods.length < 20 " type="primary"  @click="addGoods">添加商品</el-button>
            <el-button size="small" v-else disabled>添加商品</el-button>
            </el-col>
            <el-col :span="18" class="form-span"><span style="color: #999;">最多一共可以选择20个商品,拖拽可改变商品顺序</span></el-col>
        </el-row>
        <div class="drag" style="overflow: auto;">
        <draggable  class="dragArea" v-model="content.goods">
          <div v-for="(item, index) in content.goods" class="goods-wrap" :key="index">
            <img :src="item.img">
            <i class="el-icon-delete del-goods" @click="remove(index)"></i>
          </div>
        </draggable>
       </div>
      </section>
    </div>
    <el-dialog
        title="选择商品"
        :visible.sync="modalShow"
        :destroy-on-close="true"
        width="900px">
      <goods-list :type="2" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import goodsList from "@/components/Goods/GoodsList";
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {
        goods: []
      }
    },
    computed: {

    },
    components: {
      draggable,
      goodsList
    },
    data () {
      return {
        type: 'customGoods',
        modalShow: false,
        good_list: [],
        selectImg: [], // modal自选选择的商品
        confirmSelectImg: [], // 确认的自选选择的商品
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      checkBoxChange(e) {
        console.log(e)
      },
      addGoods () {
        this.modalShow = true
      },
      remove (index) {
        this.content.goods.splice(index, 1);
      },
      chooseGoods(goods) {
        var _this = this
        goods.forEach(function (item) {
          _this.content.goods.push({id: item.id, title: item.title, img: item.img, price: item.price})
        })
        _this.modalShow = false
      }
    }
  }
</script>

<style scoped>
  .edit-shop-content .ivu-radio-group label{
    width: 94px;
    text-align: center;
    color: #999;
    margin-top: -28px;
  }
  .form-button{padding-left:10px;}
  .form-span{height: 32px;line-height: 32px;}
  .dragArea{margin:5px 0 0 12px;}
  .dragArea > .goods-wrap {
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px 10px 0px 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
  }
  .dragArea > .goods-wrap:hover {
    cursor: move;
    border: 1px #cd6e00 dashed;
  }
  .dragArea > .goods-wrap > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .dragArea > .goods-wrap > i.del-goods {
    display: none;
    position: absolute;
    font-size: 20px;
    top: -4px;
    right: -4px;
    cursor: pointer;
    color: red;
  }
  .dragArea > .goods-wrap:hover > i.del-goods {
    display: block;
  }
  .edit-shop-content .ivu-form-item .select i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
</style>
