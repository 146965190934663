<template>
  <div class="module-information">
    <div class="mod-shop-information">
      <div class="view-nav-infor">
        <section class="infor-type infor-type-1" :class="[content.position=='left'?'left':'',content.position=='center'?'center':'',content.position=='right'?'right':'']" v-if="content.num == 1"><!--居中：center;居右:right-->
          <div class="block" :style="{width:(width-92)+'px',backgroundColor:render(item)}" v-for="(item,index) in content.tags" :key="index">
              <img v-if="item.nav_icon" :src="item.nav_icon"/>
              <img v-else src="../../assets/images/icon-bg8.jpg"/>
              <span v-if="item.nav_name">{{item.nav_name}}</span>
          </div>
        </section>
        <section class="infor-type infor-type-2" :class="[content.position=='left'?'left':'',content.position=='center'?'center':'',content.position=='right'?'right':'']" v-if="content.num == 2"><!--居中：center;居右:right-->
          <div class="block" :style="{width:(width-109)/2+'px',height:(width-126)/2+'px',backgroundColor:render(item)}" v-for="(item,index) in content.tags" :key="index">
              <img :class="[item.nav_name?'has-title':'']" v-if="item.nav_icon" :src="item.nav_icon"/>
              <img :class="[item.nav_name?'has-title':'']" v-else src="../../assets/images/icon-bg8.jpg"/>
              <span v-if="item.nav_name">{{item.nav_name}}</span>
          </div>
        </section>
        <section class="infor-type infor-type-3" :class="[content.position=='left'?'left':'',content.position=='center'?'center':'',content.position=='right'?'right':'']" v-if="content.num == 3"><!--居左：left居中：center;居右:right-->
          <div class="block" :style="{width:(width-110)/3+'px',height:(width-100)/3+'px',backgroundColor:render(item)}" v-for="(item,index) in content.tags" :key="index">
              <img :class="[item.nav_name?'has-title':'']" v-if="item.nav_icon" :src="item.nav_icon"/>
              <img :class="[item.nav_name?'has-title':'']" v-else src="../../assets/images/icon-bg8.jpg"/>
              <span v-if="item.nav_name">{{item.nav_name}}</span>
          </div>
      </section>
      <section class="infor-type infor-type-4" v-if="content.num == 4">
          <div class="block" :style="{width:(width-23)/4+'px',height:(width-23)/4+'px',backgroundColor:render(item)}" v-for="(item,index) in content.tags" :key="index">
              <img :class="[item.nav_name?'has-title':'']" v-if="item.nav_icon" :src="item.nav_icon"/>
              <img :class="[item.nav_name?'has-title':'']" v-else src="../../assets/images/icon-bg8.jpg"/>
              <span v-if="item.nav_name">{{item.nav_name}}</span>
          </div>
      </section>
     </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      width () {
        return 297;
      }
    },
    data () {
      return {
        'type': 'information',
        tags: []
      }
    },
    methods: {
      render (item) {
        return this.hexToRgba(item.nav_bgcolor, item.nav_opacity)
      },
      hexToRgba (hex, opacity) {
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      }
    }
  }
</script>

<style scoped>
  .view-nav-infor {
    overflow: hidden;
  }

  .infor-type {
    display: block !important;
  }

  .infor-type-1 .block {
    display: flex;
    position: relative;
    margin-bottom: 1px;
    margin-left: 10px;
    height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }

  .infor-type-1 .block img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .infor-type-1 .block span {
    color: #333;
    font-size: 14px;
    margin-left: 10px;
  }

  .infor-type-1 .block:last-child {
    margin-bottom: 0;
  }

  .infor-type-1.center .block {
    margin-left: 54px;
  }

  .infor-type-1.right .block {
    margin-left: 99px;
  }

  .infor-type-2 {
    padding-left: 10px;
    display: inline-block;
  }

  .infor-type-2 .block {
    float: left;
    background-color: #fff;
    margin-bottom: 1px;
    text-align: center;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .infor-type-2 .block img {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
    border-radius: 5px;
  }

  .infor-type-2 .block img.has-title {
    margin-top: -35px;
  }

  .infor-type-2 .block:nth-child(odd) {
    margin-right: 1px;
  }

  .infor-type-2 .block span {
    color: #333;
    font-size: 14px;
    margin-top: 65px;
    display: inline-block;
  }

  .infor-type-2.center {
    padding-left: 54px
  }

  .infor-type-2.right {
    padding-left: 99px
  }

  .infor-type-2.left .block:nth-child(2n+0) {
    margin-right: 98px;
  }

  .infor-type-3 {
    padding-left: 10px;
    display: inline-block;
  }

  .infor-type-3.center {
    padding-left: 54px
  }

  .infor-type-3.right {
    padding-left: 99px
  }

  .infor-type-3 .block {
    float: left;
    background-color: #fff;
    margin-bottom: 1px;
    text-align: center;
    display: block;
    position: relative;
    margin-right: 1px;
    overflow: hidden;
  }

  .infor-type-3 .block:nth-child(3n+0) {
    margin-right: 0px;
  }

  .infor-type-3.left .block:nth-child(3n+0) {
    margin-right: 98px;
  }

  .infor-type-3 .block img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    border-radius: 5px;
  }

  .infor-type-3 .block span {
    color: #333;
    font-size: 12px;
    margin-top: 48px;
    display: inline-block;
  }

  .infor-type-3 .block img.has-title {
    margin-top: -26px;
  }

  .infor-type-4 {
    padding-left: 10px;
    display: inline-block;
  }

  .infor-type-4 .block {
    float: left;
    background-color: #fff;
    margin-bottom: 1px;
    text-align: center;
    display: block;
    position: relative;
    margin-right: 1px;
    overflow: hidden;
  }

  .infor-type-4 .block:nth-child(4n+0) {
    margin-right: 0px;
  }

  .infor-type-4 .block img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    border-radius: 5px;
  }

  .infor-type-4 .block span {
    color: #333;
    font-size: 12px;
    margin-top: 50px;
    display: inline-block;
  }

  .infor-type-4 .block img.has-title {
    margin-top: -26px;
  }
</style>
