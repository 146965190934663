<template>
  <div class="box">
    <div class="mini-design-content">
      <div class="u-design">
        <div class="tabs-box">
          <div class="ivu-tabs ivu-tabs-card">
            <div class="edit-page-header" style="text-align: center; background-color: #f5f5f5;">组件库</div>
            <div class="ivu-tabs-content ivu-tabs-content-animated">
              <div class="ivu-tabs-tabpane" :style="{height: ivuTabsTabpaneHeight + 'px'}">
                <div class="coms-list" :style="{height: comsListHeight + 'px'}">
                  <div class="coms-list-content">
                    <div class="coms-list-title">
                      <p>-- 常用 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <template v-for="(item, index) in defaultComs">
                        <div class="coms-item" :class="{'coms-flag': item.flag}" :key="index" :data-type="item.type" v-if="item.group == 1">
                          <i :class="item.icon"></i>
                          <h3>{{item.name}}</h3>
                        </div>
                      </template>
                    </draggable>

                    <div class="coms-list-title">
                      <p>-- 商品 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <template v-for="(item, index) in defaultComs">
                        <div class="coms-item" :class="{'coms-flag': item.flag}" :key="index" :data-type="item.type" v-if="item.group == 2">
                          <i class="fa" :class="item.icon"></i>
                          <h3>{{item.name}}</h3>
                        </div>
                      </template>
                    </draggable>

                    <div class="coms-list-title">
                      <p>-- 资讯 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <template v-for="(item, index) in defaultComs">
                        <div class="coms-item" :class="{'coms-flag': item.flag}" :key="index" :data-type="item.type" v-if="item.group == 3">
                          <i class="fa" :class="item.icon"></i>
                          <h3>{{item.name}}</h3>
                        </div>
                      </template>
                    </draggable>

                    <div class="coms-list-title">
                      <p>-- 营销 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <template v-for="(item, index) in defaultComs">
                        <div class="coms-item" :class="{'coms-flag': item.flag}" :key="index" :data-type="item.type" v-if="item.group == 4">
                          <i class="fa" :class="item.icon"></i>
                          <h3>{{item.name}}</h3>
                        </div>
                      </template>
                    </draggable>

                    <!--div class="coms-list-title">
                      <p>-- 其他 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <template v-for="(item, index) in defaultComs">
                        <div class="coms-item" :class="{'coms-flag': item.flag}" :key="index" :data-type="item.type" v-if="item.group == 5">
                          <i class="fa" :class="item.icon"></i>
                          <h3>{{item.name}}</h3>
                        </div>
                      </template>
                    </draggable-->

                    <!--input type="hidden" v-model="copycount" />
                    <div class="coms-list-title">
                      <p>-- 公众号关注组件 --</p>
                    </div>
                    <draggable v-model="defaultComs" :options="defaultComsOptions_2" @end="defaultComsDragEnd" @start="defaultComsDragStart">
                      <div class="coms-item" :class="{'coms-flag': item.flag}" v-for="(item, index) in defaultComs" :key="index" :data-type="item.type" v-if="item.group == 6">
                        <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        <h3>{{item.name}}</h3>
                        <div v-show="copycount > 0" class="coms-bg" title="此组件只能拖动一次哦"></div>
                      </div>
                    </draggable-->

                    <!--div class="coms-list-title">
                      <p>-- 自由面板子组件 --</p>
                    </div>
                    <draggable v-model="freePanelComs" :options="freePanelComsOptions" @end="freePanelComsDragEnd" @start="freePanelComsDragStart">
                      <div class="coms-item" :class="{'coms-flag': item.flag}" v-for="(item, index) in freePanelComs" :key="index" :data-type="item.type">
                        <i class="fa" :class="item.icon"></i>
                        <h3>{{item.name}}</h3>
                      </div>
                    </draggable-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="phone-box" :style="{height: phoneBoxHeight + 'px'}">
          <div class="phone-model">
            <div class="wp-config">
              <div class="wp-comp">
                <h1><span>{{pageInfo.title}}</span></h1>
              </div>
            </div>
            <div class="phone-screen" :style="{minHeight: phoneScreenHeight + 'px'}">
              <div class="design-preview" :style="{height: designPreviewHeight + 'px'}">
                <div class="design-content-bg" :style="pageBg"></div>
                <div class="design-content" :class="{'design-content-float': comsTabs == 1}" :style="{height: designContentHeight + 'px'}">
                  <draggable class="edit-coms" :style="{minHeight: editComsHeight + 'px'}" v-model="editTempPages" :options="editComsOptions" @start="editComsDragStart" @add="editComsDragAdd" @end="editComsDragEnd">
                    <div v-for="(subitem, subindex) in editTempPages" :key="subindex">
                      <div class="edit-coms-item" @click="selectItem(subitem, subindex)">
                        <div class="edit-coms-outline left-outline" v-if="comsTabs == 0" v-show="selectIndex == subindex"></div>
                        <div class="edit-coms-outline right-outline" v-if="comsTabs == 0" v-show="selectIndex == subindex"></div>
                        <div class="edit-coms-outline top-outline" v-if="comsTabs == 0" v-show="selectIndex == subindex"></div>
                        <div class="edit-coms-outline bottom-outline" v-if="comsTabs == 0" v-show="selectIndex == subindex"></div>
                        <div class="edit-coms-delete" v-if="comsTabs == 0" @click.stop="">
                          <i class="el-icon-delete" @click="deleteComs(subindex, subitem)"></i>
                        </div>
                        <Banner v-if="subitem.type == 'banner'" :content="subitem.content"></Banner>
                        <public-focus v-if="subitem.type == 'publicFocus'" :content="subitem.content"></public-focus>
                        <Search v-if="subitem.type == 'search'" :content="subitem.content"></Search>
                        <Book v-if="subitem.type == 'book'" :content="subitem.content"></Book>
                        <!--Coupon v-if="subitem.type == 'couponGroup'" :content="subitem.content"></Coupon-->
                        <group-goods v-if="subitem.type == 'groupGoods'" :content="subitem.content"></group-goods>
                        <custom-goods v-if="subitem.type == 'customGoods'" :content="subitem.content"></custom-goods>
                        <slider-goods v-if="subitem.type == 'sliderGoods'" :content="subitem.content"></slider-goods>
                        <Carousel v-if="subitem.type == 'carousel'" :content="subitem.content"></Carousel>
                        <Images v-if="subitem.type == 'images'" :content="subitem.content"></Images>
                        <middle-nav v-if="subitem.type == 'middleNav'" :content="subitem.content"></middle-nav>
                        <water-fall v-if="subitem.type == 'waterFall'" :content="subitem.content"></water-fall>
                        <Title v-if="subitem.type == 'title'" :content="subitem.content"></Title>
                        <Video v-if="subitem.type == 'video'" :content="subitem.content"></Video>
                        <Audio v-if="subitem.type == 'audio'" :content="subitem.content"></Audio>
                        <Map v-if="subitem.type == 'map'" :content="subitem.content"></Map>
                        <Phone v-if="subitem.type == 'phone'" :content="subitem.content"></Phone>
                        <rich-text v-if="subitem.type == 'richText'" :content="subitem.content"></rich-text>
                        <White v-if="subitem.type == 'white'" :content="subitem.content"></White>
                        <!--Seckill v-if="subitem.type == 'seckill'" :content="subitem.content"></Seckill-->
                        <Information v-if="subitem.type == 'information'" :content="subitem.content"></Information>
                        <group-article v-if="subitem.type == 'groupArticle'" :content="subitem.content"></group-article>
                        <custom-article v-if="subitem.type == 'customArticle'" :content="subitem.content"></custom-article>
                        <slider-article v-if="subitem.type == 'sliderArticle'" :content="subitem.content"></slider-article>
                        <!--fight-group v-if="subitem.type == 'fightGroup'" :content="subitem.content"></fight-group-->
                        <!--free-panel v-if="subitem.type == 'freePanel'" :content="subitem.content" @message="recieveMessage"  @delete="recieveDelete"></free-panel-->
                        <image-text v-if="subitem.type == 'imageText'" :content="subitem.content"></image-text>
                        <!--bargain v-if="subitem.type == 'bargain'" :content="subitem.content"></bargain-->
                        <!--live v-if="subitem.type == 'live'" :content="subitem.content"></live-->
                        <!--payk v-if="subitem.type == 'payk'" :content="subitem.content"></payk-->
                      </div>
                    </div>
                  </draggable>
                </div>
                <!--div class="design-content" v-else>
                  <p class="coms-tips"><i type="information-circled"></i> 暂未创建页面！</p>
                </div-->
              </div>
            </div>
          </div>
        </div>
        <!-- </Affix> -->
        <div class="edit-box-two" :style="{'max-height': phoneBoxHeight + 'px'}">
          <div class="edit-box">
            <div class="edit-page">
              <div class="edit-page-header" :class="{'open': !openPageInfoStatus}">
                <h3>页面设置</h3>
                <a href="javascript:void(0);" class="open-page-info" @click="openPageInfoStatus = !openPageInfoStatus">
                  <span v-if="openPageInfoStatus">关闭</span>
                  <span v-else>打开</span>
                  <i type="chevron-up" v-if="openPageInfoStatus"></i>
                  <i type="chevron-down" v-else></i>
                </a>
              </div>
              <div class="edit-page-content" v-show="openPageInfoStatus">
                <div class="edit-page-content-loading" :style="{minHeight: designPreviewHeight + 'px'}" v-show="editTempPagesLoading">
                  <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                </div>
                <section class="edit-shop-page">
                  <el-form label-width="100px" :model="pageInfo" :rules="rules" ref="ruleForm" size="small">
                    <el-form-item label="页面标题：" prop="title">
                      <el-input v-model="pageInfo.title" placeholder="标题最多10个字" maxlength="10" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="背景颜色：">
                      <el-color-picker v-model="pageInfo.bg_color"></el-color-picker>
                    </el-form-item>
                    <el-form-item label="背景图片：">
                      <div class="choice-image-box">
                        <SelectImgs :selectNum="1" :selectData="[pageInfo.bg_img]" :selectFunc="setBgImg"></SelectImgs>
                        <p>建议尺寸：750 * 1334 像素，大小不超过1M。</p>
                      </div>
                    </el-form-item>
                    <el-form-item label="选中菜单：">
                      <el-select v-model="pageInfo.select_nav" placeholder="请选择">
                        <el-option :key="1" label="第一个" :value="1"></el-option>
                        <el-option :key="2" label="第二个" :value="2"></el-option>
                        <el-option :key="3" label="第三个" :value="3"></el-option>
                        <el-option :key="4" label="第四个" :value="4"></el-option>
                        <el-option :key="5" label="第五个" :value="5"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="分享标题：">
                      <el-input v-model="pageInfo.share_title" placeholder="标题最多20个字" size="small" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="分享图片：">
                      <div class="choice-image-box">
                        <SelectImgs :selectNum="1" :selectData="[pageInfo.share_img]" :selectFunc="setShareImg"></SelectImgs>
                        <p>建议尺寸：500 * 500 像素，大小不超过1M。</p>
                      </div>
                    </el-form-item>
                  </el-form>
                </section>
              </div>
            </div>
          </div>

          <div class="edit-box" v-show="editBoxHide">
            <template v-for="(subitem, subindex) in editTempPages">
              <div v-if="selectIndex == subindex" :key="subindex">
                <edit-banner v-if="subitem.type == 'banner'" :content="subitem.content" :index="subindex" @message="recieveMessage"  @delete="recieveDelete"></edit-banner>
                <edit-public-focus v-if="subitem.type == 'publicFocus'" :content="subitem.content" :index="subindex" @message="recieveMessage"  @delete="recieveDelete"></edit-public-focus>
                <!--edit-coupon v-if="subitem.type == 'couponGroup'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-coupon-->
                <edit-white v-if="subitem.type == 'white'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-white>
                <edit-search v-if="subitem.type == 'search'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-search>
                <edit-book v-if="subitem.type == 'book'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-book>
                <edit-group-goods v-if="subitem.type == 'groupGoods'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-group-goods>
                <edit-custom-goods v-if="subitem.type == 'customGoods'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-custom-goods>
                <edit-slider-goods v-if="subitem.type == 'sliderGoods'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-slider-goods>
                <!--edit-seckill v-if="subitem.type == 'seckill'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-seckill-->
                <edit-images v-if="subitem.type == 'images'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-images>
                <edit-title v-if="subitem.type == 'title'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-title>
                <edit-video v-if="subitem.type == 'video'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-video>
                <edit-map v-if="subitem.type == 'map'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-map>
                <edit-audio v-if="subitem.type == 'audio'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-audio>
                <edit-phone v-if="subitem.type == 'phone'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-phone>
                <edit-rich-text v-if="subitem.type == 'richText'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-rich-text>
                <edit-carousel v-if="subitem.type == 'carousel'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-carousel>
                <edit-middle-nav v-if="subitem.type == 'middleNav'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-middle-nav>
                <edit-water-fall v-if="subitem.type == 'waterFall'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-water-fall>
                <edit-information v-if="subitem.type == 'information'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-information>
                <edit-group-article v-if="subitem.type == 'groupArticle'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-group-article>
                <edit-custom-article v-if="subitem.type == 'customArticle'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-custom-article>
                <edit-slider-article v-if="subitem.type == 'sliderArticle'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-slider-article>
                <!--edit-fight-group v-if="subitem.type == 'fightGroup'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-fight-group-->
                <!--edit-free-panel v-if="subitem.type == 'freePanel'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-free-panel-->
                <edit-image-text v-if="subitem.type == 'imageText'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-image-text>
                <!--edit-bargain v-if="subitem.type == 'bargain'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-bargain-->
                <!--edit-live v-if="subitem.type == 'live'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-live-->
                <!--edit-payk v-if="subitem.type == 'payk'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-payk-->
                <edit-lattice-nav v-if="subitem.type == 'latticeNav'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-lattice-nav>
                <edit-drawer-nav v-if="subitem.type == 'drawerNav'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-drawer-nav>
                <edit-side-fixed v-if="subitem.type == 'sideFixed'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-side-fixed>
                <edit-surround-nav v-if="subitem.type == 'surroundNav'" :content="subitem.content" :index="subindex"  @message="recieveMessage"  @delete="recieveDelete"></edit-surround-nav>
              </div>
            </template>
          </div>
        </div>
      </div>
  </div>
    <div class="saveBox">
      <el-button type="danger" size="small" @click="savePage">保存数据</el-button>
      <el-button size="small" @click="goback()">返回</el-button>
    </div>
  </div>
</template>
<script>
  import SelectImgs from '../../components/SelectImgs'
  import Banner from '../module/banner.module'
  import PublicFocus from '../module/publicFocus.module'
  // import Coupon from '../module/coupon.module'
  import Search from '../module/search.module'
  import Book from '../module/book.module'
  import GroupGoods from '../module/groupGoods.module'
  import CustomGoods from '../module/customGoods.module'
  import SliderGoods from '../module/sliderGoods.module'
  import Carousel from '../module/carousel.module'
  import Images from '../module/images.module'
  import MiddleNav from '../module/middleNav.module'
  import WaterFall from '../module/waterFall.module'
  import Title from '../module/title.module'
  import Video from '../module/video.module'
  import Audio from '../module/audio.module'
  import Map from '../module/map.module'
  import Phone from '../module/phone.module'
  import RichText from '../module/richText.module'
  import White from '../module/white.module'
  // import Seckill from '../module/seckill.module'
  import Information from '../module/information.module'
  import GroupArticle from '../module/groupArticle.module'
  import CustomArticle from '../module/customArticle.module'
  import SliderArticle from '../module/sliderArticle.module'
  // import FreePanel from '../module/freePanel.module'
  import ImageText from '../module/imageText.module'
  // import Live from '../module/live.module'
  import latticeNav from '../module/latticeNav.module'
  import drawerNav from '../module/drawerNav.module'
  import SideFixed from '../module/sideFixed.module'
  import surroundNav from '../module/surroundNav.module'

  import editBanner from '../module/banner.edit'
  import editPublicFocus from '../module/publicFocus.edit'
  // import editCoupon from '../module/coupon.edit'
  import editSearch from '../module/search.edit'
  import editBook from '../module/book.edit'
  import editGroupGoods from '../module/groupGoods.edit'
  import editCustomGoods from '../module/customGoods.edit'
  import editSliderGoods from '../module/sliderGoods.edit'
  import editCarousel from '../module/carousel.edit'
  import editImages from '../module/images.edit'
  import editMiddleNav from '../module/middleNav.edit'
  import editWaterFall from '../module/waterFall.edit'
  import editTitle from '../module/title.edit'
  import editVideo from '../module/video.edit'
  import editAudio from '../module/audio.edit'
  import editMap from '../module/map.edit'
  import editPhone from '../module/phone.edit'
  import editRichText from '../module/richText.edit'
  import editWhite from '../module/white.edit'
  // import editSeckill from '../module/seckill.edit'
  import editInformation from '../module/information.edit'
  import editGroupArticle from '../module/groupArticle.edit'
  import editCustomArticle from '../module/customArticle.edit'
  import editSliderArticle from '../module/sliderArticle.edit'
  // import editFreePanel from '../module/freePanel.edit'
  import editImageText from '../module/imageText.edit'
  // import editLive from '../module/live.edit'
  import editLatticeNav from '../module/latticeNav.edit'
  import editDrawerNav from '../module/drawerNav.edit'
  import editSideFixed from '../module/sideFixed.edit'
  import editSurroundNav from '../module/surroundNav.edit'
  import draggable from 'vuedraggable'
  import defaultComs from '../config/default.coms'
  // import freePanelComs from '../config/freepanel.coms'
  import floatComs from '../config/float.coms'
export default {
  components: {
    Banner, Search, Carousel, Images, MiddleNav, WaterFall, Title, Video, Audio, Map, Phone, Book,
    // Coupon, Seckill, editCoupon, editSeckill, singleSelectImg, goBack, SelectTpl, BottomNavSetting
    // FreePanel, editFreePanel, bargain, editBargain, payk, editPayk, Live, fightGroup, editFightGroup, editLive, multiUploadImg
    RichText, White, Information, GroupArticle, CustomArticle, SliderArticle,
    editBanner, editSearch, editBook, editCarousel, editImages, editMiddleNav, editWaterFall, editTitle, editVideo, editAudio,
    editMap, editPhone, editRichText, editWhite, editInformation, editGroupArticle, editCustomArticle, editSliderArticle, draggable,
    ImageText, editImageText,
    // latticeNav, drawerNav, SideFixed, , surroundNav
    editLatticeNav, editDrawerNav, editSideFixed, editSurroundNav, PublicFocus, editPublicFocus, SelectImgs,
    GroupGoods, CustomGoods, SliderGoods, editGroupGoods, editCustomGoods, editSliderGoods
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      ivuTabsTabpaneHeight: 523,
      phoneBoxHeight: 523,
      phoneScreenHeight: 458,
      designPreviewHeight: 458,
      designContentHeight: 458,
      editComsHeight: 458,
      comsListHeight: 523,
      editTempPages: [],
      selectIndex: '',
      editTempPagesLoading: false,
      modalFristPage:false,
      defaultComs: defaultComs,
      floatComs: floatComs,
      openPageInfoStatus: true,
      comsTabs: 0,
      interactive: 0,
      pageBgimgMax: 5,
      editBoxHide: true,
      saveStatus: false,
      pageBgimgMax_2: 1,
      pageTitle: '',
      pageTitle_2: '',
      pageBgcolor: '',
      pageBgimgArray: [],
      pageBgimgArray_2: [],
      pageBgtransparency: '',
      pageInfo: {
        id: 0,
        title: '',
        select_nav: '',
        bg_color: '',
        bg_img: '',
        share_title: '',
        share_img: ''
      },
      pages: [{}],
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      },
      editComsOptions: {
        group: {
          name: 'components',
          pull: true,
          put: true
        },
        sort: true,
        animation: 100
      },
      defaultComsOptions: {
        group: {
          name: 'components',
          pull: 'clone',
          put: false
        },
        disabled: false,
        sort: false,
        animation: 100,
        chosenClass: 'coms-item-chosen'
      },
      editFloatComsOptions: {
        group: {
          name: 'components',
          pull: true,
          put: true
        },
        sort: false,
        animation: 100
      },
      defaultComsOptions_2: {
        group: {
          name: 'components',
          pull: 'clone',
          put: false
        },
        disabled: false,
        sort: false,
        animation: 100,
        chosenClass: 'coms-item-chosen'
      },
      tabActive: 1
    };
  },
  created() {
    if(this.$route.query.id) {
      this.pageInfo.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
    var height = document.documentElement.clientHeight
    this.ivuTabsTabpaneHeight = height - 230
    this.phoneBoxHeight = height - 200
    this.phoneScreenHeight = height - 260 - 40
    this.designPreviewHeight = height - 600
    this.designContentHeight = height - 300
    this.editComsHeight = height - 200
    this.comsListHeight = height - 200
  },
  computed: {
    pageBg () {
      var BackGround = {};
      if (this.backgroundImage != '') {
        BackGround = {
          height: this.designPreviewHeight + 'px',
          backgroundImage: 'url(' + this.backgroundImage + ')',
          backgroundPosition: '0 0',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%' + ' ' + this.designPreviewHeight + 'px',
          backgroundColor: this.backgroundColor
        }
      } else {
        BackGround = {
          height: this.designPreviewHeight + 'px',
          backgroundColor: this.backgroundColor
        }
      }
      return BackGround;
    }
  },
  methods: {
    // 设置背景图片
    setBgImg (data) {
      this.pageInfo.bg_img = data.pic
    },
    // 设置分享图片
    setShareImg (data) {
      this.pageInfo.share_img = data.pic
    },
    // 获取详情
    getInfo () {
      var _this = this
      this.$api.shop.designInfo({id: this.pageInfo.id},function (res) {
        if(res.errcode == 0) {
          _this.pageInfo = res.data
          _this.editTempPages = res.data.component
        } else {
          this.$message.error(res.errmsg)
        }
      })
    },
    // 保存
    savePage () {
      var _this = this
      var param = this.pageInfo
      param.pages = this.editTempPages
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$api.shop.designEdit(param,function (res) {
            if(res.errcode == 0) {
              _this.$message.success(res.errmsg)
              _this.reBack()
            } else {
              _this.$message.error(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
    cancel () {},
    deleteComs (index, item) {
      var _this = this
      this.$confirm('确认删除吗？')
      .then(_ => {
        _this.saveStatus = true;
        _this.editTempPages.splice(index, 1);
        if (item.type === 'publicFocus') {
          _this.copycount = 0;
          item.listorder = this.copycount;
          _this.defaultComsOptions_2.disabled = false;
        }
      })
      .catch(_ => {})
    },
    recieveMessage (content) {
      this.saveStatus = true;
    },
    recieveDelete (index) {
      console.log('sssss')
      this.deleteComs(index);
    },
    defaultComsDragEnd (event) {
      // 保留的空函数
      this.copycount = 1;
      this.defaultComsOptions_2.disabled = true;
    },
    defaultComsDragStart (event) {
    },
    editComsDragStart () {},
    interactiveChange(value) {
      if (value == 0) {
        this.pageBgimgMax = 1;
        if (this.pageBgimgArray.length > 0) {
          this.pageBgimgArray = this.pageBgimgArray.slice(0, 1);
        }
      } else {
        this.pageBgimgMax = 5;
      }
      if (value == 0) {
        this.pageBgimgMax_2 = 1;
        if (this.pageBgimgArray_2.length > 0) {
          this.pageBgimgArray_2 = this.pageBgimgArray_2.slice(0, 1);
        }
      } else {
        this.pageBgimgMax_2 = 1;
      }
    },
    selectItem (subitem, subindex) {
      if (this.selectIndex != subindex) {
        this.selectIndex = 'none';
        this.selectType = 'none';
        setTimeout(() => {
          this.selectIndex = subindex;
          this.selectType = subitem.type;
          this.openPageInfoStatus = false;
        }, 150)
      }
    },
    addBgimg(val) {
      var tempBgimg = val;
      if (val.length > 0) {
        tempBgimg.forEach((item, index) => {
          this.pageBgimgArray.push(item)
        })
        if (this.pageBgimgArray.length > 5) {
          this.pageBgimgArray = this.pageBgimgArray.slice(0, this.pageBgimgMax);
        }
        this.pageBgimg = this.pageBgimgArray[0].url;
        this.pageInfoChange();
      }
    },
    deleteBgimg(val) {
      var tempBgimg = val;
      this.pageBgimgArray = [];
      if (val.length > 0) {
        tempBgimg.forEach((item, index) => {
          this.pageBgimgArray.push(item)
        })
        this.pageBgimg = tempBgimg[0].url;
      } else {
        this.pageBgimg = '';
      }
      this.pageInfoChange();
    },
    dragBgimg(val) {
      var tempBgimg = val;
      this.pageBgimg = tempBgimg[0].url;
      this.pageBgimgArray = [];
      tempBgimg.forEach((item, index) => {
        this.pageBgimgArray.push(item)
      })
      this.pageInfoChange();
    },
    addBgimg_2(val) {
      var tempBgimg = val;
      if (val.length > 0) {
        tempBgimg.forEach((item, index) => {
          this.pageBgimgArray_2.push(item)
        })
        if (this.pageBgimgArray_2.length > 1) {
          this.pageBgimgArray_2 = this.pageBgimgArray_2.slice(0, this.pageBgimgMax_2);
        }
        this.pageBgimg = this.pageBgimgArray_2[0].url;
      }
    },
    deleteBgimg_2(val) {
      var tempBgimg = val;
      this.pageBgimgArray_2 = [];
      if (val.length > 0) {
        tempBgimg.forEach((item, index) => {
          this.pageBgimgArray_2.push(item)
        })
        this.pageBgimg = tempBgimg[0].url;
      } else {
        this.pageBgimg = '';
      }
    },
    editComsDragAdd (event) {
      if (!this.filterFloatComs(event)) {
        if (this.pages.length > 0) {
          this.saveStatus = true;
          this.selectIndex = 'none';
          this.selectType = 'none';
          setTimeout(() => {
            this.selectIndex = event.newIndex;
            this.selectType = this.editTempPages[this.selectIndex].type;
            this.openPageInfoStatus = false;
            this.parseDefaultComs();
            this.parseFloatComs();
          }, 150)
        } else {
          this.$Message.info('暂未创建页面！');
          this.selectIndex = event.newIndex;
          this.selectType = this.editTempPages[this.selectIndex].type;
          this.editTempPages = []
        }
      } else {
        this.deleteComs(event.newIndex)
        this.$Message.info('悬浮组件每一种只能有1个！');
      }
    },
    filterFloatComs (event) {
      var status = false;
      var tempIndex = event.newIndex;
      var tempComsType = this.editTempPages[event.newIndex].type;
      // var types = ['drawerNav', 'sideFixed', 'latticeNav', 'surroundNav'];
      if (this.comsTabs == 1) {
        this.editTempPages.forEach((item, index) => {
          if (item.type == tempComsType && index != tempIndex) {
            status = true
          }
        })
      }
      return status
    },
    editComsDragEnd (event) {
      this.saveStatus = true;
      this.selectIndex = 'none';
      this.selectType = 'none';
      setTimeout(() => {
        this.selectIndex = event.newIndex;
        this.selectType = this.editTempPages[this.selectIndex].type;
        this.openPageInfoStatus = false;
        this.parseDefaultComs();
        this.parseFloatComs();
      }, 150)
    },
    parseDefaultComs () {
      this.defaultComs.forEach((item, index) => {
        this.defaultComs[index] = JSON.parse(JSON.stringify(item));
      });
    },
    parseFloatComs () {
      this.floatComs.forEach((item, index) => {
        this.floatComs[index] = JSON.parse(JSON.stringify(item));
      });
    },
    parseFreePanelComs () {
      this.freePanelComs.forEach((item, index) => {
        this.freePanelComs[index] = JSON.parse(JSON.stringify(item));
      });
    },
    dragBgimg_2(val) {
      var tempBgimg = val;
      this.pageBgimg = tempBgimg[0].url;
      this.pageBgimgArray_2 = [];
      tempBgimg.forEach((item, index) => {
        this.pageBgimgArray_2.push(item)
      })
    },
    goback() {
      this.$router.push({
        name: 'shop_design'
      })
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}
.box img {
  border-radius: 50%;
  float: left;
  margin-right: 20px;
}
</style>
