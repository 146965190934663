<template>
  <div class="edit-white">
    <div class="edit-shop-header">
      <h3>自定义资讯设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-customArticle">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="显示风格：" prop="style">
            <el-radio-group v-model="content.style">
              <el-radio :label="1">左小图</el-radio>
              <el-radio :label="2">右小图</el-radio>
              <!--el-radio :label="3">三张小图</el-radio-->
              <el-radio :label="4">大图</el-radio>
              <el-radio :label="6">双列</el-radio>
              <el-radio :label="7">三列</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示元素：">
            <el-checkbox v-model="content.show_ele.content">资讯摘要</el-checkbox>
            <el-checkbox v-model="content.show_ele.time">发布时间</el-checkbox>
            <el-checkbox v-model="content.show_ele.red">阅读量</el-checkbox>
          </el-form-item>
        </el-form>
        <div style="overflow:hidden; margin-top: 15px;">
          <el-button v-if="content.article.length < 20" type="primary" style="float: left; margin-left: 10px;" size="small" @click="addArticle()"><i type="plus-round"></i> 添加资讯</el-button>
          <Button v-else disabled style="float: left; margin-left: 10px;">添加资讯</Button>
          <p class="edit-tips" style="margin-top: 7px;">最多可添加20篇资讯，拖曳可调整资讯顺序</p>
        </div>
        <div class="drag" style="overflow: auto;">
          <draggable class="dragArea" v-model="content.article">
            <div v-for="(item, index) in content.article" class="goods-wrap" :key="index">
              <img :src="item.pic" />
              <i class="el-icon-delete del-goods" @click="remove(index)"></i>
            </div>
          </draggable>
        </div>
      </section>
    </div>
    <el-dialog :visible.sync="visibleMod" width="940px" title="选择资讯">
      <div>
        <news-mod :selectFunc="selectNews" :type="2"></news-mod>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import NewsMod from '../../components/NewsMod'
  export default {
    components: {
      draggable,
      NewsMod
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        modal2: false,
        visibleMod: false,
        type: 'customArticle',
        selectIds: [],
        selectId: '',
        searchParams: {
          group_ids: 0,
          title: '',
          c_time: '',
          u_time: '',
          read_num: ''
        },
        pageInfo: {
          currentPage: 1,
          limit: 5,
          totalItems: 0
        },
        data: [],
        selectAll: false
      }
    },
    created () {
      this.content.article.forEach((item, index) => {
        if (typeof item.img_json == 'string') {
          this.content.article[index].img_json = JSON.parse(item.img_json)
        }
      })
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      addArticle () {
        this.visibleMod = true;
      },
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      confirm () {
        this.searchParams.currentPage = 1;
        this.searchParams.title = '';
        this.modal2 = false;
      },
      remove (index) {
        this.content.article.splice(index, 1)
      },
      selectNews(data) {
        this.content.article = data
        this.visibleMod = false
      }
    }
  }
</script>

<style scoped>
  .edit-shop-customArticle {
    background-color: #fff;
  }
  .edit-shop-customArticle .edit-input{
    width: 100px;float: left;
  }
  .edit-shop-customArticle .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-customArticle .edit-tips{
    float:left;margin-left: 20px;margin-top: 1px;color: #999;
  }
  .edit-shop-customArticle .ivu-form-item{
    min-height: 34px;
  }
  .edit-shop-content .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }

  .dragArea{
    margin: 5px 0 0 12px;
  }
  .dragArea > .goods-wrap {
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px 10px 0px 0;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
  }
  .dragArea > .goods-wrap:hover {
    cursor: move;
    border: 1px #cd6e00 dashed;
  }
  .dragArea > .goods-wrap > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .dragArea > .goods-wrap > i.del-goods {
    display: none;
    position: absolute;
    font-size: 18px;
    top: -4px;
    right: -4px;
    cursor: pointer;
    color: #a3a3a3;
  }
  .dragArea > .goods-wrap > i.del-goods:hover {
    color: #333;
  }
  .dragArea > .goods-wrap:hover > i.del-goods {
    display: block;
  }

  .edit-shop-content .ivu-radio-group label{
    width: 94px;
    text-align: center;
    color: #999;
    margin-top: -28px;
  }
  .edit-shop-content .ivu-radio-group .ivu-radio{
    display: none;
  }
  .edit-shop-content .ivu-form-item img.style{
    width: 88px;
    display: block;
    margin-top: 4px;
  }
  .edit-shop-content .ivu-form-item img.current{
    width: 87px;
    border: 2px solid #57a3f3;
  }

  .edit-shop-content .ivu-form-item span.select{
    height: 22px;
    width: 22px;
    position: absolute;
    top: 86px;
    right: 6px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
    line-height: 1;
  }
  .edit-shop-content .ivu-form-item .select i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
</style>
