<template>
  <div class="edit-middlenav">
    <div class="edit-shop-header">
      <h3>菜单导航设置</h3>
      <div class="edit-shop-delete">
        <!--Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip-->
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-middlenav">
        <el-form :model="content" label-width="110px" size="small">
          <el-row style="height: 52px;">
              <el-col :span="12" style="height: 34px;width: 160px;">
                <el-form-item label="背景色：">
                  <el-color-picker v-model="content.bgcolor"></el-color-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="height: 34px;width: 160px;">
                <el-form-item label="文字颜色：">
                  <el-color-picker v-model="content.color"></el-color-picker>
                </el-form-item>
              </el-col>
          </el-row>
          <el-form-item label="图标样式：">
            <el-row class="images-style">
              <el-col :span="4" style="width: 51px;">
                <span class="img yuan" :class="{select: content.style==1}" @click="chooseStyle(1)"></span>
                <span class="selecti" v-show="content.style==1"><i class="el-icon-check"></i></span>
                <p>圆形</p>
              </el-col>
              <el-col :span="4" style="width: 51px;">
                <span class="img fang" :class="{select: content.style==2}" @click="chooseStyle(2)"></span>
                <span class="selecti" v-show="content.style==2"><i class="el-icon-check"></i></span>
                <p>方形</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="每行菜单数量：">
            <el-select v-model="content.num" placeholder="请选择" @on-change="selectChange">
              <el-option :value="3">3</el-option>
              <el-option :value="4">4</el-option>
              <el-option :value="5">5</el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="edit-item" v-for="(item, index) in content.data" :key="index">
          <el-form :model="item" label-width="90px" size="small" style="position: relative">
            <span style="position: absolute; right: 10px; top: -25px; cursor: pointer; z-index: 1">
              <el-popconfirm
                  title="您确认删除吗？"
                  @confirm="deleteItem(index)">
                <el-button type="text" slot="reference">
                  <i class="el-icon-delete" style="font-size: 18px;" />
                </el-button>
              </el-popconfirm>
            </span>
            <el-row style="height: 100px; margin-top: 10px; clear: both;">
              <el-col :span="6">
                <SelectImgs :selectNum="1" btnTitle="菜单图标" btnClass="w50" :selectData="[item.nav_icon]" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
              </el-col>
              <el-col :span="18">
                <div>
                  <el-row :gutter="10">
                    <el-col :span="4" style="line-height: 35px">
                      菜单文字
                    </el-col>
                    <el-col :span="16">
                      <el-input v-model="item.nav_name" placeholder="标题最多10个字" maxlength="5" size="small"></el-input>
                    </el-col>
                  </el-row>
                </div>
                <div class="mg-tp-20">
                  <el-row :gutter="10">
                    <el-col :span="4" style="line-height: 35px">
                      菜单链接
                    </el-col>
                    <el-col :span="16">
                      <select-link :setData="item.nav_link" :index="index" :setFunc="setLink">
                        <template slot="button">
                          <el-input :value="item.nav_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                        </template>
                      </select-link>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="overflow:hidden; margin-top: 10px;">
          <el-button type="primary" size="small" @click="addItem" class="fl" :disabled="content.data.length > 20">添加菜单</el-button>
          <p class="edit-tips" style="margin-top: 7px;">最多一共可以有20个菜单</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import SelectImgs from '../../components/SelectImgs'
  import selectLink from './select.link.vue'
  export default {
    components: {
      SelectImgs, selectLink
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'middleNav'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    created () {

    },
    methods: {
      deleteItem (index) {
        this.content.data.splice(index, 1);
      },
      addItem (index) {
        this.content.data.push({
          nav_name: '导航文字', // 导航文字
          nav_icon: '', // 导航图标地址
          nav_link: { // 导航链接
            type: '',
            name: '',
            id: ''
          }
        });
      },
      setImgUrl (item, index) {
        this.content.data[index].nav_icon = item.pic;
      },
      contentChange () {
        this.$emit('message', this.type, this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      setLink (item,idx) {
        this.content.data[idx].nav_link = item
      },
      selectChange (num) {
        if (this.content.data.length < num) {
          for (var i = this.content.data.length; i < num; i++) {
            this.content.data.push({
              nav_name: '菜单' + (i + 1),
              nav_icon: '',
              nav_link: {
                type: '',
                name: '',
                id: ''
              }
            })
          }
        }
      },
      chooseStyle (style) {
        this.content.style = style;
      }
    }
  }
</script>

<style scoped>
  .edit-shop-middlenav {
    background-color: #fff;
  }
  .edit-shop-middlenav {
    background-color: #fff;
  }
  .edit-shop-middlenav .edit-input{
    width: 140px;float: left;
  }
  .edit-shop-middlenav .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-middlenav .edit-tips{
    float:left;margin-left: 20px; margin-top: 1px;color: #999;
  }
  .edit-item{
    background-color: #f8f8f9;
    padding: 10px;
    margin: 15px 10px;
  }
  .edit-shop-middlenav .ivu-form-item{
    min-height: 34px;
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-row:before, .edit-shop-content .ivu-row:after, .edit-shop-content .ivu-form-item:before, .edit-shop-content .ivu-form-item:after{
    display: none;
  }

  .images-style .el-col{
    position: relative;
    margin-right: 37px;
    margin-bottom: 5px;
    display: block;
  }
  .images-style .el-col:last-child{
    margin-right: 0;
  }
  .images-style .yuan{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
    border-radius: 50%;
  }
  .images-style .fang{
    width: 48px;
    height: 48px;
    display: block;
    background: #ddd;
  }
  .images-style .ivu-col p{
    text-align: center;
    color: #999;
  }
  .images-style .ivu-col .img:hover{
    cursor: pointer;
  }
  .images-style .img {
    border: 2px solid #fff;
  }
  .images-style .img.select{
    border: 2px solid #57a3f3;
  }
  .images-style p {
    text-align: center;
  }
  .images-style .selecti{
    height: 23px;
    width: 22px;
    position: absolute;
    top: 29px;
    right: -1px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
  }
  .images-style .selecti i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
  .images-data .line{
    height: 80px;
    line-height: 80px;
  }
  .images-style{
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
    height: 80px;
  }
  .images-style:after, .images-style:before{
    display: block;
  }
</style>
