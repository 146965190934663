<template>
    <div class="edit-title">
        <div class="edit-shop-header">
            <h3>标题设置</h3>
            <div class="edit-shop-delete">
            </div>
        </div>
        <div class="edit-shop-content">
            <section class="edit-shop-title">
                <el-form :model="content" label-width="100px" size="small">
                    <el-form-item label="左侧标题：" prop="left_title">
                        <el-row>
                            <el-col :span="12">
                                <el-input v-model="content.left_title" placeholder="标题最多10个字" maxlength="10" size="small"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <p class="tit pd-lf-10">默认为“标题”，最多可输入20个字</p>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="文字颜色：" prop="bgcolor">
                        <el-color-picker v-model="content.color"></el-color-picker>
                    </el-form-item>
                    <el-form-item label="背景颜色：" prop="bgcolor">
                        <el-color-picker v-model="content.bgcolor"></el-color-picker>
                    </el-form-item>
                    <el-form-item label="左侧图标：" prop="left_icon" style="min-height: 100px;">
                        <el-row>
                            <el-col :span="5">
                                <SelectImgs :selectNum="1" :selectData="[content.left_icon?content.left_icon:'']" :selectFunc="setIcoImg"></SelectImgs>
                            </el-col>
                            <el-col :span="18">
                                <el-button type="primary" size="small" @click="deleteBgimg()">删除图片</el-button>
                                <div class="tit line">建议尺寸：50 * 50px，大小不超过1M</div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="右侧入口：">
                        <el-row>
                            <el-col :span="12">
                                <el-input v-model="content.right_title" placeholder="标题最多10个字" maxlength="5" size="small"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <span class="tit" style="margin-left: 7px;">最多可输入5个字</span>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="右侧文字颜色：" prop="bgcolor">
                        <el-color-picker v-model="content.morecolor"></el-color-picker>
                    </el-form-item>
                    <el-form-item label="链接：" prop="right_link">
                        <el-row>
                            <el-col :span="12">
                              <select-link :setData="content.right_link" :index="index" :setFunc="setLink">
                                <template slot="button">
                                  <el-input :value="content.right_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                                </template>
                              </select-link>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>

            </section>
        </div>
    </div>
</template>

<script>
    import selectLink from './select.link.vue'
    import SelectImgs from '../../components/SelectImgs'

    export default {
        components: {
            SelectImgs, selectLink
        },
        props: {
            index: {
                type: Number,
                default: 0
            },
            content: {}
        },
        computed: {},
        data() {
            return {
                'type': 'title'
            }
        },
        watch: {
            'content': {
                handler(newValue, oldValue) {
                    this.contentChange()
                },
                deep: true
            }
        },
        methods: {
            setIcoImg(item) {
                this.content.left_icon = item.pic
            },
            contentChange() {
                this.$emit('message', this.content);
            },
            deleteComs() {
                this.$emit('delete', this.index);
            },
            deleteBgimg(val) {
                this.content.left_icon = '';
            },
            setLink(item) {
                this.content.right_link = item
            }
        }
    }
</script>

<style scoped>
    .edit-shop-content .ivu-form-item {
        margin-bottom: 10px;
    }

    .edit-shop-content .ivu-form-item:after {
        display: none;
    }

    .tit {
        color: #999;
    }
</style>
