<template>
  <div class="module-groupGoods">
    <div class="mod-shop-groupGoods">
       <section class="good_tags_scroll" v-if="content.tag.length > 1">
          <div class="scroll" >
              <div :class="[index==0 ? 'item active' : 'item']" v-for="(item,index) in contentTag" :key="index">
                <span v-if="index==0" :style="{'color': content.font_bgcolor, 'borderColor': content.font_bgcolor}">{{item.title}}</span>
                <span v-else>{{item.title}}</span>
              </div>
          </div>
       </section>
        <section class="good_tags" v-else>
            <template v-for="(item,index) in content.tag">
              <span class="tag_text" v-if="index==0" :key="index">{{item.title}}</span>
            </template>
          <i type="chevron-right"></i>
        </section>
        <div class="view-article">
           <!--左图-->
          <section class="article-type-1" v-if="content.style == 2">
             <ul>
                <li >
                    <img src="../../assets/images/icon-bg8.jpg" class="u-img"/>
                    <div class="u-des">
                        <p class="tit">资讯标题</p>
                        <p class="content"><span v-if="content.show_ele.content">资讯摘要</span></p>
                        <p class="info" v-if="content.show_ele.time||content.show_ele.red"><span v-if="content.show_ele.time">2017-10-31  18:30</span>
                        <span v-if="content.show_ele.red" class="red">阅读 : 100</span></p>
                    </div>

                </li>
             </ul>

          </section>
           <!--右图-->
          <section class="article-type-2"  v-if="content.style == 1">
             <ul>
                <li >
                    <div class="u-des">
                      <p class="tit">资讯标题</p>
                        <p class="content"><span v-if="content.show_ele.content">资讯摘要</span></p>
                        <p class="info" v-if="content.show_ele.time||content.show_ele.red"><span v-if="content.show_ele.time">2017-10-31  18:30</span>
                        <span v-if="content.show_ele.red" class="red">阅读 : 100</span></p>
                  </div>
                   <img src="../../assets/images/icon-bg8.jpg" class="u-img"/>

                </li>

             </ul>

          </section>
           <!--三小图-->
          <section class="article-type-3"  v-if="content.style==3">
             <ul>
                <li >
                  <p class="tit">资讯标题</p>
                  <p class="content"><span v-if="content.show_ele.content">资讯摘要</span></p>
                  <div class="u-imgs">
                      <img :style="{width:(width-40)/3+'px',height:(width-40)/3+'px'}" src="../../assets/images/icon-bg8.jpg" class="u-img"/>
                      <img :style="{width:(width-40)/3+'px',height:(width-40)/3+'px'}" src="../../assets/images/icon-bg8.jpg" class="u-img"/>
                      <img :style="{width:(width-40)/3+'px',height:(width-40)/3+'px'}" src="../../assets/images/icon-bg8.jpg" class="u-img"/>
                  </div>
                  <p class="info" v-if="content.show_ele.time||content.show_ele.red"><span v-if="content.show_ele.time">2017-10-31  18:30</span>
                   <span v-if="content.show_ele.red" class="red">阅读 : 100</span></p>
                </li>
             </ul>

          </section>

          <!--大图-->
          <section class="article-type-4"  v-if="content.style==4">
             <ul>
                <li >
                  <p class="tit">资讯标题</p>
                 <p class="content"><span v-if="content.show_ele.content">资讯摘要</span></p>
                  <img :style="{width:(width-20)+'px',height:(width-20)+'px'}" src="../../assets/images/icon-bg8.jpg" class="u-img"/>
                   <p class="info" v-if="content.show_ele.time||content.show_ele.red"><span v-if="content.show_ele.time">2017-10-31  18:30</span>
                    <span v-if="content.show_ele.red" class="red">阅读 : 100</span></p>
                </li>
             </ul>
          </section>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      width () {
        return 298;
      },
      contentTag () {
        return this.content.tag.filter(function(item) {
          item.title = item.title.replace(/┗/i,'')
          return item
        })
      }
    },
    data () {
      return {
        'type': 'groupGoods'
      }
    }
  }
</script>

<style scoped>
.view-goods{display: -webkit-box;}
.mod-shop-groupGoods{background-color: #f0f3f6;}
.good_tags{flex-direction: row;align-items: center;padding: 8px 10px; background-color: #fff;margin-bottom: 3px;}
.good_tags .tag_text{display:inline-block;font-size: 14px;color: #424141;overflow: hidden;text-overflow:ellipsis;white-space:nowrap;width:80%;}

.good_tags i{margin-top: 2px;
float: right;
    font-size: 14px;
    color: #424141;}
.good_tags_scroll{
  height: 38px;
  background-color: rgba(255, 255, 255, 1);
	box-shadow: inset 0px -1px 0px 0px
    rgba(216, 216, 216, 1);
    margin-bottom: 3px;
  overflow: hidden;
}
.good_tags_scroll .scroll{
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: 38px;
}
.good_tags_scroll .item{
  margin-right: 12px;
  text-align: center;
  float: left;
}
.good_tags_scroll span{
  font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 35.53073px;
	letter-spacing: 0px;
  color: #333333;
  display: inline-block;

}
.good_tags_scroll .active span{
  color: #ff5500;
  border-bottom: 2px solid #ff5500;
}
.good_tags_scroll .item:first-child{
  margin-left: 10px;
}
.good_tags_scroll .item:last-child{
  margin-right: 0px;
  padding-right: 10px;
}
.icon{background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAATCAYAAACORR0GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYmE0NTlkMy1lNTJjLWRhNGQtODhmNi01YzJiMDM1MjAzZjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjYyQkZCMTVDMkMzMTFFN0FFQkRDMTZENUEyOURENkEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjYyQkZCMTRDMkMzMTFFN0FFQkRDMTZENUEyOURENkEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NGU5ZWM5ODktMjNhMC1iMDQ4LWFlOGUtMzk3ZTIxMjQ0ZWNiIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6ZWFiZmJhMGQtYjU2MS0xMWU3LWEyZDAtZGQzYzhmNWMzZTcyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+TLXbpwAAASBJREFUeNpi7OvrY2ZgYCgB4kIgFmfADz4A8T4gPgfEkUCsAcTMeNS/BOJ+IO5hARJ1UEwMEADiICgmBoAc3gHEXExAIh0q6AnEjASwIhDPBuInQBwCxNwE1HtAzU5nQQquHUS48AEQp0Et+EqE+p0wnzExkAe+kqoBm0UngPg/FnySgFkXoOouEmsRIwmOwqYPq34WLGLmZAanPqlBRxOAzaJ9aHGzF4/+c1ji8gwtfER0HGOLIycSLDKgxEV0i6NDOPIRCB+mpkU0AdjiyI4IfdykFkNM0DoDBNyJUK8AxDOB+AYQBwIxJwH1MDNfskA11hFZeiODdSSonQmyqAmIfwJxPhCLEdDwCoiPAvFZIA4DYnUgZiegfgIQdwEEGABdVD29sAAv7wAAAABJRU5ErkJggg==);
  width: 13px;
  height: 10px;
  display: inline-block;
  background-size: 100%;
  vertical-align: -2px;
  margin-right: 4px;
}
.article-type-1,.article-type-2{
    padding: 0 3px;
}
.article-type-1 ul,.article-type-2 ul{
    background-color: #fff;
    padding: 10px 0px 10px 9px;
}
.article-type-2 ul li{min-height: 114px;}
.article-type-1 ul li,.article-type-2 ul li,.article-type-3 ul li,.article-type-4 ul li{
    position: relative;
    padding: 10px 9px 10px 0px;
   border-bottom:0.5px solid #eaeaea;
   display: inline-block;
    width: 100%;
}
.article-type-1 ul li:first-child{
    padding-top:0px;
}
.article-type-1 ul li:last-child,.article-type-2 ul li:last-child{
     padding-bottom: 0px;
     border-bottom:0;
 }
.article-type-1 ul li .u-img{width:94px;height: 94px;float: left;}
.article-type-1 ul li .u-des{margin-left: 104px;}
.article-type-1 ul li .u-des .tit,.article-type-2 ul li .u-des .tit
{font-size: 16px;line-height: 22.5px;color: #424141;font-weight: 600;
overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;display: -webkit-box;
}
.article-type-1 ul li .u-des .content,.article-type-2 ul li .u-des .content{
    font-size: 12px;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;display: -webkit-box;
    height: 32px;
    margin-top: 5px;
}
.article-type-1 ul li .u-des .info span,
.article-type-2 ul li .u-des .info span,
.article-type-3 ul li .info span,
.article-type-4 ul li .info span{
    font-size: 10px;
    color: #8e8e8e;
}
.article-type-1 ul li .u-des .info span.red,
.article-type-2 ul li .u-des .info span.red,
.article-type-3 ul li .info span.red,
.article-type-4 ul li .info span.red{
    float: right;
}
.article-type-2 ul{padding: 0px 0px 0px 9px;}
.article-type-2 ul li{position: relative;}
.article-type-2 ul li .u-img{width:94px;height: 94px;    position: absolute;
top: 10px;
right: 10px;}
.article-type-2 ul li .u-des{margin-right: 104px;}
.article-type-2 ul li:last-child{padding-bottom: 10px;}
.article-type-3 ul,.article-type-4 ul{
    background-color: #fff;
    padding: 0px 0px 0px 9px;
}
.article-type-3 ul li,.article-type-4 ul li{
    padding: 15px 9px 15px 0px;
}
.article-type-3 .tit,.article-type-4 .tit{
    font-size: 16px;font-weight: 600;color: #424141;overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;display: -webkit-box;
}
.article-type-3 .content,.article-type-4 .content{
    font-size: 12px;color: #666666;overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;display: -webkit-box;margin-top:5px;
}
.article-type-3 .u-imgs{display: inline-block;margin-top:5px;}
.article-type-3 .u-imgs .u-img{width:112px;height: 112px;float: left;margin-right: 10px;}
.article-type-3 .u-imgs .u-img:last-child{margin-right: 0;}

.article-type-4 .u-img{margin-top:5px;}
</style>
