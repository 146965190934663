<template>
  <div class="edit-groupGoods">
    <div class="edit-shop-header">
      <h3>商品分类设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-groupGoods">
        <el-form :model="content" label-width="100px" size="small">
          <el-form-item label="商品样式：" prop="style" class="img">
             <el-radio-group v-model="content.style" style="overflow: hidden;">
               <el-radio :label="1">双列</el-radio>
               <el-radio :label="2">小图单列</el-radio>
               <el-radio :label="3">大图单列</el-radio>
               <el-radio :label="4">三列</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="排序方式：" prop="sort">
              <el-radio-group v-model="content.sort">
                  <el-radio :label="1">销量</el-radio>
                  <el-radio :label="2">新品</el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item label="显示元素：">
              <el-checkbox v-model="content.show_ele.name">商品名称</el-checkbox>
              <el-checkbox v-model="content.show_ele.price">价格</el-checkbox>
              <el-checkbox v-model="content.show_ele.sales">销量</el-checkbox>
              <el-checkbox v-model="content.show_ele.cart">购物车</el-checkbox>
           </el-form-item>
          <el-form-item label="商品数量：" prop="goods_num">
              <el-input-number v-model="content.goods_num"></el-input-number>
          </el-form-item>
          <el-form-item label="选择分类：" prop="tag">
              <el-select v-model="content.tag" multiple style="width:300px">
                  <el-option :value="0" :key="0" label="所有商品"></el-option>
                  <el-option v-for="item in groupList" :value="item.id" :key="item.id" :label="item.title"></el-option>
              </el-select>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    created () {
      this.getGroups();
    },
    data () {
      return {
        'type': 'groupGoods',
        'tempData': [],
        'groupList': [], // 分组列表
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      // 获取商品分组
      getGroups () {
        let params = {
          page: 1,
          pagesize: 100,
          type: 1,
        };
        this.$api.goods.menuIndex(params, (res) => {
          if (res.errcode == 0) {
            this.groupList = res.data;
          }
        });
      }
    }
  }
</script>

<style scoped>
  .edit-shop-content .img.ivu-form-item .select i{
    color: #fff;
    margin: 7px 0 0 8px;
  }
  .edit-shop-content .img.ivu-form-item  .ivu-radio-group label{
    width: 94px;
    text-align: center;
    color: #999;
    margin-top: -28px;
  }
</style>
