<template>
  <div class="module-drawernav">
    <div class="mod-shop-drawernav" :style="navBg" v-if="content.array == 1">
      <div class="mod-item-box mod-item-box-1">
        <div class="mod-item" :style="border" v-for="(item, index) in content.data" :key="index">
          <span class="mod-img" v-show="content.show == 1 || content.show == 2">
            <img v-if="item.nav_icon != ''" :class="{'yuan': content.style == 1, 'fang': content.style == 2}" :src="$store.state.imgBaseUrl + item.nav_icon">
            <img v-else :class="{'yuan': content.style == 1, 'fang': content.style == 2}" src="../../assets/images/icon-bg8.jpg">
          </span>
          <!-- <h3 class="mod-name" :class="{'mod-name-margin-left': content.show == 3}" :style="{color: item.nav_color}">{{item.nav_name}}</h3> -->
          <h3 class="mod-name" :style="{color: item.nav_color}">{{item.nav_name}}</h3>
        </div>
      </div>
    </div>
    <div class="mod-shop-drawernav" :style="navBg" v-else-if="content.array == 2">
      <div class="mod-item-box mod-item-box-2">
        <div class="mod-item" :style="border" v-for="(item, index) in content.data" :key="index">
          <span class="mod-img" :class="{'mod-img-small1': content.show == 1 && content.size == 2, 'mod-img-small2': content.show == 2 && content.size == 2}" v-show="content.show == 1 || content.show == 2">
            <img v-if="item.nav_icon != ''" :class="{'yuan': content.style == 1, 'fang': content.style == 2}" :src="$store.state.imgBaseUrl + item.nav_icon">
            <img v-else :class="{'yuan': content.style == 1, 'fang': content.style == 2}" src="../../assets/images/icon-bg8.jpg">
          </span>
          <h3 class="mod-name" v-show="content.show == 1" :style="{color: item.nav_color}">{{item.nav_name}}</h3>
        </div>
      </div>
    </div>
    <div class="drawernav-open">
      <!-- <span><i type="navicon" class="navicon"></i></span> -->
      <span><img :src="$store.state.imgBaseUrl + content.icon"></span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {
      navWidth () {
        return 100 + '%'
      },

      total () {
        return this.content.data.length
      },

      navBg () {
        var BackGround = {};
        if (this.content.bgimg != '') {
          BackGround = {
            backgroundImage: 'url(' + this.$store.state.imgBaseUrl + this.content.bgimg + ')',
            backgroundPosition: '0 0',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundColor: this.hexToRgba(this.content.bgcolor, this.content.bgopacity),
            paddingTop: this.content.margin + 'px'
          }
        } else {
          BackGround = {
            backgroundColor: this.hexToRgba(this.content.bgcolor, this.content.bgopacity),
            paddingTop: this.content.margin + 'px'
          }
        }
        return BackGround;
      },

      border () {
        var borderBottom = {};
        if (this.content.line) {
          borderBottom = {
            textAlign: this.content.align == 1 ? 'left' : 'center',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: this.hexToRgba(this.content.linecolor, 100)
          }
        } else {
          borderBottom = {
            textAlign: this.content.align == 1 ? 'left' : 'center',
            borderBottom: 'none'
          }
        }
        return borderBottom;
      }
    },
    data () {
      return {
        'type': 'drawerFixed'
      }
    },
    created () {

    },
    methods: {
      hexToRgba (hex, opacity) {
        console.log((opacity / 100).toFixed(2))
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      }
    }
  }
</script>

<style scoped>
  .module-drawernav{
    width:158px;
  }
  .mod-shop-drawernav{
    background-color: #fff;
    width:128px;
    height: 100%;
    position: absolute;
  }
  .mod-item-box {
    overflow: hidden;
  }
  .mod-item:hover {
    /*background-color: #f5f5f5;*/
  }
  .mod-item:hover .mod-name {
    /*color: #444;*/
  }
  .mod-img img.yuan{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .mod-img img.fang{
    height: 100%;
    width: 100%;
    border-radius: 10%;
  }
  .mod-item-box-1 .mod-item {
    width: 80%;
    display: inline-block;
    padding: 8px 0 7px 0;
    margin-left: 10%;
  }
  .mod-item-box-1 .mod-img {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 3px 0 0;
    border-radius: 50%;
  }
  .mod-item-box-1 .mod-img img{
    display: block;
    height: 100%;
    width: 100%;
  }
  .mod-item-box-1 .mod-name {
    font-size: 12px;
    color: #8e8e8e;
    font-weight: normal;
    word-wrap: break-word;
    word-break: break-all;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    display: inline-block;
  }
  .mod-item-box-1 .mod-name-margin-left {
    /*margin-left: 12px;*/
  }

  .mod-item-box-2 .mod-item {
    width: 80%;
    display: inline-block;
    padding: 8px 0 7px 0;
    margin-left: 10%;
    text-align: center;
  }
  .mod-item-box-2 .mod-img {
    display: inline-block;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .mod-item-box-2 .mod-img-small1 {
    height: 24px;
    width: 24px;
  }
  .mod-item-box-2 .mod-img-small2 {
    height: 32px;
    width: 32px;
  }
  .mod-item-box-2 .mod-img img{
    display: block;
    height: 100%;
    width: 100%;
  }
  .mod-item-box-2 .mod-name {
    font-size: 12px;
    color: #8e8e8e;
    font-weight: normal;
    word-wrap: break-word;
    word-break: break-all;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
  }

  .drawernav-open {
    float: right;
    width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    /*background-color: rgba(255, 255, 255, 0.25);*/
  }
  .drawernav-open .navicon{
    font-size: 28px;
    color: #fff;
  }
  .drawernav-open img{
    width: 30px;
    height: 30px;
  }
</style>
