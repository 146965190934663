<template>
  <div class="edit-carousel">
    <div class="edit-shop-header">
      <h3>轮播图设置</h3>
      <div class="edit-shop-delete">
        <!--Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip-->
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-carousel">
        <el-form :model="content" label-width="140px" size="small">
          <el-form-item label="选择风格：">
            <el-radio-group v-model="content.direction">
              <el-radio :label="1">
                <span>横向</span>
              </el-radio>
              <el-radio :label="2">
                <span>纵向</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="高度：">
            <el-row>
              <el-col :span="6">
                <el-input-number v-model="content.height" :min="1" :max="1000" size="small" label="请输入高度" @on-change="InputNumberChange" controls-position="right"></el-input-number>
              </el-col>
              <el-col :span="10">
                <p class="edit-tips">px 默认为300px，最大可输入1000</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="图片周围留白宽度：">
            <el-row>
              <el-col :span="6">
                <el-input-number v-model="content.margin" :min="0" :max="100" size="small" label="请输入" controls-position="right"></el-input-number>
              </el-col>
              <el-col :span="10">
                <p class="edit-tips">默认为0px，最大可输入100</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="自动切换时间间隔：">
            <el-row>
              <el-col :span="6">
                <el-input-number v-model="content.interval" :min="1" size="small" label="请输入" controls-position="right"></el-input-number>
              </el-col>
              <el-col :span="10">
                <p class="edit-tips">s</p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div class="choice-box" v-for="(item, index) in content.data" :key="index" style="position: relative">
          <el-popconfirm style="position: absolute; right: 10px; top: 0; z-index: 10;"
             title="您确认删除吗？"
             @confirm="deleteItem(index)">
            <el-button type="text" slot="reference">
              <i class="el-icon-delete" style="font-size: 18px;" />
            </el-button>
          </el-popconfirm>
          <div class="choice-image-box">
            <SelectImgs :selectNum="1" :selectData="[item.img_url]" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
          </div>
          <div class="choice-image-link">
            <el-form :model="content" label-width="90px">
              <el-form-item label="图片链接：">
                <select-link :setData="item.img_link" :index="index" :setFunc="setLink">
                  <template slot="button">
                    <el-input :value="item.img_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                  </template>
                </select-link>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="overflow:hidden; margin-top: 15px; padding-left: 10px;">
          <el-button type="primary" size="small" @click="addItem" class="fl" :disabled="content.data.length > 4">添加图片</el-button>
          <p class="edit-tips" style="margin-top: 7px;">最多一共可以有五张图片</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import SelectImgs from '../../components/SelectImgs'
  import selectLink from './select.link.vue'
  export default {
    components: {
      SelectImgs, selectLink
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'carousel',
      }
    },
    created () {

    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      deleteItem (index) {
        this.content.data.splice(index, 1);
      },
      addItem (index) {
        this.content.data.push({
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        });
      },
      setImgUrl (item, index) {
        this.content.data[index].img_url = item.pic;
      },
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      setLink(item,index) {
        this.content.data[index].img_link = item
      },
      heightDelete (event) {
        // console.log(parseInt(event.target.value));
        // var value = parseInt(event.target.value);
        // if (value == 'NaN') {
        //   this.content.height = 1
        // }
      },
      InputNumberChange (value) {
        if (parseInt(value) < 1) {
          this.$Message.info({
            content: '高度最小为1px',
            duration: 3
          });
          setTimeout(() => {
            this.content.height = 1
          }, 100)
        } else if (parseInt(value) >= 1 && parseInt(value) <= 1000) {
          setTimeout(() => {
            this.content.height = Math.round(parseInt(value))
          }, 100)
        } else if (parseInt(value) > 1000) {
          this.$Message.info({
            content: '高度最大为1000px',
            duration: 3
          });
          setTimeout(() => {
            this.content.height = 1000
          }, 100)
        }
      }
    }
  }
</script>

<style scoped>
  .edit-shop-carousel {
    background-color: #fff;
  }
  .edit-shop-content .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content .ivu-form-item:after{
    display: none;
  }
  .choice-box{
    height: 120px;
    margin: 10px;
    padding: 10px;
    background-color: #eee;
    position: relative;
  }
  .choice-image-box{
    float: left;
  }
  .choice-image-link {
    float: left;
    width: 380px;
  }

  .edit-shop-carousel .ivu-form{

  }
  .edit-shop-carousel .ivu-form-item{
    height: 34px;
  }
  .edit-shop-carousel .edit-input{
    width: 100px;float: left;
  }
  .edit-shop-carousel .edit-unit{
    float: left;margin-left: 10px;
  }
  .edit-shop-carousel .edit-tips{
    float:left;margin-left: 20px;margin-top: 1px;color: #999;
  }
</style>
