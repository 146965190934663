<template>
  <div class="module-search">
      <div class="view-search flex"  v-bind:style="{backgroundColor:content.out_bacolor}">
        <div class="u-search flex" v-bind:style="{backgroundColor:content.in_bgcolor}">
            <span class="box">
              <!--Icon type="search" class="u-searchIcon"></Icon-->
              <i class="el-icon-search"></i>
              <span class="ftsize13" :style="{color: content.color}">{{content.label||'请输入搜索内容'}}</span>
            </span>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'search'
      }
    }
  }
</script>

<style scoped>
 .view-search{width:100%;height: 44px;    padding-top: 8px;
    padding-left: 4%;background-color: #dddddd;}
    .ftsize13{font-size:13px;color: #999999;}
    .u-search{padding-top:5px;border: 1px solid #ddd;width:94%;text-align: center;height: 28px;background-color: #fff;flex-direction:row;border-radius: 5px;}
    .u-search .box{
      overflow: hidden;
      display: inline-block;
    }
    .u-search .el-icon-search{
        width: 14px;
        height: 14px;
        font-size: 13px;
        color: #999;
        float: left;
        margin: 2px 4px 0 0;
      }
</style>
