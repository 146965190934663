<template>
  <div class="module-focus">
    <div class="mod-shop-focus" :style="{backgroundColor:content.bgcolor}">
      <div class="view-focus">
        <p>{{content.note}}</p>
          <ul>
            <li ><img class="u-focusIcon" src="../../assets/images/icon-bg8.jpg" /></li>
            <li >
              <div class="focustext" >{{content.title}} <span>{{content.forex}}</span><br>
                <div class="spantext">{{content.text}}</div>
              </div>
            </li>
            <li><div class="icon-right" v-if="content.stlye == 1">关注</div></li>
            <li><a><div class="icon-right" v-if="content.stlye == 2">查看</div></a></li>
          </ul>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'publicFocus'
      }
    }
  }
</script>

<style scoped>
.mod-shop-focus{height:80px;position:relative;}
.view-focus{padding: 4px 7px;}
.view-focus p{font-size:8px;color:#cbcbcb;}
.u-focusIcon{width: 35px;height: 35px;margin-top:8px;border-radius:50%;}

.view-focus ul{width:100%;position:relative;}
.view-focus ul li{float:left;padding-right:10px;}
/*.view-focus ul .li3{padding-left:5px;}*/
.view-focus ul li:last-child{float:right;padding-right:9px;}

.view-focus .focustext{margin-top:10px;font-size: 14px;color:#101010;letter-spacing:1px;font-family:Arial,Helvetica,sans-serif;}
.focustext span{font-size:12px;}
.focustext .spantext{font-size:10px;color:#929292;width:169px;letter-spacing:.5px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.icon-right{font-size:12px;color:#31aa26;border:1px solid #3baa39;padding:2px 12px;margin-top:18px;border-radius:2px;
  font-family:Arial,Helvetica,sans-serif;}
</style>
