<template>
  <div class="module-middlenav">
    <div class="mod-shop-middlenav" :style="navBg" v-if="content.show == 1">
      <div class="mod-item-box mod-item-box-1" :style="{paddingLeft: content.margin + 'px', paddingRight: content.margin + 'px', paddingTop: content.padding_top + 'px', paddingBottom: content.padding_bottom + 'px'}">
        <div class="mod-item" :style="padStyle" v-for="(item, index) in content.data" :key="index">
          <span class="mod-img" :class="{'mod-img-small': content.size == 2}">
            <img v-if="item.nav_icon != ''" :class="{'yuan': content.style == 1, 'fang': content.style == 2}" :src="$store.state.imgBaseUrl + item.nav_icon">
            <img v-else :class="{'yuan': content.style == 1, 'fang': content.style == 2}" src="../../assets/images/icon-bg8.jpg">
          </span>
          <h3 class="mod-name" :class="{'mod-name-small': content.size == 2}" :style="{color: item.nav_color}">{{item.nav_name}}</h3>
        </div>
      </div>
    </div>

    <div class="mod-shop-middlenav" :style="navBg" v-if="content.show == 2">
      <div class="mod-item-box mod-item-box-2" :style="{paddingLeft: content.margin + 'px', paddingRight: content.margin + 'px', paddingTop: content.padding_top + 'px', paddingBottom: content.padding_bottom + 'px'}">
        <div class="mod-item" :style="padStyle" v-for="(item, index) in content.data" :key="index">
          <span class="mod-img" :class="{'mod-img-small': content.size == 2}">
            <img v-if="item.nav_icon != ''" :class="{'yuan': content.style == 1, 'fang': content.style == 2}" :src="$store.state.imgBaseUrl + item.nav_icon">
            <img v-else :class="{'yuan': content.style == 1, 'fang': content.style == 2}" src="../../assets/images/icon-bg8.jpg">
          </span>
        </div>
      </div>
    </div>

    <div class="mod-shop-middlenav" :style="navBg" v-if="content.show == 3">
      <div class="mod-item-box mod-item-box-3" :style="{paddingLeft: content.margin + 'px', paddingRight: content.margin + 'px', paddingTop: content.padding_top + 'px', paddingBottom: content.padding_bottom + 'px'}">
        <div class="mod-item" :style="padStyle" v-for="(item, index) in content.data" :key="index">
          <h3 class="mod-name mod-name-none-margin" :class="{'mod-name-small': content.size == 2}" :style="{color: item.nav_color}">{{item.nav_name}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {
      navWidth () {
        return 100 / this.content.num + '%'
      },

      total () {
        return this.content.data.length
      },

      pageSize () {
        return this.content.num * 2
      },

      navPage () {
        var total = this.content.data.length;
        var pageSize = this.content.num * 2;
        return Math.ceil(total / pageSize);
      },

      padStyle () {
        var temPad = {}
        // {width: navWidth,paddingBottom:content.line_margin + 'px'}
        if (this.content.fixed == 1) {
          temPad = {
            width: 100 / this.content.num + '%',
            paddingTop: this.content.line_margin + 'px'
            // marginTop: '-' + this.content.line_margin + 'px'
          }
        } else if (this.content.fixed == 2) {
          temPad = {
            width: 100 / this.content.num + '%',
            paddingBottom: this.content.line_margin + 'px'
            // marginBottom: '-' + this.content.line_margin + 'px'
          }
        }
        return temPad;
      },

      navBg () {
        var BackGround = {};
        if (this.content.bgimg != '') {
          BackGround = {
            backgroundImage: 'url(' + this.$store.state.imgBaseUrl + this.content.bgimg + ')',
            backgroundPosition: '0 0',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundColor: this.hexToRgba(this.content.bgcolor, this.content.bgopacity),
            marginTop: this.content.fixed == 1 ? '-' + this.content.line_margin + 'px' : 0,
            marginBottom: this.content.fixed == 1 ? 0 : '-' + this.content.line_margin + 'px'
          }
        } else {
          BackGround = {
            backgroundColor: this.hexToRgba(this.content.bgcolor, this.content.bgopacity),
            marginTop: this.content.fixed == 1 ? '-' + this.content.line_margin + 'px' : 0,
            marginBottom: this.content.fixed == 1 ? 0 : '-' + this.content.line_margin + 'px'
          }
        }
        return BackGround;
      }
    },
    data () {
      return {
        'type': 'latticeNav',
        imgBaseUrl: this.$store.state.imgBaseUrl
      }
    },
    created () {

    },
    methods: {
      hexToRgba (hex, opacity) {
        // console.log((opacity / 100).toFixed(2))
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      }
    }
  }
</script>

<style scoped>
  .mod-shop-middlenav{
    background-color: #fff;
  }
  .mod-item-box {
    overflow: hidden;
  }
  .mod-item {
    width: 33.33%;
    display: block;
    text-align: center;
    /*padding: 10px 0;*/
    float:left;
  }
  .mod-img {
    display: block;
    height: 36px;
    width: 36px;
    margin: 0 auto;
    border-radius: 50%;
  }
  .mod-img-small {
    height: 32px;
    width: 32px;
  }
  .mod-img img.yuan{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .mod-img img.fang{
    height: 100%;
    width: 100%;
  }
  .mod-name {
    font-size: 13px;
    color: #444;
    font-weight: normal;
    margin-top: 5px;
    word-wrap: break-word;
    word-break: break-all;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
  }
  .mod-name-none-margin {
    margin-top: 0;
  }
  .mod-name-small{
    font-size: 12px;
  }
  .mod-dots{
    clear: left;
    width: 100%;
    padding: 4px 0;
    text-align: center;
  }
  .mod-dots span{
    display: inline-block;
    border: 1px solid #666;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin: 0 4px;
  }
</style>
