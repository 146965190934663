<template>
  <div class="module-customarticle">
    <div class="mod-shop-customarticle">
      <div class="m-list5">
        <ul class="f-cb" v-if="content.article.length == 0">
          <li>
            <div class="u-img"><a href="javascript:void(0);">
              <img src="../../assets/images/icon-bg8.jpg" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);">资讯标题资讯标题资讯标题资讯标题</a></h3>
            </div>
          </li>
          <li>
            <div class="u-img"><a href="javascript:void(0);">
              <img src="../../assets/images/icon-bg8.jpg" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);">资讯标题资讯标题资讯标题资讯标题</a></h3>
            </div>
          </li>
          <li>
            <div class="u-img"><a href="javascript:void(0);">
              <img src="../../assets/images/icon-bg8.jpg" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);">资讯标题资讯标题资讯标题资讯标题</a></h3>
            </div>
          </li>
        </ul>
        <ul class="f-cb" v-else>
          <li v-for="(item, index) in content.article" :key="index">
            <div class="u-img"><a href="javascript:void(0);">
              <img :src="$store.state.imgBaseUrl + item.img_json[0].url" alt="" />
            </a></div>
            <div class="txt">
              <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'edit-shop-customArticle'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.parseContent()
        },
        deep: true
      }
    },
    created () {
      this.parseContent();
    },
    methods: {
      parseContent () {
        this.content.article.forEach((item, index) => {
          if (typeof item.img_json == 'string') {
            this.content.article[index].img_json = JSON.parse(item.img_json)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .mod-shop-customarticle {
    max-height: 182px;
    width: 298px;
    overflow: hidden;
    background-color: #fff;
  }

  .m-list5 {
    padding: 1px 0 0;
    zoom: 1;
    width: 1000px;
    background-color: #fff;
  }

  .m-list5 ul {
    margin: 0;
    padding: 7px;
    overflow: hidden;
  }

  .m-list5 li {
    width: 120px;
    display: inline-block;
    margin: 0 7px 0 0;
    border: 1px solid #eee;
  }

  .m-list5 .txt {
    width: 100%;
    overflow: hidden;
  }

  .m-list5 h3 {
    overflow: hidden;
    line-height: 18px;
    margin: 4px 0;
    padding: 0 4px;
    color: #666;
    font-size: 12px;
    font-weight: normal;
    height: 36px;
  }

  .m-list5 h3 a {
    color: #666;
  }

  .m-list5 .u-img {
    width: 100%;
  }

  .m-list5 .u-img a {
    display: block;
    height: 120px;
  }

  .m-list5 .u-img2 {
    width: 100%;
  }

  .m-list5 .u-img img {
    width: 100%;
    height: 120px;
  }
</style>
