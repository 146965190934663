<template>
  <div class="module-richText">
    <div class="mod-shop-richText">
      <section class="ql-snow" :style="{'padding': content.padding/2 + 'px'}">
        <div class="ql-editor wang-box" v-html="content.text" v-if="content.text != ''"></div>
        <div v-else>
          <p class="">请输入内容......</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'richText'
      }
    }
  }
</script>

<style scoped>
.wang-box img {
  max-width: 100%;
}
</style>
