<template>
  <div class="module-video">
    <div class="mod-shop-video">
       <div class="view-video" >
           <img v-if="content.img != ''" :src="img" />
           <img v-else src="https://dshop-static.tsphp.com/applet_mch/images/icon-bg3.jpg" />
       </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      img () {
        if (this.content.img != '') {
          return this.$store.state.imgBaseUrl + this.content.img
        }
        return ''
      }
    },
    data () {
      return {
        'type': 'video'
      }
    }
  }
</script>

<style scoped>
  .view-video{height:150px;}
  .view-video img{
     height: 100%;
     width:100%;
   }
</style>
