<template>
    <div class="edit-information">
        <div class="edit-shop-header">
            <h3>图文设置</h3>
            <div class="edit-shop-delete">
                <!--Poptip
                    confirm
                    placement="left"
                    title="您确认删除吗？"
                    @on-ok="deleteComs()"
                    @on-cancel="cancel">
                  <a href="javascript:void(0);">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </a>
                </Poptip-->
            </div>
        </div>
        <div class="edit-shop-content">
            <section class="edit-shop-images">
                <el-form :model="content" label-width="80px" size="small">
                    <el-form-item label="选择模板：" prop="style">
                        <el-radio-group v-model="content.style">
                            <el-radio :label="1">左图右文</el-radio>
                            <el-radio :label="2">右图左文</el-radio>
                            <el-radio :label="3">上图下文</el-radio>
                            <el-radio :label="4">双列</el-radio>
                            <el-radio :label="5">三列</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="颜色：" prop="style">
                        <el-row>
                            <el-col :span="5">
                                <el-form-item label="文字颜色：">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-color-picker v-model="content.color"></el-color-picker>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="背景颜色：">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-color-picker v-model="content.bgcolor"></el-color-picker>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </section>
            <div class="edit-item" v-for="(item, index) in content.data" :key="index">
                <el-form :model="item" label-width="90px" size="small" style="position: relative">
                    <el-popconfirm style="position: absolute; right: 10px; top: 0; z-index: 10;"
                                   title="您确认删除吗？"
                                   @confirm="deleteItem(index)">
                        <el-button type="text" slot="reference">
                            <i class="el-icon-delete" style="font-size: 18px;" />
                        </el-button>
                    </el-popconfirm>
                    <el-form-item label="标题：">
                        <el-row>
                            <el-col :span="12">
                                <el-input v-model="item.it_title" placeholder="标题最多10个字" maxlength="10" size="small"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <p class="tit">最多可输入10个字</p>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="简介：">
                        <el-row>
                            <el-col :span="20">
                                <el-input v-model="item.it_desc" type="textarea" :rows="5" placeholder="请输入简介" size="small"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="选择链接：">
                        <el-col :span="12">
                          <select-link :setData="item.it_link" :index="index" :setFunc="setLink">
                            <template slot="button">
                              <el-input :value="item.it_link.title" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                            </template>
                          </select-link>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="选择图片：">
                        <el-row>
                            <el-col :span="5">
                                <SelectImgs :selectNum="1" :selectData="[item.it_icon?item.it_icon:'']" :idx="index" :selectFunc="setImgUrl"></SelectImgs>
                            </el-col>
                            <el-col :span="12">
                                <p class="tit">建议尺寸 149 x 149 像素，大小不超过1M</p>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </div>
            <el-row style="height:40px;margin:15px 0 0 10px;">
                <el-col :span="6" class="form-button" style="width: 100px;">
                    <el-button type="primary" size="small" @click="addItem" class="fl" :disabled="content.data.length > 9">添加图文</el-button>
                </el-col>
                <el-col :span="12" class="label tit" style="height:32px;line-height:32px;">
                    <span>最多一共可以选择10个图文</span></el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import SelectImgs from '../../components/SelectImgs'
    import selectLink from './select.link.vue'

    export default {
        props: {
            index: {
                type: Number,
                default: 0
            },
            content: {}
        },
        computed: {},
        components: {
            SelectImgs,
            selectLink
        },
        data() {
            return {
                'type': 'imageText'
            }
        },
        watch: {
            'content': {
                handler(newValue, oldValue) {
                    this.contentChange()
                },
                deep: true
            }
        },
        methods: {
            contentChange() {
                this.$emit('message', this.content);
            },
            deleteComs() {
                this.$emit('delete', this.index);
            },
            setImgUrl(item, index) {
                this.content.data[index].it_icon = item.pic
            },
            setLink(item,index) {
                this.content.data[index].it_link = item
            },
            addItem() {
                let length = this.content.data.length
                this.content.data.push({
                    it_title: '标题' + length,
                    it_desc: '简介' + length,
                    it_icon: '',
                    it_link: {
                        type: '',
                        name: '',
                        id: ''
                    },
                    it_color: '#333333', // 文字颜色
                    it_bgcolor: '#ffffff', // 背景颜色
                    it_opacity: 100 // 背景透明度
                })
            },
            deleteItem(index) {
                this.content.data.splice(index, 1);
            },
            chooseStyle(type) {
                this.content.style = type
            }
        }
    }
</script>

<style scoped>
    .images-style .el-col {
        position: relative;
        margin-right: 10px;
        display: block;
        width: 85px;
    }

    .images-style .el-col:last-child {
        margin-right: 0;
    }

    .images-style .el-col img {
        width: 80px;
        height: 65px;
        border: 2px solid #fff;
    }

    .images-style .el-col p {
        text-align: center;
        color: #999;
        line-height: 30px;
    }

    .images-style .el-col img:hover {
        cursor: pointer;
    }

    .images-style img.select {
        border: 2px solid #57a3f3;
    }

    .images-style span.select {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 47px;
        right: 3px;
        background: #57a3f3;
        border-radius: 100% 0 0 0;
        opacity: 1;
    }

    .images-style .select i {
        color: #fff;
        margin: 7px 0 0 8px;
    }

    .edit-shop-content .ivu-form-item {
        margin-bottom: 15px;
    }

    .edit-shop-content .ivu-form-item:after {
        display: none;
    }

    .tags-data {
        padding-left: 10px;
    }

    .tags-data .block {
        background-color: #f8f8f9;
        padding: 15px 0 10px 0;
        margin-bottom: 10px;
        min-width: 500px;
    }

    .block-row {
        min-height: 40px;
        margin: 0 10px;
        clear: right;
    }

    .block-row-1 {
        min-height: 68px;
    }

    .block-row.title {
        border-bottom: 1px solid #eee;
        height: 30px;
        margin: 0;
        padding: 0 10px;
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    .block-row .label {
        height: 32px;
        line-height: 32px;
    }

    .tit {
        color: #999;
        margin-left: 10px;
    }

    .tit-1 {
        color: #999;
        margin-top: 7px;
        margin-left: 2px;
    }

    .edit-shop-content .ivu-form-item:before, .edit-shop-content .ivu-form-item:after, .block-row:before, .block-row:after {
        display: none;
    }

    .edit-shop-content .ivu-radio-group label {
        width: 94px;
        text-align: center;
        color: #999;
        margin-top: -28px;
    }

    .edit-shop-content .ivu-radio-group .ivu-radio {
        display: none;
    }

    .edit-shop-content .ivu-form-item img.style {
        width: 88px;
        display: block;
        margin-top: 4px;
    }

    .edit-shop-content .ivu-form-item img.current {
        width: 87px;
        border: 2px solid #57a3f3;
    }

    .edit-shop-content .ivu-form-item span.select {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 86px;
        right: 6px;
        background: #57a3f3;
        border-radius: 100% 0 0 0;
        opacity: 1;
        line-height: 1;
    }

    .edit-shop-content .ivu-form-item .select i {
        color: #fff;
        margin: 7px 0 0 8px;
    }
    .edit-item{
        background-color: #f8f8f9;
        padding: 10px;
        margin: 15px 10px;
    }
</style>
