<template>
  <div class="module-audio">
    <div class="mod-shop-audio" :style="{backgroundColor: content.bgcolor}">
      <div>
        <img class="voice" src="https://dshop-static.tsphp.com/applet_weapp/images/icon2/voice.png">
        <p v-if="content.title != ''">{{content.title}}</p>
        <p v-else>标题</p>
        <img class="play" src="https://dshop-static.tsphp.com/applet_weapp/images/icon2/play.png">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'audio'
      }
    }
  }
</script>

<style scoped>
  .mod-shop-audio {
     padding: 8px 10px;
     overflow: hidden;
   }
  .mod-shop-audio .voice{
    width: 16px;
    margin: 4px 8px 0 0;
    float: left;
    opacity: 0.74;
  }
  .mod-shop-audio .play{
    width: 12px;
    float: right;
    opacity: 0.74;
    margin-top: 5px;
  }
  .mod-shop-audio p{
    font-size: 14px;
    float: left;
    line-height: 24px;
    width: 246px;
    width: 236px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
</style>
