<template>
  <div class="edit-richText">
    <div class="edit-shop-header">
      <h3>富文本设置</h3>
      <div class="edit-shop-delete">
        <!--el-popconfirm
                title="您确认删除吗？"
                @confirm="deleteComs()">
          <a href="javascript:void(0);">
            <i class="el-icon-delete"></i>
          </a>
        </el-popconfirm-->
      </div>
    </div>
    <div class="edit-shop-content">
      <!-- <div cladd="edit-shop-richText">
        <div style="margin-bottom: 20px;">
          <label style="width: 100px;">周围留白：</label>
          <div style="display: inline-block;">
            <InputNumber :max="100" :min="0" v-model="content.padding" style="width: 150px;"></InputNumber>
          </div>
          <label style="margin-left: 10px;">px</label>
          <label style="margin-left: 10px;" class="edit-tips">最大可输入100</label>
        </div>
      </div> -->
      <section class="edit-shop-richText edit-intro-custom">
        <!--editor :value="content.text" @input="contentChange" v-model="content.text"></editor-->
        <Wang-edit :txt="content.text" :callback="setContent"></Wang-edit>
      </section>
    </div>
  </div>
</template>

<script>
  import WangEdit from '../../components/WangEdit'
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    components: {
      WangEdit
    },
    data () {
      return {
        'type': 'richText'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      },
      setContent(txt) {
        this.content.text = txt
      }
    }
  }
</script>

<style>
  .edit-shop-richText .ql-toolbar.ql-snow{
    /*height: 46px;*/
  }

  .edit-shop-richText .ql-snow.ql-toolbar:after, .edit-shop-richText.ql-snow .ql-toolbar:after {
    display: none !important;
  }
  .edit-shop-richText .ql-editor{min-height:200px}
  .edit-shop-richText .edit-tips{
    /*margin-left: 20px;*/
    margin-top: 1px;
    color: #999;
    display: inline-block;
  }
</style>
