<template>
  <div class="edit-search">
    <div class="edit-shop-header">
      <h3>搜索框设置</h3>
      <div class="edit-shop-delete">
        <el-popconfirm
            title="您确认删除吗？"
            @confirm="deleteComs()">
          <a href="javascript:void(0);">
            <i class="el-icon-delete"></i>
          </a>
        </el-popconfirm>
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-search">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="外底色：">
            <el-color-picker v-model="content.out_bacolor" size="small"></el-color-picker>
          </el-form-item>
          <el-form-item label="内底色：">
            <el-color-picker v-model="content.in_bgcolor" size="small"></el-color-picker>
          </el-form-item>
          <el-form-item label="文字颜色：">
            <el-color-picker v-model="content.color" size="small"></el-color-picker>
          </el-form-item>
          <el-form-item label="提示文字：">
            <el-input type="text" v-model="content.label" placeholder="在店铺内搜索商品" maxlength="10" size="small"></el-input>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {

    },
    data () {
      return {
        'type': 'search'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      }
    }
  }
</script>

<style scoped>
  .edit-shop-search {
    background-color: #fff;
  }
  .edit-shop-content  .ivu-form-item{
    margin-bottom: 10px;
  }
  .edit-shop-content  .ivu-form-item{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .edit-shop-content  .ivu-form-item:after{
    display: none;
  }
</style>
