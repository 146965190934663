<template>
  <div class="edit-coupon">
    <div class="edit-shop-header">
      <h3>店招设置</h3>
      <div class="edit-shop-delete">
        <!--Poptip
            confirm
            placement="left"
            title="您确认删除吗？"
            @on-ok="deleteComs()"
            @on-cancel="cancel">
          <a href="javascript:void(0);">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </Poptip-->
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-banner">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="选择风格：">
            <el-radio-group v-model="content.style">
              <el-radio :label="0">
                <span>小店招</span>
              </el-radio>
              <el-radio :label="1">
                <span>大店招</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商城名称：">
            <el-row>
              <el-col :span="12">
                <el-input v-model="content.shop_name" placeholder="请输入商城名称" maxlength="20" size="small"></el-input>
              </el-col>
              <el-col :span="6">
                <p class="tit">最多可输入20个字</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="文字颜色：" prop="bgcolor">
            <el-color-picker v-model="content.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="商城logo：">
            <el-row>
              <el-col :span="5">
                <SelectImgs :selectNum="1" :selectData="[content.logo]" :idx="index" :selectFunc="logoChange"></SelectImgs>
              </el-col>
              <el-col :span="12">
                <p class="tit">建议尺寸：200 * 200 像素</p>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="店招图：">
            <el-row>
              <el-col :span="5">
                <SelectImgs :selectNum="1" :selectData="[content.banner_img]" :idx="index" :selectFunc="bannerChange"></SelectImgs>
              </el-col>
              <el-col :span="12" class="tit">
                <p v-if="content.style == 0">建议尺寸：750 * 240 像素</p>
                <p v-else>建议尺寸：750 * 320 像素</p>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  import SelectImgs from '../../components/SelectImgs'
  export default {
    components: {
      SelectImgs
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    created () {
    },
    data () {
      return {
        'type': 'coupon'
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      bannerChange (val) {
        this.content.banner_img = val.pic;
      },
      logoChange (val) {
        this.content.logo = val.pic;
      },
      contentChange () {
        this.$emit('message', this.content);
      },
      deleteComs () {
        this.$emit('delete', this.index);
      }
    }
  }
</script>

<style scoped>
  .edit-shop-banner .edit-input{
    width: 400px;
  }
  .edit-shop-banner .edit-tips{
    color: #999;
  }
  .tit {
    color: #999;
    margin-left: 10px;
  }
</style>
