/*
 * 分组： group = 1，2，3，4，5
 * 常用[1]： 搜索框、轮播图、标题、图片模版、富文本、店招、音频、视频、地图、占位符、电话、中部导航、图文、瀑布式列表
 * 商品[2]： 商品分类、横滑商品、自定义商品、知识付费
 * 资讯[3]： 资讯分类、横滑资讯、自定义资讯
 * 营销[4]： 秒杀、拼团、优惠券、砍价、直播
 * 其他[5]： 自由面板、图片、文本
 */
import config from "@/config/config";
const defaultComs = [
  {
    type: 'banner',
    group: 1,
    flag: false,
    name: '店招',
    icon: 'el-icon-collection-tag',
    content: {
      style: 1, // 店招风格：小店招【0】，大店招【1】
      banner_img: config.SOURCE_DOMAIN + '/images/shop-bg.jpg', // 店招背景图片
      shop_name: '您的店铺', // 店招名称
      logo: config.SOURCE_DOMAIN + '/images/shop.png', // 店招头像
      color: '#fff',
    }
  },
  {
    type: 'search',
    group: 1,
    flag: false,
    name: '搜索框',
    icon: 'el-icon-search',
    content: {
      color: '#999',
      in_bgcolor: '#fff',
      out_bacolor: '#ddd'
    }
  },
  {
    type: 'carousel', //  轮播图
    group: 1,
    flag: false,
    name: '轮播图',
    icon: 'el-icon-refresh',
    content: {
      height: '150', // 高度，默认300px，最大1000px
      margin: '0', // 轮播图周围留白，默认0px，最大100px
      interval: 2,
      direction: 1,
      data: [
        {
          img_url: '', // 图片地址，轮播图默认两张图片占位
          img_link: { // 图片链接
            type: '', // 商品【1】，商品分组【2】，购物车【3】，订单列表【4】，会员中心【5】，领取会员卡【6】，优惠买单【7】，门店列表页面【8】，新建页面【9】，资讯【10】，资讯分组【11】，优惠券【12】，商城【13】，单张优惠券【14】
            name: '',
            id: '' // 选择的商品、商品分组、新建页面的id
          }
        },
        {
          img_url: '',
          img_link: {
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  },
  {
    type: 'images',
    group: 1,
    flag: false,
    name: '图片模版',
    icon: 'el-icon-picture-outline',
    content: {
      style: 1, // 显示风格：单列【1】、双列【2】、三列【3】，左一右二【4】，左四右一【1】
      height: '300', // 高度，默认300px，最大1000px
      margin: '0', // 图片周围留白，默认0px，最大100px
      padding: '1', // 图片间距，默认10px，最大100px
      data: [
        {
          img_url: '', // 图片地址
          img_link: { // 图片链接
            type: '',
            name: '',
            id: ''
          }
        }
      ]
    }
  },
  {
    type: 'middleNav',
    group: 1,
    flag: false,
    name: '菜单导航',
    icon: 'el-icon-menu',
    content: {
      style: 1, // 显示风格：圆形【1】、 方行【2】
      bgcolor: '#fff', // 导航区域背景，默认白色#fff
      color: '#333333', // 菜单文字颜色
      num: 3, // 每行菜单的数量，有三个值【3，4，5】，默认为3
      data: [
        {
          nav_name: '菜单1', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_link: { // 菜单链接
            authkey: '',
            name: '',
            ctype: 0,
            path: ''
          }
        },
        {
          nav_name: '菜单2', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_link: { // 菜单链接
            authkey: '',
            name: '',
            ctype: 0,
            path: ''
          }
        },
        {
          nav_name: '菜单3', // 菜单文字
          nav_icon: '', // 菜单图标地址
          nav_link: { // 菜单链接
            authkey: '',
            name: '',
            ctype: 0,
            path: ''
          }
        }
      ]
    }
  },
  /* {
    type: 'bottomNav',
    group: 1,
    flag: false,
    name: '底部导航',
    icon: 'fa-navicon',
    content: {
      // height: '50px', 高度，默认50px，最大100px
      bgcolor: '#ddd', // 导航区域背景，默认白色#fff
      data: [
        {
          nav_name: '商城', // 菜单文字
          nav_color: '#333', // 初始颜色
          nav_hover_color: '#333', // 选中颜色
          nav_icon: '/2017/11/20/FoaIUX9pgG5soBLDdFIv4p7eN3Kw.png', // 初始图标
          nav_hover_icon: '/2017/11/20/Fl85XlrvHiIFjB_-n11RNrfyOxVh.png', // 选中图标
          nav_link: { // 菜单链接
            type: '13',
            name: '商城',
            id: ''
          }
        },
        {
          nav_name: '订单', // 菜单文字
          nav_color: '#333', // 初始颜色
          nav_hover_color: '#333', // 选中颜色
          nav_icon: '/2017/11/20/FhExodu-wzqtWBFBKVWLqFgkI9gH.png', // 初始图标
          nav_hover_icon: '/2017/11/20/FgZzwDwXnXwlgGRyA9OHfRFEq0lb.png', // 选中图标
          nav_link: { // 菜单链接
            type: '4',
            name: '订单列表页面',
            id: ''
          }
        },
        {
          nav_name: '购物车', // 菜单文字
          nav_color: '#333', // 初始颜色
          nav_hover_color: '#333', // 选中颜色
          nav_icon: '/2017/11/20/Fr5cIoYmtIQQuOv1ad9ExS_nyQTq.png', // 初始图标
          nav_hover_icon: '/2017/11/20/Fmx7ZxzZXVK7c9K5Jb3wdqvMk5Xh.png', // 选中图标
          nav_link: { // 菜单链接
            type: '3',
            name: '购物车页面',
            id: ''
          }
        },
        {
          nav_name: '我的', // 菜单文字
          nav_color: '#333', // 初始颜色
          nav_hover_color: '#333', // 选中颜色
          nav_icon: '/2017/11/20/FuVRARibPqMPJfzZ52UJrukSTJIg.png', // 初始图标
          nav_hover_icon: '/2017/11/20/FjW283IfindgyPrZ9Oy-wZhNj6Ig.png', // 选中图标
          nav_link: { // 菜单链接
            type: '5',
            name: '会员中心页面',
            id: ''
          }
        }
      ]
    }
  }, */
  {
    type: 'title',
    group: 1,
    flag: false,
    name: '标题',
    icon: 'el-icon-tickets',
    content: {
      left_title: '标题', // 左侧标题
      left_icon: '', // 左侧图标
      right_title: '右侧标题', // 右侧标题
      right_link: { // 链接
        type: '',
        name: '',
        id: ''
      },
      color: '#333', // 文字颜色，默认黑色#333
      bgcolor: '#fff', // 背景颜色，默认白色#fff
      morecolor: '#666' //更多颜色
    }
  },
  // {
  //   type: 'video',
  //   group: 1,
  //   name: '视频',
  //   icon: 'el-icon-video-camera',
  //   content: {
  //     url: '', // 视频地址
  //     img: '' // 视频封面
  //   }
  // },
  // {
  //   type: 'audio',
  //   group: 1,
  //   name: '音频',
  //   icon: 'el-icon-microphone',
  //   content: {
  //     title: '',
  //     bgcolor: '#fff', // 背景，默认白色#fff
  //     isloop: 1, // 循环播放，否【0】，是【1】
  //     ispause_after: 1, // 循环播放，暂停后再恢复播放时，从头开始播放【1】，暂停后再恢复播放时，从暂停位置开始播放【2】
  //     name: '', // 音频名字
  //     url: '' // 音频地址
  //   }
  // },
  {
    type: 'richText',
    group: 1,
    flag: false,
    name: '富文本',
    icon: 'el-icon-notebook-1',
    content: {
      text: '请输入内容......', // 富文本内容
      padding: 0 // 留白
    }
  },
  {
    type: 'white',
    group: 1,
    flag: false,
    name: '占位符',
    icon: 'el-icon-more',
    content: {
      height: '10', // 高度默认10px
      bgcolor: '#fff', // 背景色，默认白色#fff
      opacity: 100 // 背景透明度
    }
  },
  {
    type: 'phone',
    group: 1,
    flag: false,
    name: '电话',
    icon: 'el-icon-phone-outline',
    content: {
      title: '电话', // 显示文字
      phone: '', // 电话号码
      bgcolor: '#fff' // 背景，默认白色#fff
    }
  },
  // {
  //   type: 'map',
  //   group: 1,
  //   flag: false,
  //   name: '地图',
  //   icon: 'el-icon-map-location',
  //   content: {
  //     is_show: 1, // 是否显示地图:0显示，1不显示
  //     country: 1,
  //     province: 640000, // 省、直辖市编号
  //     city: 640000, // 市、区编号
  //     district: 640000, // 区、县编号
  //     country_name: '中国',
  //     province_name: '上海', // 省、直辖市名字
  //     city_name: '上海', // 市、区名字
  //     district_name: '崇明', // 区、县名字
  //     address: '', // 详细地址
  //     lat: '', // 坐标经度
  //     lng: '' // 坐标纬度
  //   }
  // },
  {
    type: 'imageText',
    group: 3,
    flag: false,
    name: '图文',
    icon: 'el-icon-film',
    content: {
      style: 1, // 样式：左图右文【1】、右图左文【2】、上图下文【3】
      data: [{
        it_title: '标题1',
        it_desc: '简介1',
        it_icon: '',
        color: '#333333', // 文字颜色
        bgcolor: '#ffffff', // 背景颜色
        it_link: {
          type: '',
          name: '',
          id: ''
        }
      }, {
        it_title: '标题2',
        it_desc: '简介1',
        it_icon: '',
        it_link: {
          type: '',
          name: '',
          id: ''
        }
      }]
    }
  },
  // {
  //   type: 'groupGoods',
  //   group: 2,
  //   flag: false,
  //   name: '商品分类',
  //   icon: 'el-icon-date',
  //   content: {
  //     style: 1, // 显示风格：双列【1】、小图单列【2】、大图单列【3】
  //     sort: 1, // 排序方式: 销量【1】，新增【2】
  //     show_ele: {
  //       name: true, // 商品名称，隐藏【false】，显示【true】
  //       price: true, // 价格
  //       sales: true, // 销量
  //       cart: true // 购物车
  //     },
  //     tag_style: 1, // 标签风格：在上【1】、在左【2】
  //     tag: [
  //       {
  //         id: 0, // 标签id
  //         name: '所有商品' // 标签名称
  //       }
  //     ],
  //     goods_num: 10, // 默认为10，最多100个，
  //     font_bgcolor: '#ff5500' // 选中底边和字体颜色，tab标签选中底边和字体颜色
  //   }
  // },
  {
    type: 'customGoods',
    group: 2,
    flag: false,
    name: '自定义商品',
    icon: 'el-icon-document-checked',
    content: {
      style: 1, // 显示风格：双列【1】、小图单列【2】、大图单列【3】
      show_ele: {
        name: true, // 商品名称，隐藏【false】，显示【true】
        price: true, // 价格
        sales: true, // 销量
        cart: true // 购物车
      },
      goods: []
    }
  },
  // {
  //   type: 'sliderGoods',
  //   group: 2,
  //   flag: false,
  //   name: '横滑商品',
  //   icon: 'el-icon-document-remove',
  //   content: {
  //     show_ele: {
  //       name: true, // 商品名称，隐藏【false】，显示【true】
  //       price: true, // 价格
  //       sales: true // 销量
  //     },
  //     goods: []//  商品id，最多10个
  //   }
  // },
  // {
  //   type: 'payk',
  //   group: 2,
  //   flag: true,
  //   name: '知识付费',
  //   icon: 'el-icon-files',
  //   content: {
  //     style: 1, // 显示风格：右小图【1】、左小图【2】、三张小图【3】、大图【4】
  //     sort: 1, // 排序方式: 按发布时间【1】，阅读量【2】
  //     show_ele: {
  //       content: true, // 资讯摘要，隐藏【false】，显示【true】
  //       price: true, // 价格
  //       time: true, // 发布时间
  //       red: true // 阅读量
  //     },
  //     tag: [
  //       {
  //         id: 0, // 标签id
  //         name: '所有内容', // 标签名称
  //         type: '-1'
  //       }
  //     ], // 专栏
  //     article_num: 10, // 默认为10，最多100个，
  //     font_bgcolor: '#ff5500' // 选中底边和字体颜色，tab标签选中底边和字体颜色
  //   }
  // },
  // {
  //   type: 'information',
  //   group: 3,
  //   flag: false,
  //   name: '资讯类导航',
  //   icon: 'el-icon-reading',
  //   content: {
  //     num: '1', // 每行菜单数量1-4
  //     position: 'left', // 导航位置【left：居左；center：居中；right：居右】
  //     tags: [
  //       {
  //         nav_name: '菜单1', // 菜单文字
  //         nav_bgcolor: '#ffffff', // 背景颜色
  //         nav_opacity: 100, // 背景透明度
  //         nav_icon: '', // 初始图标
  //         nav_link: { // 菜单链接
  //           type: '',
  //           name: '',
  //           id: ''
  //         }
  //       },
  //       {
  //         nav_name: '菜单2', // 菜单文字
  //         nav_bgcolor: '#ffffff', // 背景颜色
  //         nav_opacity: 100, // 背景透明度
  //         nav_icon: '', // 初始图标
  //         nav_link: { // 菜单链接
  //           type: '',
  //           name: '',
  //           id: ''
  //         }
  //       }
  //     ]
  //   }
  // },
  {
    type: 'customArticle',
    group: 3,
    flag: false,
    name: '自定义资讯',
    icon: 'el-icon-tickets',
    content: {
      style: 1, // 显示风格：左小图【1】、右小图【2】、三张小图【3】、大图【4】
      show_ele: {
        content: true, // 摘要
        time: true, // 发布时间
        red: true // 阅读量
      },
      article: []
    }
  },
  {
    type: 'groupArticle',
    group: 3,
    flag: false,
    name: '资讯分组',
    icon: 'el-icon-takeaway-box',
    content: {
      style: 1, // 显示风格：右小图【1】、左小图【2】、三张小图【3】、大图【4】
      sort: 1, // 排序方式: 按发布时间【1】，阅读量【2】
      show_ele: {
        content: true, // 资讯摘要，隐藏【false】，显示【true】
        time: true, // 发布时间
        red: true // 阅读量
      },
      tag: [
        {
          id: 0, // 标签id
          title: '所有资讯' // 标签名称
        }
      ],
      article_num: 10, // 默认为10，最多100个，
      font_bgcolor: '#ff5500' // 选中底边和字体颜色，tab标签选中底边和字体颜色
    }
  },
  // {
  //   type: 'sliderArticle',
  //   group: 3,
  //   flag: true,
  //   name: '横滑资讯',
  //   icon: 'el-icon-document-remove',
  //   content: {
  //     article: []
  //   }
  // },
  // {
  //   type: 'couponGroup',
  //   group: 4,
  //   flag: true,
  //   name: '优惠券',
  //   icon: 'el-icon-money',
  //   content: {
  //     style: 1, // 模板：1张券【1】、2张券【2】、3张券【3】
  //     scheme: 1, // 配色方案: 白色【1】、黑金【2】、蓝色【3】、橘色【4】、红色【5】、绿色【6】、粉色【7】
  //     data: [{
  //       id: '',
  //       name: ''
  //     }]
  //   }
  // },
  // {
  //   type: 'fightGroup',
  //   group: 4,
  //   flag: true,
  //   name: '拼团',
  //   icon: 'el-icon-odometer',
  //   content: {
  //     style: 1, // 显示风格：双列【1】、小图单列【2】、大图单列【3】
  //     groups: []
  //   }
  // },
  // {
  //   type: 'seckill',
  //   group: 4,
  //   flag: true,
  //   name: '秒杀',
  //   icon: 'el-icon-discover',
  //   content: {
  //     style: 1, // 显示风格：双列【1】、小图单列【2】、大图单列【3】
  //     seckills: []
  //   }
  // },
  // {
  //   type: 'bargain',
  //   group: 4,
  //   flag: true,
  //   name: '砍价',
  //   icon: 'el-icon-football',
  //   content: {
  //     style: 1, // 显示风格：双列【1】、小图单列【2】、大图单列【3】
  //     bargain: []
  //   }
  // },
  // {
  //   type: 'live',
  //   group: 4,
  //   flag: true,
  //   name: '直播',
  //   icon: 'el-icon-news',
  //   content: {
  //     live_live: [], // 直播
  //     live_record: [] // 录播
  //   }
  // },
  {
    type: 'book',
    group: 4,
    flag: false,
    name: '报名',
    icon: 'el-icon-chat-square',
    content: {
      bgcolor: '#fff',
      btnBgcolor: '#66b1ff',
      btncolor: '#fff',
      btnTxt: '提交',
      title: '我是标题',
      titleColor: '#333',
      titleSize: 16
    }
  },
  // {
  //   type: 'freePanel',
  //   group: 5,
  //   flag: true,
  //   name: '自由面板',
  //   icon: 'fa-cubes',
  //   content: {
  //     sid: 0, // 选中的子组件id
  //     height: 400, // 高度，默认值400px，最大1000，最小1
  //     bg_color: '#f8f8f8', // 背景色，默认值#f8f8f8
  //     bg_img: '', // 背景图片
  //     bg_link: { // 背景链接
  //       type: '',
  //       name: '',
  //       id: ''
  //     },
  //     bg_transparency: 100, // 背景色透明度，默认值100
  //     data: []
  //   }
  // },
  // {
  //   // iscopy: 2, // 右侧手机能只能存在一个此类模块
  //   // copycount: 1,
  //   type: 'publicFocus',
  //   group: 6,
  //   flag: false,
  //   name: '公众号关注',
  //   // icon: 'fa-cubes',
  //   content: {
  //     note: '微信微餐厅关联的公众号',
  //     title: '微信微餐厅', // 公众号标题
  //     forex: '(样例)',
  //     text: '每日菜单提前看，省时省力又省心!', // 公众号说明内容
  //     stlye: 1, // 公众号状态 1关注 2查看
  //     bgcolor: '#fff' // 背景，默认白色#fff
  //   }
  // }
  // ,
  // {
  //   type: 'waterFall',
  //   group: 1,
  //   flag: false,
  //   name: '瀑布式列表',
  //   icon: 'fa-bars fa-bars-v',
  //   content: {
  //     data: [{
  //       img: '',
  //       title: '菜单名称1',
  //       height: 200,
  //       link: {
  //         type: '',
  //         name: '',
  //         id: ''
  //       }
  //     }, {
  //       img: '',
  //       title: '菜单名称2',
  //       height: 200,
  //       link: {
  //         type: '',
  //         name: '',
  //         id: ''
  //       }
  //     }]
  //   }
  // }
];

export default defaultComs
