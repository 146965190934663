<template>
  <div class="module-surroundnav">
    <div class="mod-shop-surroundnav">
      <div class="mod-item-box">
        <div class="mod-item" :class="{'mod-item-1': content.fixed == 1, 'mod-item-2': content.fixed == 2, 'mod-item-3': content.fixed == 3, 'mod-item-4': content.fixed == 4}" v-for="(item, index) in content.data" :key="index" :style="{'transform': 'rotate('+ positionXY[content.fixed][dataLength][index] +'deg)'}">
          <span class="mod-img" v-if="content.show == 1" :style="{background: hexToRgba(item.nav_bgcolor, item.nav_bgopacity), 'transform': 'rotate(-'+ positionXY[content.fixed][dataLength][index] +'deg)'}">
            <img v-if="item.nav_icon != ''" :src="$store.state.imgBaseUrl + item.nav_icon">
            <img v-else src="../../assets/images/icon-bg8.jpg">
          </span>
          <h3 class="mod-name" :style="{background: hexToRgba(item.nav_bgcolor, item.nav_bgopacity), color: item.nav_color, 'transform': 'rotate(-'+ positionXY[content.fixed][dataLength][index] +'deg)'}" v-else-if="content.show == 2">{{item.nav_name}}</h3>
        </div>
        <div class="mod-dotted" :class="{'mod-dotted-1': content.fixed == 1, 'mod-dotted-2': content.fixed == 2, 'mod-dotted-3': content.fixed == 3, 'mod-dotted-4': content.fixed == 4}">
          <span class="mod-close" :style="{background: hexToRgba(content.icon_color, content.icon_opacity)}">
            <img :src="$store.state.imgBaseUrl + content.icon">
          </span>
        </div>
      </div>
      <!-- {{positionXY[content.fixed][dataLength]}} -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {
      navWidth () {
        return 100 / this.content.num + '%'
      },
      dataLength () {
        return this.content.data.length;
      }
    },
    data () {
      return {
        'type': 'surroundNav',
        'positionXY': {
          '1': [],
          '2': [],
          '3': [],
          '4': []
        }
      }
    },
    watch: {
      'content': {
        handler (newValue, oldValue) {
          this.setPositionXY(this.content.fixed, this.content.data.length);
        },
        deep: true
      }
    },
    created () {
      this.setPositionXY(this.content.fixed, this.content.data.length);
    },
    methods: {
      hexToRgba (hex, opacity) {
        return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity / 100).toFixed(2) + ')';
      },
      setPositionXY (fixed, dataLength) {
        var cirAngle = 360;
        var angle = 0;

        this.positionXY[this.content.fixed] = [];
        this.positionXY[this.content.fixed][dataLength] = [];

        for (var i = 0; i < dataLength; i++) {
          this.positionXY[this.content.fixed][dataLength][i] = [];
        }

        if (fixed == 1) {
          angle = (cirAngle / 4) / (dataLength * 2);
          this.positionXY[this.content.fixed][dataLength].forEach((item, index) => {
            this.positionXY[this.content.fixed][dataLength][index] = index * angle * 2 + angle;
          })
        } else if (fixed == 2) {
          angle = (cirAngle / 2 - 30) / (dataLength * 2);
          this.positionXY[this.content.fixed][dataLength].forEach((item, index) => {
            this.positionXY[this.content.fixed][dataLength][index] = index * angle * 2 + angle + 15;
          })
        } else if (fixed == 3) {
          angle = (cirAngle / 4) / (dataLength * 2);
          this.positionXY[this.content.fixed][dataLength].forEach((item, index) => {
            this.positionXY[this.content.fixed][dataLength][index] = index * angle * 2 + angle;
          })
        } else if (fixed == 4) {
          angle = cirAngle / dataLength;
          this.positionXY[this.content.fixed][dataLength].forEach((item, index) => {
            this.positionXY[this.content.fixed][dataLength][index] = index * angle;
          })
        }
      }
    }
  }
</script>

<style scoped>
  .mod-shop-surroundnav{
    /*background-color: #fff;*/
  }
  .mod-item-box {
    overflow: hidden;
    height: 200px;
    position: relative;
  }
  .mod-item {
    display: block;
    text-align: center;
    overflow: hidden;
    position: absolute;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
  }
  .mod-item-1 {
    height: 300px;
    width: 36px;
    bottom: -132px;
    left: 12px;
  }
  .mod-item-2 {
    height: 36px;
    width: 200px;
    bottom: 12px;
    left: 0;
  }
  .mod-item-3 {
    height: 36px;
    width: 300px;
    bottom: 12px;
    right: 12px;
    left: 18px;
  }
  .mod-item-4 {
    height: 36px;
    width: 200px;
    bottom: 84px;
    left: 0;
  }
  .mod-img {
    height: 36px;
    width: 36px;
    display: block;
    border-radius: 50%;
  }
  .mod-img img{
    display: block;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    border-radius: 50%;
  }
  .mod-name {
    font-size: 12px;
    color: #444;
    font-weight: normal;
    word-wrap: break-word;
    word-break: break-all;
    height: 36px;
    width: 36px;
    overflow: hidden;
    text-align: center;
    padding-left: 1px;
    padding-right: 1px;
    margin-top: 4px;
    vertical-align: middle;
    display: table-cell;
    line-height: 14px;
    border-radius: 50%;
  }
  .mod-dotted {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 36px;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
  }
  .mod-dotted-1 {
    bottom: 12px;
    left: 12px;
  }
  .mod-dotted-2 {
    bottom: 12px;
    left: 84px;
  }
  .mod-dotted-3 {
    bottom: 12px;
    right: 12px;
    left: auto;
  }
  .mod-dotted-4 {
    bottom: 84px;
    left: 84px;
  }
  .mod-dotted .mod-close{
    display: block;
    height: 36px;
    width: 36px;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    color: #666;
    border-radius: 50%;
  }
  .mod-dotted .mod-close img{
    display: block;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
</style>
