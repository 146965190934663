<template>
  <div class="module-images">
    <div class="mod-shop-images">
      <div class="view-image">
        <!--单列-->
        <div class="single" :style="{height:content.height/2+'px',margin:content.margin+'px',width:(width - content.margin *2)+'px'}" v-if="content.style == 1">
          <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
          <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
        </div>
        <!--双列-->
        <div class="double row" :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 2">
            <div class="double-img" :style="{width:(width-(content.margin * 2)-content.padding)/2+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="double-img" :style="{width:(width-(content.margin * 2)-content.padding)/2+'px'}">
                <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
        </div>
          <!--三列-->
        <div class="three row"  :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 3">
            <div class="three-img" :style="{width:(width-(content.margin * 2)-(content.padding *2))/3+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="three-img" :style="{width:(width-(content.margin * 2)-(content.padding *2))/3+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
             </div>
            <div class="three-img" :style="{width:(width-(content.margin * 2)-(content.padding *2))/3+'px'}">
              <img v-if="content.data[2].img_url" :src="content.data[2].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
        </div>
          <!--四列-->
        <div class="six row"  :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 6">
            <div class="six-img" :style="{width:(width-(content.margin * 2)-(content.padding *3))/4+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="six-img" :style="{width:(width-(content.margin * 2)-(content.padding *3))/4+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
             </div>
            <div class="six-img" :style="{width:(width-(content.margin * 2)-(content.padding *3))/4+'px',marginRight:content.padding+'px'}">
              <img v-if="content.data[2].img_url" :src="content.data[2].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="six-img" :style="{width:(width-(content.margin * 2)-(content.padding *3))/4+'px'}">
              <img v-if="content.data[3].img_url" :src="content.data[3].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
        </div>
          <!--五列-->
        <div class="seven row"  :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 7">
            <div class="seven-img" :style="{width:(width-(content.margin * 2)-(content.padding *4))/5+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="seven-img" :style="{width:(width-(content.margin * 2)-(content.padding *4))/5+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
             </div>
            <div class="seven-img" :style="{width:(width-(content.margin * 2)-(content.padding *4))/5+'px',marginRight:content.padding+'px'}">
              <img v-if="content.data[2].img_url" :src="content.data[2].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="seven-img" :style="{width:(width-(content.margin * 2)-(content.padding *4))/5+'px',marginRight:content.padding+'px'}">
              <img v-if="content.data[3].img_url" :src="content.data[3].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="seven-img" :style="{width:(width-(content.margin * 2)-(content.padding *4))/5+'px'}">
              <img v-if="content.data[4].img_url" :src="content.data[4].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
        </div>
         <!--左一右二-->
        <div class="four row" :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 4">
            <div class="left" :style="{height:content.height/2+'px',width:(width-(content.margin * 2)-(content.padding))/2+'px',marginRight:content.padding+'px'}">
                <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
                <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
             <div class="clum left" :style="{width:(width-(content.margin * 2)-(content.padding))/2+'px'}">
                 <div class="four-img" :style="{height:(content.height/2-(content.padding))/2+'px',marginBottom:content.padding+'px'}">
                    <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
                   <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
                 </div>
                 <div class="four-img" :style="{height:(content.height/2-(content.padding))/2+'px'}">
                    <img v-if="content.data[2].img_url" :src="content.data[2].img_url" class="img" />
                    <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
                 </div>
            </div>
        </div>
        <!--左四右一-->
        <div class="five row" :style="{height:content.height/2+'px',margin:content.margin+'px'}" v-if="content.style == 5">
          <div class="clum left" :style="{width:(width-(content.margin * 2)-(content.padding *2))/3+'px',marginRight:content.padding+'px'}">
            <div class="five-img" :style="{height:(content.height/2-(content.padding))/2+'px',marginBottom:content.padding+'px'}">
              <img v-if="content.data[0].img_url" :src="content.data[0].img_url" class="img" />
              <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="five-img"  :style="{height:(content.height/2-(content.padding))/2+'px'}">
              <img v-if="content.data[1].img_url" :src="content.data[1].img_url" class="img" />
              <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>

          </div>
          <div class="clum left" :style="{width:(width-(content.margin * 2)-(content.padding *2))/3+'px',marginRight:content.padding+'px'}">
            <div class="five-img" :style="{height:(content.height/2-(content.padding))/2+'px',marginBottom:content.padding+'px'}">
              <img v-if="content.data[2].img_url" :src="content.data[2].img_url" class="img" />
              <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>
            <div class="five-img"  :style="{height:(content.height/2-(content.padding))/2+'px'}">
              <img v-if="content.data[3].img_url" :src="content.data[3].img_url" class="img" />
              <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
            </div>

          </div>
          <div class="left" :style="{height:content.height/2+'px',width:(width-(content.margin * 2)-(content.padding *2))/3+'px'}">
            <img v-if="content.data[4].img_url" :src="content.data[4].img_url" class="img" />
            <img v-else src="../../assets/images/icon-bg8.jpg" class="img" />
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    props: {
      content: {}
    },
    computed: {
      width () {
        return 298;
      }
    },
    data () {
      return {
        'type': 'images'
      }
    }
  }
</script>

<style scoped>
.single{height: 93px;width:100%;}
.img{width: 100%;height: 100%;display: block;}
.row{flex-direction:row;height: 93px;justify-content: space-between;flex-wrap:wrap;}
.clum{flex-direction:column;height: 93px;justify-content: space-between;}
.double .double-img{height: 100%;float: left;}
.double .double-img:first-child{margin-right: 1px;}
.view-image{overflow: hidden;}
.three .three-img{height: 100%;margin-right: 1px;float: left;}
.three .three-img:last-child{margin-right:0px}
.four{height:186px;overflow: hidden;}
.four .four-img{width:100%;height: 92.5px}
.four .four-img:first-child{margin-bottom: 1px;}
.left{float:left}
.five{height:186px}
.five .clum{margin-right:1px;}
.five .five-img{height: 92.5px;width: 100%}
.five .clum .five-img:first-child{margin-bottom: 1px;}
.six{overflow: hidden;}
.six .six-img{height: 100%;margin-right: 1px;float: left;}
.six .six-img:last-child{margin-right:0px}
.seven{overflow: hidden;}
.seven .seven-img{height: 100%;margin-right: 1px;float: left;}
.seven .seven-img:last-child{margin-right:0px}
</style>
