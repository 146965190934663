<template>
  <div class="edit-groupGoods">
    <div class="edit-shop-header">
      <h3>资讯分组设置</h3>
      <div class="edit-shop-delete">
      </div>
    </div>
    <div class="edit-shop-content">
      <section class="edit-shop-groupGoods">
        <el-form :model="content" label-width="80px" size="small">
          <el-form-item label="显示风格：" prop="style">
            <el-radio-group v-model="content.style">
              <el-radio :label="1">右小图</el-radio>
              <el-radio :label="2">左小图</el-radio>
              <!--el-radio :label="3">三张小图</el-radio-->
              <el-radio :label="4">大图</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="排序方式：" prop="sort">
            <el-radio-group v-model="content.sort">
              <el-radio :label="1">按发布时间</el-radio>
              <el-radio :label="2">按阅读量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示元素：" prop="show_ele">
            <el-checkbox v-model="content.show_ele.content">资讯摘要</el-checkbox>
            <el-checkbox v-model="content.show_ele.time">发布时间</el-checkbox>
            <el-checkbox v-model="content.show_ele.red">阅读量</el-checkbox>
          </el-form-item>
          <el-form-item label="资讯数量：" prop="article_num">
            <el-input-number v-model="content.article_num" :min="1" :max="100" size="small" label="请输入熟练"
                             controls-position="right"></el-input-number>
          </el-form-item>
          <el-form-item label="选择分组：" prop="tag">
            <el-select v-model="tag" multiple label-in-value="true" style="width:80%" @change="selectTag">
              <!--el-option value="0" label="所有资讯" :key="0">所有资讯</el-option-->
              <el-option v-for="item in groupList" :value="item.id" :label="item.title" :key="item.id">{{ item.title }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选中颜色：">
            <el-color-picker v-model="content.font_bgcolor"></el-color-picker>
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0
      },
      content: {}
    },
    computed: {},
    created() {
      this.getGroups()
    },
    data() {
      return {
        type: 'groupGoods',
        groupList: [{id: 0, title: '全部分类'}], // 分组列表
        tag: []
      }
    },
    watch: {
      'content': {
        handler(newValue, oldValue) {
          this.contentChange()
        },
        deep: true
      }
    },
    methods: {
      contentChange() {
        this.$emit('message', this.content);
      },
      deleteComs() {
        this.$emit('delete', this.index);
      },
      creatTag() {
        var _this = this
        if (this.content.tag && this.content.tag.length > 0) {
          this.content.tag.forEach(function (item) {
            _this.tag.push(parseInt(item.id))
          })
        }
      },
      // 获取资讯分组
      getGroups() {
        var _this = this
        let params = {
          page: 1,
          pagesize: 100
        };
        this.$api.news.newsMenuTree(params, (res) => {
          if (res.errcode == 0) {
            res.data.forEach(function (item) {
              _this.groupList.push(item)
            })
            this.creatTag()
          } else {
            this.$Message.error(res.errmsg);
          }
        }, () => {
          this.$Message.error('系统繁忙，请稍后重试！');
        })
      },
      selectTag(selectData) {
        var _this = this
        this.content.tag = []
        if (this.inArray('0', selectData)) {
          _this.content.tag.push({id: 0, title: '全部分类'})
        }
        this.groupList.forEach(function (item) {
          if (_this.inArray(item.id, selectData)) {
            _this.content.tag.push(item)
          }
        })
      },
      inArray(value, arr) {
        for (var i = 0; i < arr.length; i++) {
          if (value === arr[i]) {
            return true;
          }
        }
        return false;
      }
    }
  }
</script>

<style scoped>
</style>
